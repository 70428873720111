.interview-notes {
  .interview-card__header__title {
    flex: 1;
    min-width: 250px;
  }

  .interview-card__header__user {
    width: 180px;
    flex: 0 0 180px;
  }
}
