/*
---
name: Company Logo
category: branding/logo
mixin: companyLogo
tag:
  - Branding
---

```code
+companyLogo('color')
```
*/

.logo {
    font-family: $font-family-secondary-black;
    font-size: 2.375rem;
    color: $black;
    display: inline-block;
    width: 140px;
    height: 30px;
    // background: url('/imgs/logo_hrcover_black@2x.png') center / contain no-repeat;
    img {
      width: auto;
      height: 100%;
    }
}

.logo--s {
    font-size: 1.625rem;
}

.logo--xl {
  width: 200px;
  height: 40px;
}

.logo--white {
    // background: url('/imgs/logo_hrcover_white@2x.png') center / contain no-repeat;
    color: $white;
    b {
        color: $white;
    }
}

.logo--margin-bottom {
    margin-bottom: 50px;
}
