.dashboard-career {
  .customization {

    margin-top: 40px;

    button {
      margin-left: 30px;
      margin-top: 35px;
    }

    strong {
      font-weight: 400;
    }
    details {
      margin-top: 1.5rem;
    }

    &__row {
      display: inline-flex;
      flex-flow: column;
      align-items: flex-start;
      margin-bottom: 1rem;

      img {
        max-width: 200px;
        max-height: 100px;
        width: auto;
        height: auto;
      }

      &.social-media {
        display: flex;
        max-width: 500px;
      }
    }

    &__text {
      display: flex;
      flex-flow: column;
      align-items: center;
      margin-top: 1rem;

      &.upload a {
        font-size: .875rem;
        text-decoration: underline;

        &:not(:first-of-type) {
          margin-top: .5rem;
        }
      }
    }
  }
}

.dashboard-career__link {
  width: 500px;
}

.dashboard-career__copy {
  display: flex;
  align-items: center;
}

.dashboard-career__section {
  margin-top: 10px;
  padding: 10px;
  border: 1px dashed grey;

  summary h3 {
    // necessary to show the arrowhead of details summary
    display: inline;
  }
}

.dashboard-career__content {
  margin: 10px;
  padding: 20px 10px 0;
}

.dashboard-career__frame-container {
  padding: 50px;
  margin: 50px;
  background-color: grey;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-career__frame {
  @include size(500px 800px);
  border: none;

  &.mobile {
    @include size(700px 400px);
  }
}
