.page__footer {
  @include size(100% 30px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: $font-size-small;

  .footer__text {
    margin: 8px;
    text-align: center;
  }

  .footer__logo {
    width: 100px;
  }
}