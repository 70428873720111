
.document-radio-field {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  .document-radio-field__input {
    border-radius: 3px;
    background-color: $input-field-grey;
    padding: 18px 25px;
    li:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .document-radio-field__input-checkbox, .document-radio-field__input-radio {
    display: flex;
    gap: 14px;
    align-items: center;

    input {
      @include size(16px);
      min-width: 16px;
      min-height: 16px;
      border: 2px solid black;
    }

    span {
      font-size: $font-size-default;
    }
  }



  .document-radio-field__input-radio input {
    border-radius: 50%;

    &:checked {
      background-image: url('/imgs/icons/icon-checked-round@2x.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 10px;
    }
  }
}

.document-radio-field__flex {
  display: flex;
}

.document-radio-field .document-radio-field__input-radio input{
  margin-right: 8px;
}