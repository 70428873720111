.profile-popup {
  .box {
    padding: 30px 60px 30px 77px;
  }

  .profile-photo {
    @include size(88px 88px);
    margin: -8px 0 24px -2px;
  }

  .box__input-field {
    margin-bottom: 23px;
  }

  input {
    margin-top: 7px;
  }

  .input-field {
    padding-left: 20px;
  }

  .label--input {
    padding-left: 12px;
    font-weight: bold;
    letter-spacing: 1px;
  }

  .icon {
    margin-left: 22px;
    margin-top: 15px;
    @include size(30px 30px);
  }

  .btn {
    font-size: $font-size-default;
    width: 235px;
    padding: 22px 0;
    margin: 10px 20px 2px 0;
  }
}
