.c-modal-new-session__date {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .input-field {
        margin-bottom: 0;
        width: 100%;
        padding-left: 30px !important;
    }
}

    .c-modal-new-session__datepicker {
        flex: 3;
        margin-right: 1rem;
        max-width: 252px;
    }

    .c-modal-new-session__time {
        flex: 2;
        max-width: 150px;
        .ui-timepicker-wrapper {
            position: relative !important;
            width: 100% !important;
            margin-bottom: -150px !important;
            top: 0;
            left: 0;
        }
    }

    .c-modal-new-session__time_end {
        flex: 2;
        max-width: 102px;
        .ui-timepicker-wrapper {
            position: relative !important;
            width: 100% !important;
            margin-bottom: -150px !important;
            top: 0;
            left: 0;
        }
    }

    .c-modal-new-session__btn {
        margin-top: 50px;
        span {
            margin-right: 20px;
        }
    }

    .c-modal-new-session__message {
        margin-bottom: 15px !important;
    }
