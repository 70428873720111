.cc-tests {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 2;
}

    .cc-tests--white-bg {
        background-color: #fff;
    }

    .cc-tests__checkboxes {
        display: flex;
        flex: 1;
        width: 100%;
        max-width: 1000px;
        flex-direction: column;
    }


        .cc-tests__sub-checkboxes {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: flex-start;
            padding: 0px 100px 10px;

            .input-field {

            }

            .input-field--checkbox-container {
                flex: 1;
                flex-basis: 33%;
            }

            .input-field__title {
                margin-right: 0;
            }


        }

            .cc-tests__sub-checkboxes--docs {
                align-items: center;

                .input-field {
                    width: 65%;
                    margin: 0;
                    // background-color: #F1F5F5;
                }
            }

                a.cc-tests__sub-checkboxes__delete {
                    font-size: $font-size-smaller;
                    color: #7B7B7B;
                    text-decoration: underline;
                    &:hover {
                        color: $black;
                    }
                }
