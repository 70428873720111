.candidates-tabs {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    // border: 1px solid red;
    margin-bottom: 25px;
    align-items: end;

    .candidates__display-mode {
        margin-right: 20px;
        .display-mode--toggle {
            width: max-content;
            display: flex;
            gap: 5px;
            .settings {
                margin-left: 10px;
            }
            ul.dropdown-menu.dropdown-menu-form {
                margin-left: 10px;
            }
        }
    }
    
}

    .candidates-tabs__item {
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: center;
        @include size(100% 43px);
        background-color: #edeff1;
        border: solid 1px #EFF0F2;
        border-radius: 3px;
        // padding: 0 20px;
        color: $black;
        + .candidates-tabs__item {
            margin-left: 5px;
            &.candidates-tabs__item--search {
                margin-left: 20px;
            }
        }
        &.active, &:not(.candidates-tabs__item--search):hover {
            background-color: #fff;
            color: $black;
        }
        cursor: pointer;
    }

        .candidates-tabs__item--search {
            min-width: 300px;
            gap: 0px;
            .input-field {
                height: 100%;
                margin: 0;
                min-height: inherit;
                padding-left: 15px;
                padding-right: 15px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        .candidates-tabs__item--search:focus-within {
            border: solid 1px #666666;
        }

            .candidates-tabs__num {
                font-size: $font-size-medium;
            }

            span.candidates-tabs__label {
                text-transform: uppercase;
                font-size: $font-size-small;
            }

        .candidates-tabs__item--search-button {
            background-color: #666666;
            height: 43px;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            background-position: 14px;
            background-repeat: no-repeat;
            background-size: 20px;
            padding-left: 47px;
        }