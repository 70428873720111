.cc-settings {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: flex-start;

    // placing the language-picker in th top right corner

    .box {
        position: relative;

        .language-picker {
            position: absolute;
            right: 50px;
            top: 20px;
        }
    }

    .cc-settings__hubspot-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 25px;
      
        .refresh-text {
            position: absolute;
            height: 60px;
            padding: 21px 17px;
            margin-left: -160px;
            margin-top: 0px;
            font-style: italic;
            cursor: pointer;
            background-color:#f4f4f4;
        }    
        .hubspot-deal__clear-button {
            position: absolute;
            height: 60px;
            border: none;
            padding: 0px 28px;
            background-color: #f4f4f4;
            margin-left: -67px;

            i {
                margin-top: 23px;
            }
        }

        .cc-settings__hubspot-field {
            .label--input {
                width: 215px;
                display: inline-block;
            }
            &.hubspot-deal {
                margin-bottom: 10px;
            }
            &.box__input-field .input-field--select, .text--value {
                width: 460px;
                text-overflow: ellipsis;
                padding-right: 70px;
            }
            .text--value {
                white-space: nowrap;
                max-width: 100%;
                overflow: hidden;
                padding-right: 0;
            }
        }
    }
}

    .job-category__container {
        flex-wrap: wrap;
        .badge {
            margin: 5px;
        }
    }

    .linked-documents {
        width: 100%;
        margin-top: 10px;
        padding: 20px;
        background-color: $input-field-grey;
        border-radius: 5px;
    }

        .linked-document {
            display: flex;
            align-items: center;
            justify-content: space-between;
            & + & {
                margin-top: 10px;
            }
        }

            .linked-document__buttons {
                display: flex;
                width: 50px;
                justify-content: space-between;
            }

    .setting__timer-field {
        height: 60px;

        .assessment--input-minutes {
            margin-left: 20px;
            padding-left: 20px;
            width: 120px;
            background-position: 65%;
        }
    }

    .linked-document__buttons-add {
        display: flex;
        width: 100%;
        
        &.right {
            justify-content: flex-end;
        }
    }