.written-questions-container {
    display: flex;
    flex-direction: column;

    &.not-customized {

    }
    &.has-customized {
        .input-field {
            background-color: #fff;
        }
        .interview-video__question {
            background-color: #111;
            color: #fff;
        }
        .written-questions__multiple {
            background-color: #fff;
        }
    }
}

    .written-questions {

    }

        .written-questions__text {
            width: 100%;
            max-width: 600px;
            text-align: right;
        }

        .written-questions__error {
            margin-bottom: 30px;
        }

            .written-questions__input {
                height: 100%;
                padding: 30px;
                resize: none;
                margin: 0 0 -40px;
            }

            .written-questions__condition {
                margin-right: 20px;
                font-size: $font-size-default;
                color: $warm-grey;
            }

        .written-questions__multiple {
            padding: 30px;
            width: 100%;
            min-height: 180px;
            max-width: 600px;
            background-color: $white;
            border-radius: 3px;
            text-align: center;
        }

            .written-questions__type {
                font-size: $font-size-small;
                color: $warm-grey;
                text-align: center;
            }
