.submission {
    display: flex;
    flex: 1;
    flex-direction: column;
}

        .submission__top-buttons {
            display: flex;
        }

        .submission__top-button {
            // same margin-bottom as the h1 which is in the same div
            margin-bottom: 1.25rem;
            // background-color: $brand-color-3-light;
            & + & {
                margin-left: 20px;
            }
        }


    .submission__container {
        display: flex;
        flex: 3;
        flex-direction: column;
        h1 {
            margin-bottom: 20px;
            font-family: $font-family-main-book;
            font-size: $font-size-medium;
        }
    }

        .submission__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .submission__details {
            margin-bottom: 30px;
        }
        
        .submission__summary {
            font-size: $font-size-medium;
        }

        .submission__section {
            padding-bottom: 2.5rem;
        }
            .submission__num {
                font-size: $font-size-small;
                color: $warm-grey-two;
                margin-bottom: 5px;
            }

       
        .submission__documents-dragover {
            border: 2px dashed lighten($cerulean, 20%); 
            border-radius:3px;
            background-color: rgba(0, 123, 255, 0.1); 
            transition:  background-color 0.1s ease-in-out, border 0.1s ease-in-out;    
            
        }

       
        
          .submission__media-upload {
            width: 40%;
            border: 2px dashed $warm-grey-two;
            background: rgba(255,255,255,.8);
            border-radius: 3px;
            margin: 0 auto;
            margin-top: 100px;
            
            
        }
    
        .submission__media-upload__bold-text {
            font-size: $font-size-small;
            font-family: $font-family-main-bold;
            letter-spacing: 0.3;
        }
        
        .submission__media-upload__content {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 15px 40px;
            justify-content: center;
            gap: 5px;
            height: 220px;
            
        }    

        .submission.drag-and-drop .submission__media-upload {
            div.icon {
              filter: invert(30%) sepia(8%) saturate(3451%) hue-rotate(167deg) brightness(130%) contrast(80%);
            }
            span, p, a {
              color: $cerulean;
            }
            border-color: lighten($cerulean, 20%);
            a.top-right-position {
              color: $white;
            }
          }

          .submission__media-upload__content .icon--upload.custom-color {
            filter: invert(30%) sepia(8%) saturate(0%) hue-rotate(167deg) brightness(90%) contrast(30%);
        }
          .submission__media-upload__bold-text{
            color: $warm-grey-two;
          }