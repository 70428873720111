.submission-tests {
    display: flex;
    flex: 1;
    flex-direction: column;
}

    .submission-tests__container {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .icon {
            margin: -100px 0 40px;
        }
    }
