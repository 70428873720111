//-----------------------------------------------------------------------------/
//  Settings
//
//  @Colors..................Global Colors
//  @Typography..............Typography Styles
//  @Spacing.................Horiontal & Vertical Spacing
//
//--------------------------------------------------*/

//
// SCREEN SIZES
// ===========================================

$screen-height-xs: 500px;
$screen-height-s: 600px;
$screen-height-m: 700px;
$screen-height-l: 800px;

// @Colors
// ===========================================

// B & W
$white: #f5f5f5;
$black: #333;

// Grays
$grey: #ccc;
$grey-light: lighten($grey, 10%);
$grey-lighter: lighten($grey, 20%);
$grey-dark: darken($grey, 10%);
$grey-darker: darken($grey, 20%);
$grey-darkerer: darken($grey, 40%);

// Brand Colors
// $brand-yellow: #dfa03a;
// $brand-yellow: #f1be51; // code du logo
// $brand-yellow: #e9b956; // code du site squarespace
$brand-yellow: #FFB700; // code du site avec filtre
$brand-blue: #355775;

$assessfirst-green: #9BD420;
$assessfirst-lightgreen: #e3ffa4;

$docimo-blue: #2aa0d7;
$docimo-lightblue: lighten($docimo-blue, 30%);

$cerulean: #0098ce;
$tealish: #1cb5b4;

$white-two: #dbdbdb;
$white-three: #f7f7f7;

$warm-grey: #999999;
$warm-grey-two: #727272;
$charcoal-grey: #45494c;
$light-blue-grey: #b0dfe3;

$silver: #d6e1e1;
$silver-two: #b1bcbc;

$cloudy-blue: #bcc7d0;

$greyish-brown: #454545;
$greyish-brown-two: #515151;

$steel-grey: #7c8083;

$pinkish-grey: #bbbbbb;
$pinkish-grey-two: #c4c4c4;

$warning-color: #de4a4a;
$warning-color-darker:          darken($warning-color, 10%);

// Inputs
$input-field-grey: #f4f4f4;

// Brand Variables
$brand-color-1:                 $brand-yellow;
$brand-color-1-light:           lighten($brand-color-1, 10%);
$brand-color-1-lighter:         lighten($brand-color-1, 20%);
$brand-color-1-dark:            darken($brand-color-1,  10%);
$brand-color-1-darker:          darken($brand-color-1,  20%);
$brand-color-2:                 $brand-yellow;
$brand-color-2-light:           lighten($brand-color-2, 10%);
$brand-color-2-dark:            darken($brand-color-2,  10%);
$brand-color-3:                 $brand-blue;
$brand-color-3-light:           lighten($brand-color-3, 10%);
$brand-color-3-lighter:         lighten($brand-color-3, 20%);
$brand-color-3-dark:            darken($brand-color-3,  10%);
$brand-color-3-darker:          darken($brand-color-3,  20%);
$brand-color-4: #f5556c;
$btn-color-inactive: $pinkish-grey-two;

$upgrade-color:                #ed8b00;

$tag-color-grey:                $white-three;
$tag-background-color:          $brand-blue;
$tag-border:                    none;
$tag-text-color:                #fff;


$job-category-color-1: $brand-color-3;

$stage-color-default:           #ccc;

$background-light-grey:         #f8f8f8;

// Links

// overlays
$overlay-grey-trans: rgba(161, 161, 161, 0.3);

// @Typography
// ===========================================

// Font Stacks
$font-gotham-thin: "Gotham Thin", Arial, sans-serif;
$font-gotham-x-light: "Gotham XLight", Arial, sans-serif;
$font-gotham-light: "Gotham Light", Arial, sans-serif;
$font-gotham-book: "Gotham Book", Arial, sans-serif;
$font-gotham-medium: "Gotham Medium", Arial, sans-serif;
$font-gotham-bold: "Gotham Bold", Arial, sans-serif;
$font-gotham-black: "Gotham Black", Arial, sans-serif;
$font-gotham-ultra: "Gotham Ultra", Arial, sans-serif;

$font-futura-semi:                  "FuturaPT Demi", Arial, sans-serif;
$font-futura-black:                  "FuturaPT Bold", Arial, sans-serif;

$font-chronicle-black: "ChronicleDisplay Black", Arial, sans-serif;

// Copy Font
// $font-family-main-thin:              $font-gotham-thin;
// $font-family-main-x-light:           $font-gotham-x-light;
// $font-family-main-light:             $font-gotham-light;
$font-family-main-book: $font-gotham-book;
$font-family-main-medium: $font-gotham-medium;
$font-family-main-bold: $font-gotham-bold;
// $font-family-main-black:             $font-gotham-black;
// $font-family-main-ultra:             $font-gotham-ultra;

$font-family-secondary-black: $font-chronicle-black;

$font-family-ternary-semi:              $font-futura-semi;
$font-family-ternary-black:             $font-futura-black;

// Font Sizes
$font-size-smaller: 0.75rem; // 12px
$font-size-small: 0.875rem; // 14px
$font-size-default: 1rem; // 16px
$font-size-base: 1.125rem; // 18px
$font-size-medium: 1.25rem; // 20px
$font-size-large: 1.5rem; // 24px
$font-size-larger: 2.188rem; // 35px
$font-size-extra-large: 4.375rem; // 70px

// @Spacing
// ===========================================

$parallax-perspective: 1 !default;
$parallax-element: "body" !default;
$parallax-ios: true !default;

$enable-flex: true;
$grid-gutter-width: 30px;
$content-padding: 50px;

$size-xs: 377px;
$size-xs-max: 575px;
$size-sm: 576px;
$size-sm-max: 767px;
$size-md: 768px;
$size-md-max: 991px;
$size-lg: 992px;
$size-lg-max: 1200px;
$size-xl: 1201px;

$border-radius-default: 3px;

$image-onboarding: '/imgs/logo_beehive_yellow.png';

$analytics-hint-mean: rgb(187, 125, 216);

$bg--gradient-sky: linear-gradient(330deg, $brand-blue, lighten($brand-blue, 20%));