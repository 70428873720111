.answers-list {
    margin: 20px 0 10px 10px;
}

.answers-list__answer {
    display: flex;
    align-items: center;
    span {
        font-size: $font-size-small;
    }

    padding-bottom: 5px;
    padding-top: 5px;
}

