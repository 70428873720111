.progress__container {
    &:not(.ng-hide) + & {
        margin-left: 20px
    }
}

    .progress__circle {
        @include size(60px);
        margin: auto;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        line-height: 1rem;
        background-color: white;
        border-radius: 50%;
        // color: black;
        canvas {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .progress__circle--small {
        @include size(40px);
        font-size: $font-size-small;
        line-height: $font-size-small;
    }
    .progress__circle--medium {
        @include size(50px);
        font-size: $font-size-small;
        line-height: $font-size-small;
    }
    .progress__circle--large {
        @include size(60px);
        font-size: $font-size-default;
        line-height: $font-size-default;
    }
    .progress__circle--xlarge {
        @include size(160px);
    }

    .progress__label {
        margin-top: 10px;
    }
    .progress__label--uppercase {
        text-transform: uppercase;
    }

    .vignette {
        border-radius: 5px;
        padding: 10px;
        background-color: #ebebeb;
    }
    
    // inspired from https://codepen.io/jo-asakura/pen/stFHi?editors=1100
    @mixin draw-progress($percentage) {
        .pie {
            .left-side {transform: rotate($percentage * 3.6deg);}
            @if $percentage <= 50 {
                .right-side {display: none;}
            } @else {
                clip: rect(auto, auto, auto, auto);
                .right-side {transform: rotate(180deg);}
            }
        }
            @if $percentage < 25 {
                .pie .half-circle {border-color: #f73f3f;}   
                .shadow {border-color: #ffcdcd;}
            } @else if $percentage < 50 {
                .pie .half-circle {border-color: #ffc341;}   
                .shadow {border-color: #ffe5ad;}
            } @else if $percentage < 75 {
                .pie .half-circle {border-color: #dafc1b;}   
                .shadow {border-color: #f0ff9b;}
            } @else  {
                .pie .half-circle {border-color: #04cc04;}   
                .shadow {border-color: #9ee49e;}
            }
        }
    @mixin makePieWrapper($default-size: 40px, $font-s: $font-size-small) {
        @include size($default-size);
        position: relative;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        .pie {
            @include size(100%);
            clip: rect(0, $default-size, $default-size, $default-size * 0.5);
            position: absolute;
            .half-circle {
                @include size(100%);
                border: ($default-size * 0.1) solid #3498db;
                border-radius: 50%;
                clip: rect(0, $default-size * 0.5, $default-size, 0);
                position: absolute;
            }
        }
        .pie-label {
            position: absolute;
            font-size: $font-s;
            line-height: $font-s;
        }
        .shadow {
            @include size(100%);
            border: $default-size * 0.1 solid #bdc3c7;
            border-radius: 50%;
        }
        @for $percentage from 1 through 100 {
            &.progress-#{$percentage} {
                @include draw-progress($percentage);
            }
        }

        &.pie-wrapper--green {
            .pie .half-circle {
                border-color: $assessfirst-green;
            }
            .shadow {
                border-color: $assessfirst-lightgreen;
            }
        }
        &.pie-wrapper--blue {
            .pie .half-circle {
                border-color: $docimo-blue;
            }
            .shadow {
                border-color: $docimo-lightblue;
            }
        }
    }
    .pie-wrapper--small {
        @include makePieWrapper(40px, $font-size-small)
    }
    .pie-wrapper--medium {
        @include makePieWrapper(50px, $font-size-default)
    }

.super-star__container {
    position: relative;
    margin: auto;
    width: 45px;
    text-align: center;
    color: white;

    img {
        width: 100%;
    }
}
.super-star__container--medium {
    width: 55px;
}
.super-star__text {
    position: absolute;
    width: 60px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
}
