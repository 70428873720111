.questions-answers {
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.questions-answers-video__element {
  display: flex;
  justify-content: center;
  flex: 1;
  min-width: 50%;
  max-height: 50%;
  background-color: #282a2d;
}

.questions-answers-video__answer-display {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
  background-color: #282a2d;
  color: white;
  padding: 50px 0;
  overflow: hidden;

  &.questions-answers-video__answer-display--assessment {
    color: black;
    background-color: white;
    border: 1px solid $pinkish-grey;
    border-radius: 3px;
  }
}

  .questions-answers-video__no-video {
      color: white;
  }

  .questions-answers-video__answer-display-inner {
      display: flex;
      flex-direction: column;
      padding: 15px 100px;
      p {
          color: $white;
      }

      &.questions-answers-video__answer-display-inner--assessment {
        p {
          color: $black;
        }
      }

      .predefined-answer-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 10px;

          .answer-text {
              max-width: 300px;
              position: relative;
              &.active {
                  color: $brand-color-2;
                  
                  &::before {
                    content: '\279C';
                    position: absolute;
                    left: -20px;
                    top: -2px;
                  }
              }
          }
          
          .answer-matching {
              
              margin-left: 150px;
              cursor: not-allowed;

              .rzslider {
                  width: 100px;
                  .rz-bubble {
                      color: $white;
                  }
              }
          }

      }
  }

.questions-answers-questions {
display: flex;
flex-direction: column;
flex: 1;
min-height: 50%;
color: $white;

.rz-bubble {
color: $white;
}
}

.questions-answers-questions__label {
display: flex;
justify-content: center;
.label {
  margin: -20px 0 30px;
  width: 80%;
}
}

.questions-answers-questions__container {
  flex: 1;
}
