.ng-quill-editor__l {
    .ql-editor {
        height: 300px;
    }
}

.ng-quill-editor__md {
    .ql-editor {
        height: 200px;
    }
}

.ng-quill-editor__sm {
    .ql-editor {
        max-height: 130px;
    }
}
.ng-readonly-quill-div {
    border: lightgrey 1px solid;
    border-radius: 5px;
    min-height: 150px;
    overflow: auto;
    padding: 20px 15px;
    margin-bottom: 30px;
}
.ng-readonly-quill-div__sm {
    max-height: 130px;
}


.ql-editor {
    @include beehire-formatted-html($black);
    // Preventing spacing in the ng-quill editor
    li.ql-indent-1:not(.ql-direction-rtl),
    li.ql-indent-2:not(.ql-direction-rtl),
    li.ql-indent-3:not(.ql-direction-rtl),
    li.ql-indent-4:not(.ql-direction-rtl),
    li.ql-indent-5:not(.ql-direction-rtl),
    li.ql-indent-6:not(.ql-direction-rtl),
    li.ql-indent-7:not(.ql-direction-rtl),
    li.ql-indent-8:not(.ql-direction-rtl) {
        padding-left: 0 !important;
    }
    // Remove the bullet points (a. , b. , c. , etc.) from the ordered list
    ol li.ql-indent-1:before,
    ol li.ql-indent-2:before,
    ol li.ql-indent-3:before,
    ol li.ql-indent-4:before,
    ol li.ql-indent-5:before,
    ol li.ql-indent-6:before,
    ol li.ql-indent-7:before,
    ol li.ql-indent-8:before {
        content: none !important;
        padding-left: 0em !important; 
    }
    ul, ol{
        padding-left: 1em;
    }
}
.ql-toolbar {
    text-align: left; // to avoid this being overridden 
}

.ql-snow .ql-out-bottom, .ql-snow .ql-out-top {
    visibility: visible;
}

/*  
    Adjust the position of the Quill tooltip, ensuring it remains fully visible (and is not cut off when editing a hyperlink for example)
*/
.ql-tooltip {
    background-color: #ebf3fe !important;
    left: calc(50% - 125px) !important;
}

.generate-with-ai {
    position: absolute;
    top: 21px;
    right: 21px;
    border-radius: 15px;
    padding: 0 10px 0 30px;
    height: 30px;
    border: none;
    background-color: #f3ba3a;
    font-weight: bold;
}

.text-short, .text-long {
    transition: width 0.5s ease, opacity 1s ease;
    width: 0;
    opacity: 0;
    visibility: hidden;
    display: table-column;
    white-space: nowrap;
    overflow: hidden;
}

.text-short.hidden-text {
    width: 0;
    opacity: 0;
    visibility: hidden;
    display: table-column;
}

.text-long.visible-text {
    width: 140px;
    opacity: 1;
    visibility: visible;
    display: flex;
}

/* Initially show the short text */
.generate-with-ai:hover .text-short {
    width: 0;
    opacity: 0;
    visibility: hidden;
    display: table-column;
}

.generate-with-ai .text-short {
    width: 20px;
    opacity: 1;
    visibility: visible;
    display: flex;
}

.generate-with-ai--button-icon {
    height: 20px;
    width: 20px;
    position: absolute;
    left: 5px;
    top: 5px;
    border-radius: 50%;
    background-color: #6f6f6f;
    background-size: 10px 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('/imgs/logo_beehive_yellow.png');
}

.quill-editor-resize--container {
    margin-top: 20px;
    margin-bottom: 20px;
}