.bs-loading-container {
  position: relative;
}

.bs-overlay-custom-class {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255,255,255,0.7);
  z-index: 10000;
}

.bs-overlay-container--absolute {
  position: relative;
  .bs-overlay-custom-class {
    position: absolute;
  }
}
