.candidates--upgrade {
    background: #FFF8CE;
    padding: 20px;
    margin-bottom: 30px;
    border: 1px solid rgba(196, 196, 196, 0.281);
}

    .upgrade--title-cta {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }
        .upgrade--title {
            font-size: $font-size-medium;
            font-family: $font-family-main-medium;
        }
        
    .upgrade--body {

    }

.candidates {
    display: flex;
    flex-direction: row;
    flex: 1;
}

    .candidates__container {
        display: flex;
        flex: 1;
        flex-direction: column;
        width: 100vw;
    }

    .no-candidates {
        @include size(100% 100%);
        display: flex;
        flex: 1;
        // align-items: center;
        justify-content: center;
        min-height: 300px;
        max-height: 1000px;
        text-align: center;
        padding: 0 5rem;
    }

        .no-candidates__title {
            position: absolute;
        }

        .no-candidates__placeholder {
            // @include size(100% 100%);
            min-height: 37.5vw;
            flex: 1;
            background: url('/imgs/placeholder-first-candidate@2x.png') center top / contain no-repeat;
        }

        // .no-candidates__placeholder {
        //     @include size(480px 320px);
        //     background-color: #E7ECED;
        //     display: flex;
        //     flex-direction: column;
        //     justify-content: space-between;
        //     align-items: center;
        //     border-radius: 20px;
        //     padding: 3rem;
        //     // margin: 100px;
        //     margin-top: 5vw;
        //     z-index: 200;
        //     h1 {
        //         font-size: 1.125rem;
        //         font-family: $font-family-main-bold;
        //         // @media (min-width: $screen-sm-min) {
        //         //     font-size: 1rem;
        //         // }
        //         //
        //         // @media (min-width: $screen-md-min) {
        //         //     font-size: 1.2rem;
        //         // }
        //         //
        //         // @media (min-width: $screen-lg-min) {
        //         //     font-size: 1.5rem;
        //         // }
        //         // @media (min-width: 1600px) {
        //         //     font-size: 1.8rem;
        //         // }
        //         // @media (min-width: 1800px) {
        //         //     font-size: 2rem;
        //         //     padding-left: 3%;
        //         // }
        //         // @media (min-width: 2400px) {
        //         //     font-size: 2rem;
        //         //     padding-left: 4%;
        //         // }
        //         // @media (min-width: 3000px) {
        //         //     font-size: 2rem;
        //         //     padding-left: 5%;
        //         // }
        //         // @media (min-width: 3200px) {
        //         //     font-size: 2rem;
        //         //     padding-left: 15%;
        //         // }
        //     }
        // }
        //
        // .no-candidates__arrow {
        //     @include size(43vw 19vw);
        //     min-width: 450px;
        //     min-height: 200px;
        //     // bottom: 50%;
        //     top: 120px;
        //     // left: 50%;
        //     right: 7vw;
        //     position: absolute;
        //     border: 1px solid green;
        //     z-index: 100;
        //     background: url('/imgs/no-candidates-arrow@2x.png') right top / 100% no-repeat;
        // }
