.live-sidebar {
    background-color: #f7f7f7 !important;
    padding: 3rem 2rem !important;
    border-left: 1px solid #ccc
}

.live-sidebar-section {
    width: 100%;
    margin-top: 2rem
}

    .live-sidebar-participants {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    
        .live-sidebar-participant {
            display: flex;
            flex-direction: column;
            gap: 10px;
            justify-content: space-between;
            padding: 15px 15px;
            border: 1px solid grey;
            border-radius: 10px;
        }

        .live-sidebar-participant__candidate {
            background-color: aliceblue;
        }

            .live-participant-info {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            
            .live-paricipants-profile {
                display: flex;
                gap: 12px;
                align-items: center;
            }

                .live-participant-photo {
                    width: 31px;
                    justify-content: center;
                    display: flex;
                    
                    & img {
                        border-radius: 50%;
                        height: 31px;
                        object-fit: cover;
                        width: 31px;
                    }

                    & i {
                        color: grey;
                    }
                }

            .live-participant-actions {

            }
                .live-actions {
                    display: flex;
                    gap: 5px;
                }

    .live-sidebar-title {
        // text-align: center;
        // padding: 0 4rem;
        h2 {
            margin-bottom: 10px;
        }
    }

    .live-sidebar-notes__textarea {
        flex: 1;
        resize: none;
    }
