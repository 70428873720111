//
//  https://css-tricks.com/centering-css-complete-guide/
//

.vertical-align {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}


.vertical-align--flexbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
