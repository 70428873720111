.bg {
    background-position: center;
    background-size: cover;
    background-color: white;
}

.bg--preview {
    display: block;
    @include size(700px 500px);
}

.bg--preview-h {
    display: block;
    @include size(900px 100px);
}

/*
---
name: Sky blue
category: backgrounds/gradients
tag:
  - Backgrounds
---

```jade
// use without .bg--preview class
.bg.bg--preview.bg--gradient-sky
```
*/

    .bg--gradient-sky {
      background-image: $bg--gradient-sky;
    }
    
    .bg--gradient-green { 
      // colors of BOSA
      background-image: linear-gradient(330deg,hsl(192,100%,21%),hsl(188,93%,28%));
    }

    .bg--gradient-yellow-light {
      background-image: linear-gradient(330deg, $brand-yellow, lighten($brand-yellow, 20%));
    }

    .bg--gradient-yellow-dark {
      background-image: linear-gradient(330deg, $brand-yellow, darken($brand-yellow, 20%));
    }

    .bg--yellow {
      background-image: linear-gradient(0deg, $brand-yellow, $brand-yellow);
    }
    .bg--yellow-beehives {
      background-image: url('/imgs/login-yellow-beehives.jpg');
    }

/*
---
name: Dark
category: backgrounds/gradients
tag:
  - Backgrounds
---

```jade
// use without .bg--preview class
.bg.bg--preview.bg--gradient-dark
```
*/

    .bg--gradient-dark {
        background-image: linear-gradient($black, $black),
                          linear-gradient(330deg, #0098ce, #1cb5b4),
                          linear-gradient(#a8a8a8, #a8a8a8);
    }
/*
---
name: Waiting
category: backgrounds/page
tag:
  - Backgrounds
---

/*
---
name: Building
category: backgrounds/page
tag:
  - Backgrounds
---

```jade
// use without .bg--preview class
.bg.bg--preview.bg--building
```
*/

    .bg--building {
        background-image: url(/imgs/bg-building@2x.jpg);
        background-position: center bottom;
    }

/*
---
name: Towers
category: backgrounds/page
tag:
  - Backgrounds
---

```jade
// use without .bg--preview class
.bg.bg--preview.bg--towers
```
*/

    .bg--towers {
        background-image: url(/imgs/bg-towers@2x.jpg);
    }

/*
---
name: Pictures on the wall
category: backgrounds/headings
tag:
  - Backgrounds
---

```jade
// use without .bg--preview-h class
.bg.bg--preview-h.bg--pics-on-wall
```
*/

    .bg--pics-on-wall {
        background-image: url(/imgs/bg-pictures-on-the-wall@2x.jpg);
    }

/*
---
name: Working
category: backgrounds/headings
tag:
  - Backgrounds
---

```jade
// use without .bg--preview-h class
.bg.bg--preview-h.bg--working
```
*/

    .bg--working {
        background-image: url(/imgs/bg-working@2x.jpg);
    }

/*
---
name: Meeting
category: backgrounds/headings
tag:
  - Backgrounds
---

```jade
// use without .bg--preview-h class
.bg.bg--preview-h.bg--meeting
```
*/

    .bg--meeting {
        background-image: url(/imgs/bg-meeting@2x.jpg);
    }



    .bg--typing {
        background-image: url(/imgs/bg-man@2x.jpg);
    }
