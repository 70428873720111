.submission-assessment {
    flex: 1;
    display: flex;
    flex-direction: column;

    .submission-assessment--questions-container {
        flex: 1;
        height: 100%;
    }

    questions-answers {
        height: 100%;
    }

    ng-dropdown-multiselect.filter__dropdown .dropdown-toggle {
        padding: 1rem 2rem;
    }

    .submission-assessment--summary {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;
    }
    
    .submission-assessment--summary-section {
        display: flex;
        flex-direction: column;
        gap: 5px;
        font-size: .875rem;
        a {
            color: #355775;
            text-decoration: underline;
        }
        li {
            font-size: $font-size-small;
            .fa-exclamation-triangle {
                color: $upgrade-color;
                margin-right: 5px;
            }
        }
    }

    .submission-assessment--CTA-invite {
        width: fit-content;
        margin-top: 25px;
    }
}

.submission-assessment--title__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-bottom: 8px;
}

.submission-assessment--title__container h1 {
    margin: 0;
}