.image-badge {
    @include size(15px);
    border-radius: 100px;
    margin-right: .5rem;
}

    .image-badge--yellow {
        background: url('/imgs/badge-repeat@2x.png') center / contain no-repeat;
    }

    .image-badge--green {
        background: url('/imgs/badge-accepted@2x.png') center / contain no-repeat;
    }

.badge {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: 3px 6px;
    min-width: 24px;
    border-radius: 20px;
    font-family: $font-family-main-book;
    font-size: $font-size-smaller;
    line-height: $font-size-base;
    text-transform: none;
    color: $white;
    background-color: $black;

    a, i {
        margin-bottom: 0px;
        margin-left: 10px;
    }

    & > span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.badge-content {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
}

.badge-container {
    display: flex;
}

.badge--clickable {
    cursor : pointer
}

.badge--inline {
    margin: 0px 3px;
    display: inline-block;
    span {
        margin-right: 2px;
        line-height: 1;
    }
}

.badge--bold {
    font-size: $font-size-small;
    font-family: $font-family-main-bold;
    padding-top: 4px;
}

.badge--s {
    padding: 0px 5px;
}

.badge--m {
    font-size: $font-size-small;
    padding: 6px 8px;
}

.badge--xl {
    font-size: $font-size-small;
    padding: 9px 13px;
    max-height: 30px;
}

.badge--social {
    font-size: $font-size-small;
    padding: 7px 10px 4px;
    min-width: 33px;
}

.badge--top-right-container {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(10px, -10px);
}

.badge--absolute-parent {
    position: relative;
}

.badge--absolute {
    position: absolute;
}

.badge--inline {
    display: inline-flex;
    margin-left: 5px;
}

.badge--inline-parent {
    display: flex;
    align-items: center;
}

.badge--top-right {
    margin-top: -25px;
    margin-left: 0px;
}

.badge--red {
    background-color: $warning-color;
    color: #fff;
}

.badge--orange {
    background-color: $upgrade-color;
    color: #fff;
}

.badge--grey {
    background-color: $background-light-grey;
    color: $black;
    border: 1px solid $black;
}

.badge--green {
    background-color: #28bd83;
    color: #fff;
}

.badge--posting {
    padding-left: 10px;
    padding-right: 10px;
    .icon {
        height: 18px;
        width: 18px;
        margin-right: 10px;
    }
}
.badge--posting-posted {
    background-color: #28bd83;
    color: #fff;
}
.badge--posting-expired {
    background-color: #333333;
    color: #fff;
}
.badge--posting-not-posted {
    background-color: #cccccc;
    color: #fff;
}
.badge--posting-pending {
    background-color: #7BC6EA;
    color: #fff;
}
.badge--posting-inactive {
    background-color: #333333;
    color: #fff;
}

.badge--tag {
    background-color: $tag-background-color;
    color: $tag-text-color;
    border: $tag-border;
}

.badge--job-category {
    background-color: transparent;
    color: $job-category-color-1;
    border: 1px solid $job-category-color-1;
}

.badge--campaign-status {
    background-color: white;
    color: $job-category-color-1;
    border: 1px solid $job-category-color-1;
    border-radius: 3px;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: color 0.4s ease, background-color 0.4s ease;
    display: block;
}

.badge--campaign-status-card {
    position: absolute;
    top: -103px;
    left: 10px;
}

.badge--campaign-status.can-edit-campaign-status:hover {
    cursor: pointer;
    color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('/imgs/icons/profile-sidebar/icon-edit-x32.png');
    background-size: 14px 14px;
}
  

.badge--job-category--filled {
    background-color: $job-category-color-1;
    color: #fff;
}

.badge--live {
    background-color: #52B3EB !important;
}

.badge--physical {
    background-color: #56b4e8 !important;
}

.margin-between-badges {
    margin-left: 10px;
}