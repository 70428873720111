.account-branding {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
}

    .account-branding__container {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      padding: 0px 60px;
      background-color: rgba(0,0,0,.3);
    }

        .account-branding__container-inner {
          width: 100%;
          max-width: 600px;
          margin: auto;
          padding: 50px 0;
          // @media (max-height: 860px) {
          //     zoom: .8;
          // }
        }

            .account-branding__uploads {
              display: flex;
              width: 100%;
              padding: 30px 70px;
              flex-direction: column;
              align-items: center;
              background: rgba(255,255,255,.8);
              border-radius: 3px;
              span, p {
                color: #333;
              }
              span {
                font-size: $font-size-small;
                font-family: $font-family-main-bold;
                letter-spacing: 0.3;
                margin-bottom: 10px;
              }
              p {
                font-size: $font-size-small;
              }
              a {
                text-decoration: underline;
                transition: all 0.3s ease-in-out;
                font-size: $font-size-small;
                &:hover {
                  color: #999;
                }
              }

              img {
                max-width: 200px;
                max-height: 100px;
                width: auto;
                height: auto;
                padding: 10px;
              }
            }

            .branding__uploads--grey {
              background-color: $input-field-grey;
            }


              .branding__upload-item {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                align-self: center;
                + .branding__upload-item {
                  margin-top: 1.5rem;
                }
                .icon--help {
                  margin-left: 20px;
                }
              }

              .branding__tooltip-container {
                display: flex;
              }

.branding__quill-editor {
  border-radius: 3px;
  padding-top: 15px;

  .language-picker {
    margin-left: 15px;
  }
}

  .setting__checkbox {
    padding: 20px;
    background: white;
    border-radius: 3px;
  }
  .setting__checkbox--grey {
    background: $input-field-grey;
  }
              