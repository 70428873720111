.campaign-vonq-checkout {
  width: 100%;
  display: flex;
  flex-direction: column;

  .vonq-checkout__content {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .vonq-checkout__heading {
    display: flex;
    justify-content: space-between;
  }

  .campaign-vonq-checkout-1__container {
    .label--input {
      margin-bottom: 10px;
      padding: 0;
    }
  }

  .vonq-checkout__info {
    min-width: 400px;
    width: 60%;
    margin: 10px;

    .checkout-info__group-title {
      margin-top: 20px;
    }

    & h2 a {
      margin-left: 10px;
    }

    & .fa-external-link-alt {
      margin-left: 3px;
      font-size: $font-size-default;
    }

    & ng-dropdown-multiselect {
      width: 100%;
      padding: 0px;

      & .dropdown-toggle {
        padding: 20px;
        @include append__arrow-down(0, $grey-darker);

        &:disabled {
          color: $grey-darker;
        }
      }
    }

    .badges-row {
      .badge {
        margin: 2px 0px;
      }
    }
  }

  .vonq-checkout__cart {
    flex-grow: 0;
    width: 400px;
    background-color: #fff;
    border-radius: 5px;
    height: fit-content;
    padding: 20px;

    .btn-continue {
      width: 280px;
      margin: 20px auto 0px;
    }
  }

  .img--logo-sm {
    max-width: 200px;
    max-height: 100px;
    width: auto;
    height: auto;
    padding: 10px;
  }

  .multi-input-field {
    display: flex;
    gap: 20px;
    align-items: center;

    & .label {
      min-width: 50px;
    }
  }

  .step-actions {
    display: flex;
    margin-top: 15px;
    
    & a {
      margin-left: 10px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}