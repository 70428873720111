// settings for this page
$banner-height: 80px;

//////////////////////
//
//      HEADER
//
//////////////////////


.candidateprofile__header {
    @include size(100% 400px);
    background-color: $brand-yellow;
    background-size: cover;
    background-position: center;
    //margin-top: $banner-height;
    background-image: url('/imgs/candidate-welcome-background.jpg'); // default background image, can be overwritten
}

    .header__overlay {
        @include size(100% 100%);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0,0,0,.3);
    }

        .header__title {
            color: white;
            text-shadow: 0 0 1px #000;
            font-size: 54px;
            font-family: $font-family-ternary-semi;
            padding: 10px;
            text-align: center;
            margin-bottom: 0px;
        }