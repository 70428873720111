.collaborators {
    display: flex;
    flex-direction: column;
    flex: 2;

    .collaborator__header__name {
        flex: 1 200px;
        min-width: 200px;
    }

    .collaborator__header__email {
        width: 250px;
        flex: 0 0 250px;
        word-break: break-all;
    }

    .collaborator__header__position {
        width: 150px;
        flex: 0 0 150px;
    }

    .collaborator__header__user-rights {
        width: 135px;
        flex: 0 0 135px;
        .badge {
            max-width: 100%;
        }
    }
    .collaborator__header__campaigns {
        width: 165px;
        flex: 0 0 165px;
        .badge {
            max-width: 60%;
        }
    }

    .list-filter {
        .collaborator__header__badge:last-child {
            margin-right: 118px;
        }
    }

    .suspended-collaborators-section {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;

        .suspended-collaborators-table {
            width: 100%;
        }
    }
}

    .collaborators__container {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: flex-start;
        position:relative;

        crud-list {
            width: 100%
        }
    }

        .collaborators__members {
            flex: 1;
        }

.collaborators__details {
    margin: 30px 0;
}

.collaborators__summary {
    font-size: $font-size-medium;
    margin-bottom: 30px;
}