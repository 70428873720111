.dropdown-suggestion {
    .dropdown {
        display: none;
        background-color: #283137;
        position: absolute;
        padding: 10px 50px;
        border-radius: 3px;
        text-align: center;
        li {
            padding: 10px 20px;
            a {
                font-family: $font-family-main-book;
                font-size: $font-size-default;
                transition: all 0.25s ease-in-out;
                padding: 10px 0;
                color: #fff;
                &:hover {
                    color: rgba(255,255,255,.8);
                }
            }
            + li {
                // margin-top: 20px;
            }
        }
        &.active {
            display: block;
        }
        z-index: 10000;
        transform: translateX(-50%);
    }
    + .main-modal__form-row {
        margin-top: 25px;
    }
}

.dropdown-suggestion-scroll {
        max-height: 300px;
        overflow-y: auto;
        margin-top: -30px;
        width: 85%;
}