//
// rzslider Sliders
//

$slider-bar-color: black;
$slider-bar-height: 2px;
$pointer-color: $brand-color-3-lighter;
$pointer-height: 16px;
$ticks-color: $slider-bar-color;

.rzslider {

  width: 120px;
  margin: 10px;

  // horizontal bar
  .rz-bar {
    background: $slider-bar-color;
    height: $slider-bar-height;
  }

  // displaceable ball
  .rz-pointer {
    background-color: $pointer-color;
    width: $pointer-height;
    height: $pointer-height;
    top: calc(#{$slider-bar-height} / 2 - #{$pointer-height} / 2);
    transition: left .4s ease-in-out, background-color .2s ease-in-out; // how the pointer moves when clicking on a tick (property 'left'), and when disabling it (property 'background-color')

    &:focus {
      outline: none;
    }
  }

  // center of the pointer
  .rz-pointer:after {
    display: none;
  }

  // ticks, if any
  .rz-tick {
    background: $ticks-color;
  }

  // numbers displayed above the slider
  .rz-bubble {
    top: -26px;
    &.rz-limit { // floor and ceil
      display: none;
    }
  }
  
  &.null-weight {

    .rz-bar {
      background: $slider-bar-color;
      height: $slider-bar-height;
    }

    .rz-pointer {
      background-color: black;
    }
  }
}
