#onboarding-widget {
    display: none;
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 1001;
    cursor: pointer;

    // background: colored circle
    height: 50px;
    width: 50px;
    background-color: #6f6f6f;

    // centerer beehire logo
    background-size: 30px 30px;
    background-image: url($image-onboarding);
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
}

.introjs-helperLayer {
}
    .introjs-helperLayer__dark {
        background-color: hsla(0, 0%, 50%, 0.9);       
    }
.introjs-tooltipReferenceLayer {
}
    .introjs-helperNumberLayer {
        width: 26px;
        height: 26px;
        line-height: 29px;
        font-size: $font-size-default;
        font-family: $font-gotham-medium;
        background: $brand-yellow;
    }
    .introjs-tooltip {
        padding: 30px;
        min-width: 400px;
    }
        .introjs-tooltiptext {
            text-align: center;
            margin-bottom: 1.25rem;
            p+p {
                padding-top: 0.5rem;
            }
            b {
                font-family: $font-gotham-medium;
            }
        }
            .onboard-title {
                font-family: $font-gotham-medium;
                font-size: $font-size-default;   
                line-height: 1.2;
                letter-spacing: .4px;
                margin-bottom: 0.8rem;
            }
        .introjs-progress {
            margin: 20px;
        }
            .introjs-progressbar {
                background-color: $brand-yellow;
            }
        .introjs-tooltipbuttons {
        }
            .introjs-button {
                font-size: $font-size-small;
                font-family: $font-gotham-medium;
            }