.c-calendar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

    .c-calendar__dropdown {
        margin-bottom: 1rem;
        align-self: center;
        // padding-right: 35px;
        .wrap-dd-menu {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 400px;
        }
        .dropdown__link {
            font-family: $font-family-main-book;
            font-size: $font-size-large;
            // letter-spacing: 5.6px;
            color: #333;
            padding: 0 40px;
            background: url('/imgs/icons/icon-cal-dropdown@2x.png') 100% center / 14px 7px no-repeat;
        }
        .dropdown {
            transform: none;
            position: relative;
            // margin: 10px 0 -370px 15px;
            min-width: 300px;
            max-width: 300px;
            max-height: 360px;
            overflow: scroll;
            -ms-overflow-style: -ms-autohiding-scrollbar;
            flex-direction: column;
            align-items: center;
            padding: 0 !important;
            &.active {
                display: flex;
                margin: 0 0 -335px 0;
            }
            li {
                text-align: right;
                width: 150px;
                &:first-child {
                    margin-top: 3.75rem;
                }
                &:last-child {
                    margin-bottom: 3.75rem;
                }
                > a {
                    //font-family: $font-family-main-regular;
                    font-size: $font-size-base;
                    text-transform: uppercase;
                    letter-spacing: 3px;
                    //color: rgba(255,255,255,.5);
                    transition: none;
                    &:hover, &.active {
                        color: $brand-color-1;
                        font-family: $font-family-main-bold;
                    }
                    &.active {
                        //font-family: $font-family-main-bold;
                    }
                }
            }
        }
    }

    .c-calendar__today-button {
        align-self: center;
        margin: 1rem 0 3rem;
        //background: $grey-light;
        color: $black;
        //font-size: $font-size-smaller;
        text-transform: uppercase;
        padding: .5rem 2rem;
        border-radius: 3px;
    }

    .c-calendar__container {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

        .c-calendar__left-arrow, .c-calendar__right-arrow {
            @include size(40px 28px);
            opacity: .1;
            transition: all 0.25s ease-in-out;
            cursor: pointer;
            &:hover {
                opacity: .5;
            }
        }

        .c-calendar__left-arrow {
            background: url('/imgs/cal-left-arrow@2x.png') center / contain no-repeat;
        }

        .c-calendar__right-arrow {
            background: url('/imgs/cal-right-arrow@2x.png') center / contain no-repeat;
        }

        .c-calendar-table {
            width: 70%;
            text-align: center;
            .empty {
              visibility: hidden;
            }
            td {
              cursor: pointer;
            }
        }

            .c-calendar-table__days {
                border-bottom: 1px solid #333;
                th {
                    font-family: $font-family-main-bold;
                    font-size: $font-size-base;
                    color: #999;
                    @include size(14% 3vw);
                    border-bottom: 4px solid transparent;
                    &.active {
                        color: $brand-color-2;
                        border-bottom: 4px solid $brand-color-2;
                    }
                    &.empty {
                        visibility: hidden;
                        cursor: default;
                    }
                }
            }

            .c-calendar-table__dates {
                td {
                    font-family: $font-family-main-book;
                    font-size: $font-size-large;
                    color: #283137;
                    @include size(14% 5vw);
                    border-radius: 300px;
                    cursor: pointer;
                    transition: color 0.25s ease-in-out;
                    padding-top: 2px;
                    &.past, &:hover {
                        color: #dfdfdf;
                    }
                    &.has-events {
                        font-family: $font-family-main-bold;
                    }
                    &.today {
                        color: $brand-color-2;
                        font-family: $font-family-main-bold;
                    }
                    &.active {
                        color: #fff;
                        background: url('/imgs/cal-active-circle@2x.png') center / auto 70% no-repeat;
                    }
                }
            }
.c-live-interview_hidden {
    position: absolute;
    left: -200px;
    top: 300px;
}