/*
---
name: Documents table
category: components/documents-table
tag:
  - Table
---

```jade
table.documents-table
    tr
        th
        th File Name
        th Size
    tr
        td
            .icon.icon--download
        td Curiculum Vitae Julian Ohayon 2016.pdf
        td 278kb
    tr
        td
            .icon.icon--download
        td Curiculum Julian Ohayon - Motivation letter 2016.pdf
        td 194kb
    tr
        td
            .icon.icon--download
        td Recommendation_Goldman&Sachs.pdf
        td 101kb
```
*/

.documents-table {
    width: 100%;
    text-align: left;
    line-height: 80px;
    background-color: #fff;
    font-size: $font-size-small;
    border-radius: 3px;
    tr {
        border: 1px solid #ddd;
        &:last-child {
            border-radius: 3px;
        }
    }

        th, td {
            padding-left: 5px;
        }

        th {
            border-top: 10px solid $brand-color-1;
            font-family: $font-family-main-bold;
            font-weight: normal;
        }

        td {
            border: none;

            // .icon {
            //     position: relative;
            //     top: 5px;
            //     left: 20px;
            // }

            &:nth-child(1) {
                width: 70px;
            }

            &:nth-child(3) {
              width: 20%;
            }
        }
}

    .documents-table__actions {
        display: flex;
        justify-content: end;
        gap: 10px;
    }

    .documents-table--no-download {
      td {
        &:nth-child(1) {
          width: 50px !important;
        }
      }
    }

    .documents-table__question {
      // font-weight: bold;
      margin: 0;
      padding: 0;
      line-height: 1.5em;
    }

    .documents-table__answer {
      display: list-item;
      list-style-type: disc;
      list-style-position: inside;
      margin: 0;
      padding: 0;
      line-height: 1.5em;
    }
