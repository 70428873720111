.candidate-message__section {
    width: 100%;

    summary h4 {
        // necessary to show the arrowhead of details summary
        display: inline;
    }

    & + & {
        margin-top: 30px;
    }

    tooltip {
        position: absolute;
        margin-left: 50px;
    }

    &.live-interview {
        margin-bottom: 20px;
    }
}

    .candidate-message__text {
        margin-top: 10px;
    }

    .candidate-message__content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: start;
        margin-top: 10px;
        gap: 5px
        // padding: 1rem 0;
    }

    .candidate-message__item {
        cursor: pointer;
        margin: 0 5px;
        &.active {
            font-family: $font-family-main-medium;
        }
    }
    .candidate-message__item--underlined {
        text-decoration: underline;
    }
    .candidate-message__customization {
        background-color: #52b3eb;
    }

    .candidate-message__schedule-send-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        margin-top: 30px;
      }
      
      .candidate-message__schedule-send-checkbox {
        display: flex;
        align-items: center;
      }
      
      .candidate-message__document-radio-field__flex--centered {
        display: flex;
        justify-content: center;
        gap: 20px;
      }

      .candidate-message__document-radio-field__input-checkbox {
        display: flex;
        align-items: center;
      }
      
        .candidate-message__document-radio-field__input-radio {
            display: flex;
            align-items: center;
            gap: 8px;
        
            input[type="radio"] {
            appearance: none;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            border: 2px solid black;
            background-color: white;
            position: relative;
            cursor: pointer;
            
            &:checked {
                background-image: url('/imgs/icons/icon-checked-round@2x.png');
                background-repeat: no-repeat;
                background-position: center;
                background-size: 10px;
              }
            }
        
            span {
            font-size: 14px;
            }
        }
      
    .candidate-message__document-radio-field__label {
        display: flex;
    }

    .candidate-message__document-radio-field__span {
        margin-left: 4px;
    }