collaborators-dropdown {
  .collaborators-dropdown {
    min-width: 90px;
  }

  .profiles__members {
    display: inline-flex;
    flex-direction: row-reverse;
    margin: 10px 0 0;
    .icon {
        margin-left: 20px;
        cursor: pointer;
    }

    .profiles__members-photo {
      @include size(38px);
      margin: 0;
      margin-right: -15px;
      + .profiles__members-photo {
          // border: 2px solid red;
      }

      border: 2px solid $white;
      border-radius: 100px;
      .profiles__members-creator{
        border:2px solid red;
      }
    }

    .profiles__members-photo-creator{
        @include size(38px);
        margin: 0;
        margin-right: -15px;
          border:3px solid #385474;
      
    }

    .profile-photo {
      border-radius: 50%;
      object-fit: cover;
    }
  }
}

.profiles__avatars-container{
  display:flex;
}


ng-dropdown-multiselect.collaborators {
  .multiselect-parent {
    .dropdown-toggle {
      width: 38px;
      height: 38px;
      margin-right:6px;
      background-image: url(/imgs/icons/icon-user-plus.png);
      background-size: 22px;
      background-position-x: 10px;
      background-position-y: center;
      background-repeat: no-repeat;
      background-color: #45494C;
      border-radius: 30px;
      color: $black;
      font-family: Gotham Book;
      font-size: $font-size-default;
    }
    .dropdown-menu {
      min-width: 290px;
    }
  }
}

.hover-dropdown {
  position: relative;
  display: inline-block;
  margin-left:8px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background: #282A2D;
  min-width: 220px;
  max-height: 50vh;
  border-top: none;
  border-radius: 3px;
  box-shadow: 0 10px 24.5px 0 rgba(0, 0, 0, 0.52);
  padding: 20px;
  color: #fff;
  z-index:999999;

}
.extra-collaborator{
  display: block;
  padding: 6px;
  text-decoration: none;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; 
}

.hover-dropdown:hover .dropdown-content {
  display: block;
}

.plus--extra-collaborators {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  color: black; 
  text-align: center;
  margin-left: 8px;
  cursor: pointer;
}

.plus--extra-collaborators-task {
  margin-left: 6px;
}

.container-collaborators-task {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}