.campaigns-controls {
    width: 100%;
    margin-bottom: 20px;
    // border: 1px solid green;
    display: flex;
    justify-content: space-between;
    gap: 20px 10px;

    &.campaigns-controls--sticky {
        position: sticky;
        left: 0px;
        z-index: 1;
    }
}

    .campaigns-controls__actions {
        flex: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .campaigns-controls__heading {
        display: inline-flex;
        align-items: center;
        margin-top: 7px;
        h1 {
            margin: 0;
        }
        span {
            margin-left: 8px;
        }
    }

        .campaigns-controls__add-campaign {
            margin-left: 10px;
        }

            .campaigns-controls__icon {
                position: relative;
                cursor: pointer;
                margin: 0 10px;
                display: flex;
                align-items: center;
                
                &:hover .icon {
                    opacity: .5;
                }
            }

            .icon--add-campaign {
                @include make_circle_cross($grey-darkerer, 35px, 3px, 20px);
            }
            
    .campaigns-controls__filters--search {
        margin-left: auto;
        justify-content: flex-end;
    }
    .campaigns-controls__filters {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 30px;
        flex-wrap: wrap;
        .input-field {
            margin-bottom: 0;
        }
    }

        .filter__item {
            width: auto;
        }

        .filter__item--selected > span {
            font-family: $font-family-main-medium;
        }

        .filter__item__search {
            min-width: 270px;
            width: auto;
            text-align: left;
            font-size: $font-size-default;
        }

        .filter__item__dropdown {
            cursor: pointer;
            &.active {
                .dropdown {
                    visibility: visible;
                }
            }
            &.wrap-dd-select {
                padding: 0 25px 0 0;
                margin: 0px;
                &::after {
                    right: 0px;
                }
            }
        }

        .filter-clear {
            cursor: pointer;
            background-image: url('/imgs/icons/icon-clear-filter.svg');
            width: 27px;
            height: 27px;
        }

        // overwrite properties of ng-dropdown-multiselect buttons
        // changed : moved the code to object dropdown multiselect (generic)
        ng-dropdown-multiselect.filter__dropdown {
            .multiselect-parent {
                .dropdown-toggle {
                    border: none;
                    background-color: transparent;
                    padding: 0;
                    outline:none;
                    @include append__arrow-down(0, $black);
                    color: $black;
                    font-family: Gotham Book;
                    font-size: $font-size-default;
                }
            }

            &.filter__item--selected {
                .multiselect-parent {
                    .dropdown-toggle {
                        font-family: $font-family-main-medium;
                    }
                }
            }
        }

    .campaigns-controls__btn {
        width: 250px;
        margin-bottom: 10px;
        font-size: $font-size-default;
        background-color: $brand-color-3;
    }

    .campaigns-controls-export-csv__btn {
        background-image: url('/imgs/icons/icon-csv-export.svg');
        padding: 12px;
    }

    .campaigns-controls__link {
        text-align: center;
        text-decoration: underline;
        margin-top: 5px;
        span:first-child {
            text-decoration: underline;
        }
    }
    
    .campaigns-controls__btn--outlined {
        color: $brand-color-3;
        background-color: transparent;
        font-weight: bold;
        box-shadow: inset 0px 0px 0px 3px $brand-color-3;
        &:hover {
            background-color: $brand-color-3;
            color: $white;
        }
    }
     
    .campaigns-controls__btn--primary {
        font-family: $font-family-main-medium;
        background-color: $brand-color-1;
    }

    .campaigns-controls__subscription-upgrade-banner {
        background-color: white;
        padding: 15px;
        border-radius: 10px;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0;
        > *:not(:last-child) {
            margin-right: 15px;
        }
    }
    .campaigns-controls__campaign-request-validation {
        justify-content: center;
        text-align: center;
        align-items: center;
        gap: 10px;
        margin-top: 10px;
        display: flex;

        &-container {
          width: 600px;
          border: 1px thin grey;
          border-radius: 5px;
          background-color: white;
          padding: 20px;
        }

        &-pending {
          font-size: 16px;
          font-weight: bold;
          display: flex;
          align-items: center;
          gap: 10px;
          margin-bottom: 15px;
          justify-content: center;
        }

        &-rejected {
            font-size: 16px;
            font-weight: bold;
            color: #dc3545;
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 24px;
            justify-content: center;
        }

        &-paragraph {
            margin-bottom: 12px;
        }

        &-buttons {
          align-items: center;
          justify-content: center;
          gap: 10px;
          margin-top: 10px;
      
          &.accept-reject {
            margin-top: 24px;
      
            .icon-approved {
              color: #28a745;
              font-size: 20px;
              margin-right: 10px;
            }

            .icon-rejected {
              color: #dc3545;
              font-size: 20px;
              margin-right: 10px;
            }

            .btn-revote {
              background: none;
              border: none;
              color: #007bff;
              cursor: pointer;
              padding: 0;
              text-decoration: underline;
              margin: 10px 0;
      
              &:hover {
                text-decoration: none;
              }
            }
          }

          &.show-status{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            margin-top: 24px;
          }
        }

        .campaign-request-buttons-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 10px;
      
          div {
            display: flex;
            gap: 10px;
          }
        }
      }