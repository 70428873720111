.submission-candidatehistory {
    flex: 1;
}
    .submission-candidatehistory__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .submission-candidatehistory__container {
        // display: flex;
        // flex: 3;
        // flex-direction: column;
        // max-width: 100%;
        // h1 {
        //     margin-bottom: 20px;
        // }
        // padding: 50px;
    }

    .submission-candidatehistory .inner {
        overflow: auto;
        height: 200px;
    }


    .element, .outer-container {
        // width: 400px;
        height: 300px;
       }
        
       .outer-container {
        // border: 5px solid purple;
        position: relative;
        overflow: hidden;
       }
        
       .inner-container {
        position: absolute;
        left: 0;
        overflow-x: scroll;
        overflow-y: scroll;
       }
        
       .inner-container::-webkit-scrollbar {
        display: none;
       }