// 
// System font 
// 

@include font-face("Poppins Light", "/fonts/Poppins/Poppins-Light", 300, normal);
@include font-face("Poppins Light", "/fonts/Poppins/Poppins-LightItalic", 300, italic);
@include font-face("Poppins Regular", "/fonts/Poppins/Poppins-Regular", 400, normal);
@include font-face("Poppins Regular", "/fonts/Poppins/Poppins-RegularItalic", 400, italic);
@include font-face("Poppins Medium", "/fonts/Poppins/Poppins-Medium", 500, normal);
@include font-face("Poppins Medium", "/fonts/Poppins/Poppins-MediumItalic", 500, italic);
@include font-face("Poppins SemiBold", "/fonts/Poppins/Poppins-SemiBold", 600, normal);
@include font-face("Poppins SemiBold", "/fonts/Poppins/Poppins-SemiBoldItalic", 600, italic);
@include font-face("Poppins Bold", "/fonts/Poppins/Poppins-Bold", 700, normal);
@include font-face("Poppins Bold", "/fonts/Poppins/Poppins-BoldItalic", 700, italic);

@include font-face("Gotham Light", "/fonts/gotham/Gotham-Light");
@include font-face("Gotham Book", "/fonts/gotham/Gotham-Book");
@include font-face("Gotham Medium", "/fonts/gotham/Gotham-Medium"); 
// @font-face {
//     font-family: "Gotham Medium";
//     src: url("/fonts/gotham/Gotham-Bold") format("woff2"),
//            url("/fonts/OpenSans-Regular-webfont.woff") format("woff");
//    }
// @include font-face(
//     "Gotham Medium",
//     "fonts/gotham/Gotham-Bold"
// ) {
//     font-style: bold;
//     font-weight: 700;
// }
@include font-face("Gotham Bold", "/fonts/gotham/Gotham-Bold");
@include font-face("Gotham Black", "/fonts/gotham/Gotham-Black");
// @include font-face("Gotham Ultra", "/fonts/gotham/Gotham-Ultra"); // missing

@include font-face("ChronicleDisplay Black", "/fonts/ChronicleDisplay/ChronicleDisplay-Black");

@include font-face("FuturaPT Demi", "/fonts/FuturaPT/FuturaPT-Demi");
@include font-face("FuturaPT Bold", "/fonts/FuturaPT/FuturaPT-Bold");

@include font-face("Lato Light", "/fonts/lato/Lato-Light");

// 
// Fonts for clients' custom career page
// 

// Raleway (Villa Lorraine)
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,700;1,400;1,700&display=swap');
// Flanders Art Serif Regular (Vlaanderen connect)
@include font-face("FlandersArtSans Regular", "/fonts/FlandersArtSans/FlandersArtSans-Regular");
// Omnes Regular (Wallonie Entreprende)
@include font-face("Omnes", "/fonts/Omnes/OmnesRegular");
// Rubik (eBloom)
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');