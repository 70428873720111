  .modal--schedule-send__option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #eee;
    background-color: #fff;
    margin: 5px 0;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color 0.2s ease, box-shadow 0.2s ease;
    min-width: 450px;
    &:hover {
        background-color: #f2f9ff;
    }
  }

  .modal--schedule-send__option-label {
      flex: 1;
      text-align: left;
  }

  .modal--schedule-send__option-time {
      text-align: right;
      color: #555;
      font-family: $font-gotham-medium;
  }

  
  .modal--schedule-send__custom-date {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    font-family: $font-gotham-medium;

    i {
      font-size: 16px;
    }
  }

  .modal--schedule-send__custom-section {
    padding: 10px;
    width: 400px;
  }

  .modal--schedule-send__date-picker,
  .modal--schedule-send__time-picker {
    margin-top: 10px;

    label {
      display: block;
      margin-bottom: 5px;
      font-family: $font-gotham-medium;
    }
  }

.modal--schedule-send__errors {
  margin-top: 8px;
}
  
.modal--schedule-send__option.selected {
  background-color: #eaf6ff;
  font-family: $font-gotham-medium;;
}