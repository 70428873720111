/*
---
name: Icons
category: icons/PNGS
tag:
  - Icons
---

```jade
.icon.icon--help
.icon.icon--help-dark
.icon.icon--help-darken
.icon.icon--add
.icon.icon--add-big
.icon.icon--add-campaign
.icon.icon--settings
.icon.icon--close
.icon.icon--email
.icon.icon--bookmark
.icon.icon--bookmark.icon--bookmark--checked
.icon.icon--search
.icon.icon--search-big
.icon.icon--star
.icon.icon--star.icon--star--filled
.icon.icon--star-border
.icon.icon--star-border--filled
.icon.icon--star-popup
.icon.icon--star-popup.icon--star-popup--filled
.icon.icon--delete
.icon.icon--play
.icon.icon--menu
.icon.icon--menu.icon--menu--black
.icon.icon--tests
.icon.icon--download
.icon.icon--warning
.icon--dropdown
.icon--powered-by
```
*/

.icon {
    display: inline-block;
    background-position: center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.icon--spaced {
    margin-left: 30px;
}

.icon-circle {
    background-color: #f3f3f3f3;
    border-radius: 50%;
    display: flex;

    &:hover {
        background-color: #fcfcfc;
    }
}
.icon-circle--no-hover {
    &:hover {
        background-color: #f3f3f3f3;
    }
}
.icon-circle--black {
    color: #f3f3f3f3;
    background-color: $black;
    &:hover {
        color: #fcfcfc;
        background-color: lighten($color: $black, $amount: 10%);
    }
}
.icon-in-circle {
    @include size(16px);
    margin: auto;
    background-position: center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.icon-mail-32x {
    background-image: url('/imgs/icons/profile-sidebar/icon-email-x32.png');
}
.icon-archive-32x {
    background-image: url('/imgs/icons/profile-sidebar/icon-archive-x32.png');
}
.icon-unarchive-32x {
    background-image: url('/imgs/icons/profile-sidebar/icon-unarchive-x32.png');
}
.icon-trashbin-32x {
    background-image: url('/imgs/icons/profile-sidebar/icon-trashbin-x32.png');
}
.icon-download-32x {
    background-image: url('/imgs/icons/profile-sidebar/icon-download-x32.png');
}
.icon-view-32x {
    background-image: url('/imgs/icons/profile-sidebar/icon-view-x32.png');
}
.icon-share-32x {
    background-image: url('/imgs/icons/profile-sidebar/icon-share-x32.png');
}
.icon-print-32x {
    background-image: url('/imgs/icons/profile-sidebar/icon-print-x32.png');
}
.icon-edit-32x {
    background-image: url('/imgs/icons/profile-sidebar/icon-edit-x32.png');
}
.icon-bookmark-32x {
    background-image: url('/imgs/icons/profile-sidebar/icon-bookmark-empty-x32.png');
}
.icon-cv-32x {
    background-image: url('/imgs/icons/profile-sidebar/icon-cv-x32.png');
}
.icon-users-32x {
    background-image: url('/imgs/icons/profile-sidebar/icon-users-x32.png');
}
.icon-meeting-32x {
    background-image: url('/imgs/icons/users.png');
    width: 15px;
    height: 15px;
    margin: 8px;
}
.icon-bookmark-checked-32x {
    background-image: url('/imgs/icons/profile-sidebar/icon-bookmark-full-x32.png');
}
.icon-options-32x {
    background-image: url('/imgs/icons/profile-sidebar/icon-3-dots-x32.png');
}
.icon-half-opacity {
    opacity: 0.5;
}

.icon--help {
    @include size(25px);
    min-width: 25px;
    background-image: url('/imgs/icons/icon-help@2x.png');
    cursor: pointer;
    transition: all .25s ease-in-out;
    &:hover {
        background-image: url('/imgs/icons/icon-help-dark@2x.png');
    }
}

.icon--help-dark {
    @include size(25px);
    background-image: url('/imgs/icons/icon-help-dark@2x.png');
}

.icon--help-small {
    @include size(15px);
    background-image: url('/imgs/icons/icon-help@2x.png');
}
.icon--help-dark-small {
    @include size(15px);
    background-image: url('/imgs/icons/icon-help-dark@2x.png');
}

.icon--help-darken {
    @include size(35px);
    background-image: url('/imgs/icons/icon-help-darken@2x.png');
}

.icon--tag-remove {
    @include size(32px);
    background: url('/imgs/icons/icon-date-close@2x.png') center / 100% 100% no-repeat;
    cursor: pointer;
    &:hover {
        opacity: .5;
    }
}

.icon--tag-add {
    @include size(32px);
    background: url('/imgs/icons/icon-add@2x.png') center / 100% 100% no-repeat;
    cursor: pointer;
    &:hover {
        opacity: .5;
    }
}

.icon--add {
    @include size(32px);
    background: url('/imgs/icons/icon-add@2x.png') center / 100% 100% no-repeat;
    cursor: pointer;
    &:hover {
        opacity: .5;
    }
}

.icon--add-big {
  @include size(38px);
  background: url('/imgs/icons/icon-add-big@2x.png') center / 100% 100% no-repeat;
}

.icon--sub-big {
    @include size(38px);
    background: url('/imgs/icons/icon-sub-big@2x.png') center / 100% 100% no-repeat;
}

.icon--settings {
    @include size(22px);
    background: url('/imgs/icons/icon-settings@2x.png') center / 100% 100% no-repeat;
}

.icon--close {
    @include size(20px);
    background: url('/imgs/icons/icon-close@2x.png') center / 100% 100% no-repeat;
}

.icon--date-close {
    @include size(22px);
    background: url('/imgs/icons/icon-date-close@2x.png') center / 100% 100% no-repeat;
}

.icon--form {
    @include size(22px 22px);
    background: url('/imgs/icons/icon-form@2x.png') center / 100% 100% no-repeat;
}

.icon--jobboards {
    @include size(22px 22px);
    background: url('/imgs/icons/icon-megaphone@2x.png') center / 100% 100% no-repeat;
}

.icon--link {
    @include size(20px 20px);
    background: url('/imgs/icons/icon-link-url-simple@2x.png') center / 100% 100% no-repeat;
}

.icon--career {
    @include size(22px 22px);
    background: url('/imgs/icons/icon-career@2x.png') center / 100% 100% no-repeat;
}

.icon--qr-code {
    @include size(22px 22px);
    background: url('/imgs/icons/icon-qr-code-bis@2x.png') center / 100% 100% no-repeat;
}

.icon--email {
    @include size(23px 20px);
    background: url('/imgs/icons/icon-email-bis@2x.png') center / 100% 100% no-repeat;
}

.icon--marketing {
    @include size(23px 20px);
    background: url('/imgs/icons/icon-marketing.png') center / 100% 100% no-repeat;
}

.icon--email-circle {
    @include icon-hover-circle(url('/imgs/icons/icon-email-circle-trans@2x.png'));
}

.icon--email-mark-viewed {
    @include icon-hover-circle(url('/imgs/icons/icon-email-mark-viewed@2x.png'));
}

.icon--email-mark-unviewed {
    @include icon-hover-circle(url('/imgs/icons/icon-email-mark-unviewed@2x.png'));
}

.icon--email-reinvite {
    @include icon-hover-circle(url('/imgs/icons/icon-reinvite@2x.png'));
}

.icon--archive-circle {
    @include icon-hover-circle(url('/imgs/icons/icon-archive-circle.png'));
}

.icon--unarchive-circle {
    @include icon-hover-circle(url('/imgs/icons/icon-unarchive-circle.png'));
}

.icon--trashbin-circle {
    @include icon-hover-circle(url('/imgs/icons/icon-trashbin.png'));
}

.icon--3dots-circle {
    @include icon-hover-circle(url('/imgs/icons/icon-3-dots-circle.png'));
}

.icon--share-circle {
    @include icon-hover-circle(url('/imgs/icons/icon-share-circle.png'));
}

.icon--print-circle {
    @include icon-hover-circle(url('/imgs/icons/icon-print-circle.png'));
}

// white #fff . For other colors see https://stackoverflow.com/questions/22252472/how-to-change-the-color-of-an-svg-element#:~:text=22%20Answers&text=You%20can't%20change%20the,or%20using%20inline.
.icon--white { 
    filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(2384%) hue-rotate(360deg) brightness(116%) contrast(100%);
}


// .icon--bookmark {
//     @include size(23px 16px);
//     background: url('/imgs/icons/icon-email@2x.png') center / 100% 100% no-repeat;
// }

.icon--bookmark {
    @include size(50px 50px);
    background: url('/imgs/icons/icon-bookmark@3x.png') center / 100% 100% no-repeat;
    cursor: pointer;
    &:hover {
        background: url('/imgs/icons/icon-bookmark-circle@3x.png') center / 100% 100% no-repeat;
    }
}

.icon--bookmark--checked {
    @include size(50px 50px);
    background: url('/imgs/icons/icon-bookmark-checked@3x.png') center / 100% 100% no-repeat;
    cursor: pointer;
    &:hover {
        background: url('/imgs/icons/icon-bookmark-checked-circle@3x.png') center / 100% 100% no-repeat;
    }
}

.icon--search {
    @include size(18px);
    background: url('/imgs/icons/icon-search@2x.png') center / 100% 100% no-repeat;
}

.icon--search-big {
    @include size(22px);
    background: url('/imgs/icons/icon-search-big@2x.png') center / 100% 100% no-repeat;
}

.icon--star {
    @include size(18px);
    cursor: pointer;
    background: url('/imgs/icons/icon-star@2x.png') left top / 18px 18px no-repeat;
}

.icon--star-gold {
    @include size(18px);
    cursor: pointer;
    background: url('/imgs/icons/icon-star-gold@2x.png') left top / 18px 18px no-repeat;
}

.icon--star-small {
    @include size(13px);
    cursor: pointer;
    background: url('/imgs/icons/icon-star@2x.png') left top / 13px 13px no-repeat;
}

.icon--star--filled {
    @include size(18px);
    background: url('/imgs/icons/icon-star-filled@2x.png') left top / 18px 18px no-repeat;
}

.icon--star--filled-small {
    @include size(13px);
    background: url('/imgs/icons/icon-star-filled@2x.png') left top / 13px 13px no-repeat;
}

.icon--star-popup {
    @include size(13px);
    cursor: pointer;
    background: url('/imgs/icons/icon-star-popup@2x.png') left top / 13px 13px no-repeat;
}

.icon--star-popup--filled {
    @include size(13px);
    background: url('/imgs/icons/icon-star-popup-filled@2x.png') left top / 13px 13px no-repeat;
}

.icon--star--filled-gold {
    @include size(18px);
    background: url('/imgs/icons/icon-star-popup-filled@2x.png') left top / 18px 18px no-repeat;
}

.icon--star--filled-gold-large {
    @include size(20px);
    background: url('/imgs/icons/icon-star-popup-filled@2x.png') left top / 20px 20px no-repeat;
}

.icon--star-border {
    @include size(20px);
    background: url('/imgs/icons/icon-star-border@2x.png') left top / 20px 20px no-repeat;
    cursor: pointer;
    &:hover {
        background: url('/imgs/icons/icon-star-border-filled@2x.png') left top / 20px 20px no-repeat;
    }
}

.icon--star-border--filled {
    @include size(20px);
    background: url('/imgs/icons/icon-star-border-filled@2x.png') left top / 20px 20px no-repeat;
    &:hover {
      visibility: hidden;
    }
}

.icon--star--disabled {
    pointer-events: none;
}

.icon--delete {
    @include size(26px);
    min-width: 26px;
    background: url('/imgs/icons/icon-delete@2x.png') center / 100% 100% no-repeat;
    &:hover {
        background: url('/imgs/icons/icon-delete-hover@2x.png') center / 100% 100% no-repeat;
    }
}

.icon--play {
    @include size(55px);
    background: url('/imgs/icons/icon-play@2x.png') center / 100% 100% no-repeat;
}

.icon--menu {
    @include size(50px 50px);
    background: url('/imgs/icons/icon-menu-circle-transp@2x.png') center / 100% 100% no-repeat;
    cursor: pointer;
    &:hover {
        background: url('/imgs/icons/icon-menu-circle@2x.png') center / 100% 100% no-repeat;
    }
}

.icon--menu--black {
    @include size(7px 25px);
    background: url('/imgs/icons/icon-menu-black@2x.png') center / 100% 100% no-repeat;
    cursor: pointer;
    &:hover {
        background: url('/imgs/icons/icon-menu@2x.png') center / 100% 100% no-repeat;
    }
}

.icon--tests {
    @include size(93px 122px);
    background: url('/imgs/icons/icon-tests@2x.png') center / 100% 100% no-repeat;
}

.icon--download {
    @include size(22px 24px);
    background: url('/imgs/icons/icon-download@2x.png') center / 100% 100% no-repeat;
}

.icon--warning {
    @include size(57px 52px);
    background: url('/imgs/icons/icon-warning@2x.png') center / 100% 100% no-repeat;
}

.icon--dropdown {
    @include size(10px 5px);
    background: url('/imgs/icons/icon-dropdown@2x.png') center / 100% 100% no-repeat;
}

.icon--email-placeholder {
    @include size(154px 160px);
    background: url('/imgs/icons/icon-email-placeholder@2x.png') center / 100% 100% no-repeat;
}

.icon--powered-by {
//   @include size(96px 37px);
  width: 100px;
  background: url('/imgs/icons/logo_beehire_grey@2x.png') center / 100% 100% no-repeat;
  -webkit-print-color-adjust: exact;
}

.icon--upload {
    @include size(60px 40px);
    background: url('/imgs/icons/icon-upload@2x.png') center / 100% 100% no-repeat;
}

.icon--confirmation {
    @include size(34px);
    background: url('/imgs/icons/icon-confirmation@2x.png') center / 100% 100% no-repeat;
}

.icon--3-dots {
    @include size(6px 21px);
    background: url('/imgs/icons/icon-3-dots-grey@2x.png') center / 100% 100% no-repeat;
    // &:hover {
    //     background: url('/imgs/icons/icon-3-dots-white@2x.png') center / 100% 100% no-repeat;
    // }
}

.icon--3-dots-white {
    @include size(6px 21px);
    background: url('/imgs/icons/icon-3-dots-white@2x.png') center / 100% 100% no-repeat;
    // &:hover {
    //     background: url('/imgs/icons/icon-3-dots-white@2x.png') center / 100% 100% no-repeat;
    // }
}

.icon--video-question-black {
    @include size(22px 19px);
    min-width: 22px;
    background: url('/imgs/icons/icon-video-question-black@2x.png') center / contain no-repeat;
}

.icon--move {
    @include size(17px);
    min-width: 17px;
    background: url('/imgs/icons/icon-move@2x.png') center / contain no-repeat;
}

.icon--writing {
    @include size(19px);
    background: url('/imgs/icons/icon-writing@2x.png') center / contain no-repeat;
}

.fa--inline {
    margin-left: 10px;
}
.fa--btn {
    margin-right: 20px;
}

.fa--white {
    color: white;
    margin-left: 5px;
    margin-top: 5px;
}

.fa--darkblue {
    color: darkblue;
}

.fa--green {
    color: green;
}

.fa--darkred {
    color: darkred;
}

.fa--grey {
    color: grey;
}