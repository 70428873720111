.mobile-nav-overlay {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

  .mobile-nav-overlay--visible {
      display: block;
      background-color: #222;
      z-index: 9999;
  }

  .mobile-nav-overlay__close {
      @include retina-image('/imgs/site/icon_close', 25px, 'png');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 25px;
      float: right;
      cursor: pointer;
      margin: 30px 30px;
      @include size(25px);
      &:hover {
          cursor: pointer;
      }
  }

  .mobile-nav-overlay__nav {
      // border: 1px solid red;
      text-align: center;
      display: inline-block;
      width: 100%;

      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      li {
          // border: 1px solid green;
          text-align: center;
          padding: 18px 0;
      }

      a {
          font-size: 2em;
          color: $white;
          font-family: $font-family-main-book;
          font-size: $font-size-larger;
          display: inline-block;
          // border: 1px solid yellow;
      }
  }