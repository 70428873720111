/*
---
name: Input field variants
category: forms/variants
tag:
  - Forms
---

```jade
label.label.label--input Input field
input.input-field(placeholder='Input field')

label.label.label--input Checkboxes
.input-field--checkbox-container
    input.input-field.input-field--checkbox(type='checkbox', name='checkbox 1', value='checkbox 1')
    span.input-field__title.input-field__title--main Checkbox 1

.input-field--checkbox-container
    input.input-field.input-field--checkbox(type='checkbox', name='checkbox 2', value='checkbox 2')
    span.input-field__title Checkbox 2

.input-field--checkbox-container
    input.input-field.input-field--checkbox(type='checkbox', name='checkbox 3', value='checkbox 3')
    span.input-field__title Checkbox 3

label.label.label--input Input field number
input.input-field(type='number', pattern='[0-9.]*', placeholder='no limit')

label.label.label--input Input field minutes
input.input-field.input-field--minutes(type='number', pattern="\d{1,5}", value='3', maxlength='2', min='1', max='15')

label.label.label--input Input field search
input.input-field.input-field--search(placeholder='Input field search')

label.label.label--input Input field email
input.input-field.input-field--email(type='email', placeholder='Input field email')

label.label.label--input Input field textarea
textarea.input-field.input-field--textarea(placeholder='Textarea..')
```
*/

.input-field--checkbox-container {
    display: inline-flex;
    margin-bottom: 25px;
}

    .checkbox-container--bordered {
        border: 2px solid #e2e5e8;
        border-radius: 3px;
        padding: 20px 30px;
        justify-content: space-between;
        margin-bottom: 7px;
        transition: all .25 ease-in-out;

        .input-field--checkbox {
            margin-right: 30px;
        }

        .input-field__title--main {
            font-family: $font-family-main-bold;
        }

        .input-field {
            font-size: $font-size-small;
            &::-webkit-input-placeholder {
                color: #999;
            }
        }

        .box__input-field {
            margin-bottom: 0;
        }

    }

    .checkbox-container--disabled {
        //
        // .input-field--checkbox {
        //     //background-color: #f0f0f0;
        // }
        //
        // .input-field--checkbox-secondary {
        //     background-color: #f0f0f0;
        //
        //     &:checked {
        //         background-image: url('/imgs/icons/icon-checked-secondary-disabled@2x.png');
        //     }
        // }

        opacity: .5;
    }

.input-field {
    display: inline-block;
    width: 100%;
    border: none;
    border-radius: 3px;
    background-color: $input-field-grey;
    min-height: 60px;
    padding-left: 35px;
    font-family: $font-family-main-book;
    font-size: $font-size-default;
    margin-bottom: 1rem;

    &:disabled {
        color: $grey-dark;
    }
}

    .input-field__inner-wrapper {
        display: flex;
        align-items: center;
    }

        .input-field__inner-wrapper--indent {
            margin-left: 50px;
        }

    .input-field--select {
        appearance: none;
        background: $input-field-grey url('/imgs/icons/icon-profile-arrow@3x.png') 90% center / 14px 8px no-repeat;
        cursor: pointer;
        &::-webkit-calendar-picker-indicator {
            opacity: 0;
        }
        display: flex;
        align-self: normal;
    }

    .input-field--autocomplete {
        appearance: none;
        &::-webkit-calendar-picker-indicator {
            opacity: 0;
        }
    }

    .input-field--select-transparent {
        appearance: none;
        background: transparent url('/imgs/icons/icon-arrow-bottom-grey@2x.png') 80% center / 12px 7px no-repeat;
        cursor: pointer;
        &:focus, &:hover, &:active {
            outline: none;
        }
    }

    .input-field.input-field--multiselect {
        padding-left: 0px;
        background: #fff url(/imgs/icons/icon-profile-arrow@3x.png) 90%/14px 8px no-repeat;
        .multiselect-parent {
            .dropdown-toggle {
                padding-left: 35px;
                text-align: left;
                display: inline-block;
                padding-top: 20px;
                padding-bottom: 20px;
            }
        }
    }

    .input-field--no-margin {
        margin: 0;
        padding: 0;
        min-height: 28px;
        width: 400px;
        font-weight: bold;
    }

    .input-field--checkbox {
        @include size(26px);
        min-height: 26px;
        min-width: 26px;
        border: solid #d6e1e1 3px;
        background-color: white;

        display: inline-flex;
        -webkit-appearance: none;

        margin-right: 20px;
        line-height: 28px;
        margin-bottom: 0;

        &:checked {
            background-image: url('/imgs/icons/icon-checked-bold@2x.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 12px 9px;
        }

        &:focus {
            outline: none;
        }

        &:hover {
            cursor: pointer;
        }

        &:disabled {
            background-color: rgba(0, 0, 0, 0.1);
            cursor: not-allowed;
        }
    }

    .input-field--checkbox-grey {
        background-color: $input-field-grey;
    }

    .input-field--checkbox-secondary {
        @include size(20px);
        min-height: 20px;
        background-color: #d6e1e1;

        display: inline-flex;
        -webkit-appearance: none;

        margin-right: 20px;
        line-height: 28px;
        margin-bottom: 0;
        // &:active, &:checked:active {
        //     background-color: $black;
        // }

        &:checked {
            background-image: url('/imgs/icons/icon-checked-secondary@2x.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 20px 20px;
        }

        &:focus {
            outline: none;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .input-field--radio {
        @include size(28px);
        min-height: 28px;
        background-color: #d6e1e1;

        display: inline-flex;
        -webkit-appearance: none;

        margin-right: 20px;
        line-height: 28px;
        margin-bottom: 0;
        // &:active, &:checked:active {
        //     background-color: $black;
        // }

        &:checked {
          background-image: url('/imgs/icons/icon-checked@2x.png');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 12px 9px;
        }

        &:focus {
          outline: none;
        }

        &:hover {
          cursor: pointer;
        }
    }

    .input-field--plus-btn {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .input-field--radio-answer {
        @include size(24px);
        min-height: 24px;
        padding: 0 !important;
        border: 3px solid $black;
        border-radius: 100px;

        display: inline-flex;
        -webkit-appearance: none;

        margin: 5px 10px 5px 0 !important;
        // &:active, &:checked:active {
        //     background-color: $black;
        // }

        &:checked {
          background-image: url('/imgs/icons/icon-checked-round@2x.png');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 10px;
        }

        &:focus {
          outline: none;
        }

        &:hover {
          cursor: pointer;
        }
    }

    .input-field--checkbox-answer {
        @extend .input-field--radio-answer;
        border-radius: 0;
        &:checked {
            background-image: url('/imgs/icons/icon-checked-bold@2x.png');
          }
    }

    .input-field__title {
        display: inline-flex;
        margin-right: 80px;
        line-height: 28px;
        font-family: $font-family-main-book;
        font-size: $font-size-small;
        color: #212223;
    }

        .input-field__title--main {
            font-family: $font-family-main-medium;
        }

        .input-field__title--inverted {
          color: white;
        }

        .input-field__title--secondary {
            margin-right: 10px;
        }

    .input-field[type="number"] {
        &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            display: block;
            right: 12px;
            width: 15px;
            background: url('/imgs/icons/icon-input-arrows@2x.png') center center / 10px 20px no-repeat;
            cursor: pointer;
        }
    }


        .input-field--minutes {
            background-image: url('/imgs/icons/icon-input-min@2x.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 26px 10px;
        }

        .input-field--textarea {
            padding: 15px;
            min-height: 80px;
            max-width: 100%;
            font-size: $font-size-small;
            resize: none;
        }

        .input-field--search {
            @include input-icon(url('/imgs/icons/icon-search@2x.png'));
        }

        .input-field--search-white {
            @include input-icon(url('/imgs/icons/icon-search-white@2x.png'));
        }

        .input-field--localization {
            @include input-icon(url('/imgs/icons/icon-localization.png'));
        }

        .input-field--search-transparent {
            background-image: url('/imgs/icons/icon-search-white@2x.png');
            background-size: 18px;
            background-repeat: no-repeat;
            background-position: left center;
            padding-left: 45px;
            background-color: transparent;
            color: $white;
            font-family: 'Lato Light';
            font-size: $font-size-small;
            // border: 1px solid red;
            // min-height: 35px;
            padding-left: 30px;
            &::-webkit-input-placeholder {
                color: $white;
            }
        }

        .input-field--email {
            background-image: url('/imgs/icons/icon-email@2x.png');
            background-size: 23px 16px;
            background-repeat: no-repeat;
            background-position: 25px center;
            padding-left: 73px !important;
        }

        .input-field--location {
            @include input-icon(url('/imgs/icons/icon-localization.png'));
            background-size: 18px 16px;
            background-repeat: no-repeat;
            background-position: 25px center;
            padding-left: 73px !important;
        }

        .input-field--list {
            background: url('/imgs/icons/icon-list@2x.png') 25px center / 22px no-repeat, url('/imgs/icons/icon-arrow-bottom-grey@2x.png') 95% center / 14px 7px no-repeat;
            padding-left: 73px !important;
        }

        .tag-field--meeting {
            background: url('/imgs/icons/users.png') 25px center / 22px no-repeat;
            // padding-left: 60px !important;
        }
        
        .sidebar-profile__meeting {
            @include prepend-little-icon('/imgs/icons/users.png');
        }

        .input-field--list-dropdown {
            background: url('/imgs/icons/icon-arrow-bottom-grey@2x.png') 95% center / 14px 7px no-repeat;
            padding-left: 20px !important;
        }

        .input-field--datepicker {
            background-image: url('/imgs/icons/icon-datepicker@2x.png');
            background-size: 19px 18px;
            background-repeat: no-repeat;
            background-position: 25px center;
            text-align: center;
        }

        .input-field--time {
            background-image: url('/imgs/icons/icon-time@2x.png');
            background-size: 22px;
            background-repeat: no-repeat;
            background-position: 25px center;
            text-align: center;
            padding-left: 73px;
        }

        .input-field--link {
            background-image:url('/imgs/icons/icon-link@2x.png');
            background-size: 18px 22px;
            background-repeat: no-repeat;
            background-position: 30px center;
            padding-left: 70px !important;
            padding-right: 30px;
            text-overflow: ellipsis;
        }
        

        .input-field--percent {
            width: 100px;
        }

        input.input--error, textarea.input--error, .input-field:invalid, .input-field:out-of-range {
            border: 1px solid red !important;
        }
        .input--error, .input-field:invalid, .input-field:out-of-range {
            color: red !important;
            &::placeholder {
                color: #ff7777 !important;
            }
            // for the placeholders in ng-quill-editor 
            .ql-editor::before {
                color: #ff7777 !important;
            }
        }

        .input-field--multi {
            width: 100%;
            min-height: 36px;
            font-size: $font-size-small;
            background: url('/imgs/icons/icon-input-multi@2x.png') left center / 1rem no-repeat;
            padding-left: 2rem;
            padding-top: 4px;
            margin: 0;
        }

            .input-field--multi-inactive {
                background: url('/imgs/icons/icon-input-grey@2x.png') left center / 1rem no-repeat;
            }

/*
---
name: Input field sizes
category: forms/sizes
tag:
  - Forms
---

```jade
input.input-field.input-field--s(placeholder='Input field S')
input.input-field(placeholder='Input field')
input.input-field.input-field--xl(placeholder='Input field XL')
input.input-field.input-field--xxl(placeholder='Input field XXL')
input.input-field.input-field--xxxl(placeholder='Input field XXXL')

```
*/

        .input-field--narrow {
            max-width: 90px;
            padding-left: 20px;
        }

        .input-field--s {
            min-height: 46px;
            font-size: $font-size-small;
            padding-left: 20px;
        }

        .input-field--xl {
            min-height: 85px;
        }

        .input-field--xxl {
            min-height: 140px;
        }

        .input-field--xxxl {
            min-height: 180px;
        }

        .input-field--xxxxl {
            min-height: 220px;
        }

        .input-field--4xl {
            min-height: 220px;
            .ql-container {
                height: 137px;
            }
        }


/*
---
name: Input field colors
category: forms/colors
tag:
  - Forms
---

```jade
// Default color
input.input-field(placeholder='Input field')

// White background
input.input-field.input-field--white(placeholder='Input field')

// White background with border
input.input-field.input-field--white-border(placeholder='Input field')
```
*/

        .input-field--white {
            background-color: #fff;
            color: $black;
            &::-webkit-input-placeholder {
                color: $grey-darker;
            }
        }

        .input-field--white-border {
            background-color: #fff;
            border: solid 1px rgba(0, 0, 0, .09);
            color: $black;
            &::-webkit-input-placeholder {
                color: $grey-darker;
            }
        }
                
        .input-field--border {
            background-color: #fff !important;
            border: solid 1px rgba(0, 0, 0, .09);
        }
        
        .input-field--grey {
            background-color: #EAEAEA;
        }

        .input-field--transparent {
            background-color: transparent;
        }

        .input-field--white-transparent {
            background-color: rgba(255,255,255,.8);
        }

        .double-input--container {
            width: 100%;
            display: flex;
            align-items: flex-end;
            div:nth-of-type(1) {
                margin-right: 10px;    
                width: 100%;
            }
            div:nth-of-type(2) {
                margin-left: 10px;    
                width: 100%;
            }
        }

        .max-size--container--xxl {
            max-height: 300px;
            display: flex;
            width: 100%;
        }

        .input-field--disable {
            pointer-events: none;
            color: $grey-darker;
        }

        .input-field--no-border-radius-right {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }