.dashboard-branding {
  width: 100%;
  display: flex;

  &.row, .row {
    margin-left: 0px;
    margin-right: 0px;
  }

  .branding-dashboard--title {
    text-align: center;
    font-weight: 700;
  }

  .dashboard-branding--sidebar {
    padding: 50px;
    height: 100%;
    
    .dashboard-branding--sidebar-item {
      width: 100%;
      font-family: $font-gotham-medium;
      color: $steel-grey;

      &.active, &:hover {
        color: $brand-color-1;
      }
    }
    ul.dashboard-branding--sidebar-item {
      & + ul.dashboard-branding--sidebar-item {
        margin-top: 30px;
        padding-top: 30px;
      }
      & + ul.dashboard-branding--sidebar-item.multipage-edit--sidebar-item-divisor {
        border-top: 1px solid #eaeef1;
        text-transform: uppercase;
      }

      & > span:first-child {
        cursor: pointer;
        display: inline-block;
        width: 100%;
        &.active {
          color: $brand-color-1;
        }
      }

      li {
        color: $warm-grey;
        margin-left: 40px;
        cursor: pointer;
        margin-top: 30px;
        text-transform: none;
      }
    }

    .dashboard-branding--new-brand {
      cursor: pointer;
      width: 100%;
      border-top: 1px solid #eaeef1;
      margin-top: 30px;
      padding-top: 30px;
    }
  }

  .dashboard-branding--content {
    background-color: $white-three;
    height: 100%;
  }

  .branding-dashboard--form {
    align-items: center;
  }
  
  .branding-dashboard--upload {
    gap: 5%;
    flex-wrap: wrap;
    .box__input-upload {
      border: 2px dashed;
    }
    .branding-dashboard--upload--thumb-small {
      max-width: 130px;
      max-height: 60px;
    }
    .branding-dashboard--upload--thumb-large {
      max-width: 170px;
      max-height: 190px;
    }
    .branding-dashboard--upload-item {
      width: 47.5%;
      min-width: 300px;
    }
    
    span {
      color: $warm-grey-two;
      &.label--sub {
        font-family: $font-gotham-book;
      }
    }
  }

  .branding-dashboard__warning {
    margin-top: 30px;
    margin-bottom: 30px;
    .link-button {
      font-size: $font-size-default;
    }
  }

  .branding-dashboard--buttons {
    margin-top: 30px;
    text-align: center;

    .btn {
      margin-top: 30px;
      max-width: 200px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .branding-dashboard--career-link {
    gap: 20px;

  }
  
  .branding-dashboard--campaign-selection {
    width: 100%;

    .input-field--checkbox.half-checked {
      background-image: url('/imgs/icons/icon-checked-half-bold@3x.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 12px 9px;
    }
    .input-field--checkbox.checked {
      background-image: url('/imgs/icons/icon-checked-bold@2x.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 12px 9px;
    }
    
    .branding-dashboard--campaign-selection-filter {
      font-size: $font-size-small;
      height: 45px;
      margin-bottom: 10px;
    }

    .branding-dashboard--campaign-selection-list {
      background-color: white;
      padding: 25px 50px;
      max-height: 400px;
      width: 100%;
      overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 15px;
      
      .input-field--checkbox-container {
        margin-bottom: unset;

        &.checkbox--selectAll {
          margin-bottom: 10px;
        }

        &>.input-field__title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: 0px;
          display: inline-block;
        }
      }
    }
  }

  .branding-dashboard--filters {
    padding-left: 35px;
    padding-top: 20px;
  }

  .branding-dashboard--gtag {
    padding-top: 20px;
    padding-right: 30px;
  }

  .branding-dashboard--header-banner {
    padding-left: 30px;
    padding-right: 30px;

    .label--thin {
      width: 200px;
      flex-shrink: 0;
    }
  }

  .branding-dashboard--terms-and-conditions {
    padding: 15px 30px;
  }

  .branding-dashboard--disabled {
    pointer-events: none;
    background-color: #e0e0e0;
    color: $black;
  }

  // color picker customizations
  .sp-replacer {
    width: 100px;
    height: 40px;
    background-color: white;
    padding: 9px;
    
    .sp-preview {
      width: 56px;
    }
    .sp-dd {
      font-size: 17px;
    }
  }

  .branding-dashboard--sections {
    width: 100%;
    max-width: 625px;
    flex-direction: column;
    gap: 20px;
    .sections--add {
      max-width: 220px;
      margin-left: auto;
      margin-top: -20px;
    }
    table {
      width: 100%;
      tr {
        height: 60px;
        padding: 10px;
        background-color: white;
      }
    }
  }
}
