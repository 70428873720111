.hover-underline {
    &:hover, &:hover span {
        text-decoration: underline;
    }
}

a.link-button {
    color: $brand-blue;
    text-decoration: underline;
    transition: all 0.3s ease-in-out;
    font-size: $font-size-small;
    &:hover {
        color: #999;
    }
}

.sn__links {

}

.sn-link {
    display: inline-flex;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: $brand-blue;

    &:not(:first-of-type) {
        margin-left: 10px;
    }

    .sn-link__icon {
        @include size(20px);
        margin: auto;
        background-position: center center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .sn-link__icon-linkedin {
        background-image: url('/imgs/icons/sn-linkedin-white-32x.png');
    }
    .sn-link__icon-facebook {
        background-image: url('/imgs/icons/sn-facebook-white-32x.png');
    }
    .sn-link__icon-twitter {
        background-image: url('/imgs/icons/sn-twitter-white-32x.png');
    }
    .sn-link__icon-instagram {
        background-image: url('/imgs/icons/sn-instagram-white-32x.png');
    }
    .sn-link__icon-youtube {
        background-image: url('/imgs/icons/sn-youtube-white.svg');
        width: 24px
    }
    .sn-link__icon-tiktok {
        background-image: url('/imgs/icons/sn-tiktok-white.png');
    }
}