/*
---
name: Main Button
category: buttons/variants
mixin: btnMain
tag:
  - Buttons
---

```code
+btnMain(text, modifier)

modifier = html class
```
*/

.btn-side-by-side-container {
    display: flex;
    .btn + .btn {
        margin-left: 10px;
    }
}

.btn {
    display: inline-block;
    padding: 1rem 2rem;
    background-color: $brand-color-3;
    background-repeat: no-repeat;
    color: $white;
    font-family: $font-family-main-medium;
    font-size: $font-size-small;
    transition: all 0.3s ease-in-out;
    border-radius: $border-radius-default;
    text-align: center;
    // line-height: 0.9;

    cursor: pointer;
    &:hover {
        background-color: rgba(39, 40, 41, .9);
    }

    // on smaller screens text centering fix
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled, &.disabled, &[disabled] {
        cursor: default;
        pointer-events: none;
        background-color: $grey-darker;
        &:hover {
            cursor: default;
            background-color: $grey-darker;
        }
    }
}

.disabled {
    cursor: default;
    pointer-events: none;
}


  .btn--plus {
    background-image: url('/imgs/icons/icon-add-white@2x.png');
    background-position: center;
    background-size: 27px;
    background-repeat: no-repeat;
  }

  .btn--radius-right {
      border-radius: 0 3px 3px 0;
  }

  .btn--where-info {
      width: calc(100% - 55px);
      align-self: flex-start;
      margin-top: 5px;
  }

  .btn--with-help {
      margin-left: 32px;
      margin-right: 16px;

      + .tooltips {
          margin-left: 16px;
      }
  }

.btn--with-help {
  + .tooltips {
    margin-left: 16px;
}
}

/*
---
name: Thin Button
category: buttons/variants
mixin: btnMainThin
tag:
  - Buttons
---

```code
+btnMain('Thin Button', 'btn--thin')
```
*/

    .btn--thin {
        font-family: $font-family-main-book;
    }

/*
---
name: Full width Button
category: buttons/sizes
mixin: btnMainFullWidth
tag:
  - Buttons
---

```code
+btnMain('Full Width', 'btn--full-width')
```
*/

    .btn--full-width {
        width: 100%;
        padding: 1rem 0;
    }

    .btn--icon {
        background-color: $grey-light;
        border-color: $brand-color-2;
        color: #65481b;
        letter-spacing: 2px;
        padding: 20px 20px 20px 80px;
        background-repeat: no-repeat;
        margin: 0;
        @media (min-width: $screen-md-min) {
            padding: 20px 50px 20px 120px;
            font-size: $font-size-large;
        }
    }

/*
---
name: XL Button
category: buttons/sizes
mixin: btnMainXL
tag:
  - Buttons
---

```code
+btnMain('XL Button', 'btn--xl')
```
*/

    .btn--xl {
        padding: 1.28rem 5rem;
        font-size: $font-size-default;
    }

/*
---
name: S Button
category: buttons/sizes
mixin: btnMainS
tag:
  - Buttons
---

```code
// S
+btnMain('S Button', 'btn--s')
```
*/

    .btn--s {
        padding: 0.8rem 1.2rem 0.7rem;
        font-size: $font-size-small;
    }


    .btn--xs {
        padding: 0.8rem 1.2rem 0.7rem;
        height: 30px;
        min-width: fit-content;
        width: 140px;
        font-size: $font-size-small;
    }
    .btn--xs-wide {
        padding: 0.8rem 1.2rem 0.7rem;
        height: 30px;
        width: 300px;
        font-size: $font-size-small;
    }

    .btn--s-long {
        padding: 0.8rem 2rem 0.7rem;
        font-family: $font-family-main-bold !important;
        font-size: $font-size-smaller;
    }

/*
---
name: Dark Button
category: buttons/colors
mixin: btnMainDark
tag:
  - Buttons
---

```code
+btnMain('Dark Button', 'btn--dark')
```
*/

    .btn--dark {
        background-color: $black;

        &:hover {
            background-color: $brand-color-3;
        }
    }

/*
---
name: Cerulean Button
category: buttons/colors
mixin: btnMainDark
tag:
  - Buttons
---

```code
+btnMain('Cerulean Button', 'btn--cerulean')
```
*/

.btn--cerulean {
  background-color: $cerulean;

  &:hover {
    background-color: $brand-color-2;
  }
}


/*
---
name: Warning Button
category: buttons/colors
mixin: btnMainDark
tag:
  - Buttons
---

```code
+btnMain('Dark Button', 'btn--warning')
```
*/

    .btn--warning {
        background-color: $warning-color;

        &:hover {
            background-color: $warning-color-darker;
        }
    }

/*
---
name: Grey Button
category: buttons/colors
mixin: btnMainDark
tag:
  - Buttons
---

```code
+btnMain('Dark Button', 'btn--grey')
```
*/

    .btn--upgrade {
        background-color: $upgrade-color;
        padding: 1rem;
        color:white;
    }

    .btn--upload {
        font-family: $font-family-main-book;
        padding: 0.5rem 0.9rem;
        color: white;
        background: #A4A4A4;
    }

    .btn--grey {
        background-color: $grey-dark;
        color: white;

        &:hover {
            background-color: $black;
            color: $white;
        }
    }
    .btn--green {
        background-color: green;
        color: white;
    }
    .btn--darkred {
        background-color: darkred;
        color: white;
    }


/*
---
name: Transparent Button
category: buttons/colors
mixin: btnMainTransparent
tag:
  - Buttons
---

```code
+btnMain('Transparent Button', 'btn--transparent')
```
*/

    .btn--transparent {
        background-color: transparent;
        border: 2px solid $white;

        &:hover {
            background-color: transparent;
            // color: $black;
            // border: 2px solid $black;
            opacity: .6;
        }
    }

    .btn--transparent-black {
        background-color: transparent;
        box-shadow: inset 0px 0px 0px 2px $black;
        color: $black;
        &:hover {
            background-color: transparent;
            opacity: .6;
            cursor: pointer;
        }
    }

/*
---
name: Secondary Button
category: buttons/colors
mixin: btnMainSecondary
tag:
  - Buttons
---

```code
+btnMain('Secondary Button', 'btn--secondary')
```
*/

    .btn--primary {
        font-size: $font-size-default;
        background-color: $brand-color-1;
    }
    .btn--secondary {
        background-color: $brand-color-3;
    }

    .btn--tertiary {
      background-color: $warning-color;
    }

/*
---
name: Inactive Button
category: buttons/colors
mixin: btnMainInactive
tag:
  - Buttons
---

```code
+btnMain('Inactive Button', 'btn--inactive')
```
*/

    .btn--inactive {
        pointer-events: none;
        background-color: $btn-color-inactive;
    }

    .btn--countdown {
        color: $warning-color-darker;
        font-size: 2.5rem;
    }


/*
---
name: Next Button
category: buttons/icons
mixin: btnMainNext
tag:
  - Buttons
---

```code
+btnMain('Button Button', 'btn--icon-next')
```
*/

    .btn--icon-next {
        background-image: url('/imgs/icons/icon-next.svg');
        background-position: 90% center;
        background-size: 0.8em;
        white-space: nowrap;
    }

    .btn--round {
        @include size(100px);
        border-radius: 50%;
        padding: 0;
        border: solid 1px white;
    }

    .btn--video {
        @include size(100px);
        background-color: rgba(0,0,0,.5);
    }


// /*
// ---
// name: Circular Button
// category: buttons/variants
// mixin: btnCircular
// tag:
//   - Buttons
// ---
//
// ```code
// +btnCircular(icon, modifier)
//
// modifier = html class
// ```
// */
//
// .btn-circular {
//     display: inline-block;
//     padding: 1rem 1.2rem 0.8rem;
//     background-color: $brand-color-1;
//     background-repeat: no-repeat;
//     color: $white;
//     font-family: $font-family-main-book;
//     font-size: $font-size-large;
//     transition: all 0.3s ease-in-out;
//     border-radius: 200px;
//     text-align: center;
//
//     line-height: 1;
//
//     cursor: pointer;
//     &:hover {
//         background-color: rgba(39, 40, 41, .9);
//     }
// }


    .btn--column {
      flex-direction: column;
      span {
        + span {
          margin-top: 0.5rem;
        }
      }
    }

    .btn--bold {
        font-family: $font-family-main-bold;
    }
