.c-modal-campaign-validation-list {
    width: 100%;
    p.c-modal-campaign-validation-paragraph{
        margin-bottom: 10px;
        text-align: left;
    }
    ul {
      list-style: none;
      padding: 0;
      max-height: 120px;
      overflow-y: auto;
      margin-bottom: 10px;
      li {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .c-modal-campaign-validation-icon {
          // To replace with an icon in the future?
          width: 24px;
          height: 24px;
          background-color: #f0f0f0;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          font-size: 14px;
          color: #333;
        }
        .c-modal-campaign-validation-details {
          display: flex;
          flex-direction: row;
          .c-modal-campaign-validation-name {
            font-weight: bold;
            margin-right: 10px;
          }
          .c-modal-campaign-validation-email {
            // Maybe that's not necessary in the future
            color: #777;
          }
        }
      }
    }
  }
  