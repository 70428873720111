/**
 * The dndDraggingSource class will be applied to
 * the source element of a drag operation. It makes
 * sense to hide it to give the user the feeling
 * that he's actually moving it.
 */
.dndDraggingSource {
  display: none !important;
}

/**
 * An element with .dndPlaceholder class will be
 * added to the dnd-list while the user is dragging
 * over it.
 */
.dndPlaceholder {
  background-color: #ddd;
  display: block;
  min-height: 55px;
  border-radius: 3px;
  margin: 6px 0;
}
