.advanced-options {
  border-top: 1px dashed #dbdbdb;
  margin-top: 2rem;
  padding-top: 2rem;
  width: 100%;
}

  .advanced--career-page {
    position: relative; 
    width: fit-content;
  }
