.submission-assessfirst {
    flex: 1;
}

    .assessfirst__progress {
        display: flex;
        align-items: center;
        margin-top: 10px;
    }

    .assessfirst__table-cell__buttons {
        display: flex; 
        align-items: center;
        justify-content: space-evenly;
    }

    .assessfirst__progress {
        display: flex;
        align-items: center;
        margin-top: 10px;
    }