stripe-checkout {
  width: 100%;

  .stripe-checkout {
    .stripe-checkout__loading {
      width: 100%;
      height: 240px;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    .stripe-checkout__enter-coupon {
      text-align: left;
      margin-bottom: 10px;
    }

    .stripe-checkout__coupon {
      text-align: left;
      margin-bottom: 10px;
      .stripe-checkout__coupon-label {
        color: #30313d;
        font-size: 0.93rem;
      }
      .stripe-checkout__coupon-box {
        display: flex;
      }
      .stripe-checkout__coupon-input {
        width: 100%;
        padding: 12px;
        border-radius: 5px;
        border: 1px solid rgb(230, 230, 230);
      }
    }

    .stripe-checkout__total {
      text-align: end;
      font-size: $font-size-base;
      margin-top: 20px;
    }

    .stripe-checkout__total--discount {
      text-decoration: line-through;
    }

    .stripe-terms {
      text-align: left;
      margin-top: 20px;
      .input-field--checkbox {
        margin-right: 0;
      }
    }

    .stripe-checkout__buttons {
      display: flex;
      margin-top: 15px;
      
      a {
        margin-left: 10px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
