.selection-warning {
  padding: 10px;
  width: 100vw;
  text-align: center;
  background-color: #ffffff;
  font-size: $font-size-small;
  border: 1px solid rgba(226,229,232,.4);
  border-radius: 3px;
  margin-bottom: 3px;

  .selection-warning--link-button {
    color: $brand-blue;
    font-family: $font-gotham-bold;
  }
}