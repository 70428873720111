mentio-menu {
    ul {
        background: #282A2D;
        border-radius: 3px;
        margin-top: -200px;
        padding: 0 20px;
        box-shadow: 0 10px 24.5px 0 rgba(0, 0, 0, 0.52);
        li {
            + li {
                border-top: 1px solid #4a4a4a;
            }
            a {
                color: $white;
                font-size: $font-size-small;
                padding: 20px 0;
                line-height: 60px;
            }
        }
    }
}
