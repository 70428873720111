.message-picker {
    display: flex;
    align-items: center;
    margin: 5px 0px 10px 0px;
}

.message-picker-modal {
    display: flex;
    align-items: center;
    margin: 5px 0px 10px 0px;
}

.message-picker__select {
    height: 30px;
    min-width:150px;
    max-width:150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 5px 10px;
    justify-content: center;
    background-color: $input-field-grey;
    border: none;
}

.message-picker-modal__select {
    height: 30px;
    padding: 5px 3px;
    margin-left: 10px;
    justify-content: center;
    background-color: $input-field-grey;
    border: none;
    min-width:100px;
    max-width:100px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.message-picker__select--white {
    background-color: white;
}
