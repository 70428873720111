.candidate-collection-method {
    position: relative;
    width: 650px;
    text-align: left;
    transition: max-height 1s linear, opacity 1s linear;
    
    // Hide content (shown with javascript)
    max-height: 0px;
    opacity: 0;
    padding: 0;
}

    .candidate-collection-method__buttons-container {
        display: flex;
        gap: 10px;
    }

    .candidate-collection__image {
        position: absolute;
        @include size(180px 180px);
        top: 0px;
        left: -220px;
    }

    .candidate-collection__title {
        font-size: $font-size-large;
        margin-bottom: 1rem;
    }

    .candidate-collection__badge {
        margin-top: -8px;
        margin-bottom: 10px;
    }

    .candidate-collection__description {
        margin-bottom: 1rem;
    }

    .candidate-collection__content {
        width: 100%;
        display: inline-block; // to prevent margin collapse inside it
    }

        .multiposting__owner-override {
        }

        .multiposting__status {
        }

            .status__open {
                @include append__arrow-down(12px, $brand-blue);
                color: $brand-blue;
                font-weight: 700;
                text-decoration: underline;
            }

            .status__table_container {
                width: 920px;
                margin-top: 25px;
                margin-left: -160px;
                font-size: $font-size-small;

                // to hide/show the content smoothly
                height: 0;
                transition: height 0.5s ease-in-out;
                overflow: hidden;

                /*
                 * Rounded corners, from https://codepen.io/mlms13/pen/CGgLF
                 */
                $table-grey: $grey-light;
                $table-border: 1px solid $grey;
                $table-border-radius: 3px;

                .status__refresh {
                    i {
                        margin-bottom: 10px;
                    }
                }
            }
            .status__board-name {
            }
            .status__board-status {
            }
            .status__board-exp {
                min-width: 140px;
            }
            .status__board-link {
                min-width: 120px;
                text-align: center;
                text-decoration: underline;
            }

        .iframe__refresh {
            position: absolute;
            transform: translate(-210px, 26px);
            font-size: $font-size-small;
        }

        .multiposting__iframe {
            @include size(1028px 770px);
            margin-top: 50px;
            margin-left: -210px;
            background-color: white;

            iframe {
                border-width: 1px;
                border-style: solid;
                border-color: $grey-light;
            }
        }

        .qr-code__image {
            @include size(160px);
        }
        .qr-code__btn {
            width: 200px;
            margin-top: 30px;
            text-align: left;
        }

        .invitation-email__btn {
            width: 200px;
            margin-top: 20px;
            text-align: left;
        }
        

    .candidate-collection-talentplug__buttons-container {
        display: flex;
        justify-content: space-between;
        &>div {
            display: flex;
            gap: 10px;
        }
        tooltip {
            align-self: center;
        }
    }
    .talentplug__errors {
        color: red;
        font-weight: bold;
    }

    .talentplug-status__details {
        margin-top: 20px;
        margin-bottom: 20px;

        .status__refresh {
            text-decoration: underline;
            font-size: $font-size-small;
            margin-bottom: 5px;
            display: inline-block;
        }
    }

    .talentplug__offer {
        .talentplug__offer__status {
            font-weight: bold;
        }

        .talentplug__offer__field, details {
            margin-bottom: 10px;
        }
        .talentplug__offer__field--inline {
            display: flex;
            flex-direction: row;
            gap: 10px;
        }

        details[open] {
            margin-bottom: 0px;
        }
    }

    dialog:-internal-dialog-in-top-layer::backdrop {
        background-color: transparentize(#45494C, .15);
    }
    #talentplugModal {
        padding: 0;
        border: 0;
        background-color: #f5f5f5; // same from talentplug iframe content

        .talentplug__modal-content {
            display: flex;
            flex-direction: column;
        }

        .main-modal__close {
            margin-left: auto;
            padding: 7px;    
        }
        iframe {
            border: 0;
            height: 85vh;
        }
    }

.status__table {
    $table-grey: $grey-light;
    $table-border: 1px solid $grey;
    $table-border-radius: 3px;

    border-collapse: separate;
    border: $table-border;
    border-radius: $table-border-radius;
    width: 100%;
    overflow: hidden;
    font-size: $font-size-small;

    th, td {
        padding: 10px 10px 6px;
    }

    thead {
        font-family: $font-gotham-medium;
        background-color: $table-grey;
        th {
            font-weight: 400;
            border-bottom: $table-border;
        }
    }

    tbody {
        tr {
            td {
                border-bottom: $table-border;
            }
            &:last-child {
                td {
                    border-bottom: unset;
                }
            }
        }
        tr:nth-child(odd) {
            background-color: #fff;
        }
        tr:nth-child(even) {
            background-color: $table-grey;
        }
    }
}