.advanced--add-video {
  position: relative;
  display: flex;
  justify-content: space-between;
}

  .add-video-item {
    flex: 1;
    + .add-video-item {
      margin-left: 1rem;
    }
  }


.add-video {
  height: 155px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: $input-field-grey;
  border-radius: 3px;
  transition: all 0.25s ease-in-out;
  &:hover {
    // border: 1px dashed $brand-color-2;
    opacity: 0.5;
  }
}

  .add-video--question {
    min-width: 240px;
    margin-left: 15px;
  }

    .add-video__record {
      flex: 1;
      align-self: stretch;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      border: 1px dashed $black;
      // padding: 3rem 1rem;
      cursor: pointer;
    }

      .add-video__label {
        font-family: $font-family-main-bold;
        font-size: $font-size-smaller;
        margin: 8px 0 4px;
      }

      .add-video__desc {
        font-size: 0.625rem;
      }


    .add-video__preview {
      position: relative;
      // img {
      //   margin-bottom: -5px;
      // }
    }

      .add-video__preview-overlay {
        @include size(100% 155px);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

        .add-video__preview-duration {
          display: block;
          position: absolute;
          width: 100%;
          bottom: 20px;
          text-align: center;
          color: $white;
        }


        .add-video__remove-video {
          position: absolute;
          top: 6px;
          right: 10px;
          color: $white;
          cursor: pointer;
          text-shadow: 0px 0px 3px black;
        }
