.interview-documents {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
}

    .interview-documents__uploads {
        min-width: 640px;
        margin-top: 30px;
    }

        .interview-documents__item {
            display: flex;
            padding: 26px 40px 26px 20px;
            height: 100px;
            .btn {
                padding: 1rem 1.5rem;
                color: white;
            }

            + .interview-documents__item {
                margin-top: 10px;
            }
        }

            .interview-documents__info {
                display: flex;
                flex: 3;
                flex-direction: column;
                justify-content: space-between;
                .label {
                    padding-left: 20px;
                    &.checked {
                        background: url('/imgs/icons/icon-checked-bold@2x.png') left top / 15px 13px no-repeat;
                    }
                }
            }

              .info--red {
                color: red;
              }
