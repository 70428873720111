.submission-video {

}

    .submission-video__element {
            display: flex;
            justify-content: center;
            flex: 1;
            min-width: 50%;
            max-height: 50%;
            background-color: #282a2d;
        }

        .submission-video__answer-display {
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex: 1;
            background-color: #282a2d;
            color: white;
            padding: 50px 0;
            overflow: hidden;
            word-break: break-word;
        }

            .submission-video__no-video {
                color: white;
            }

            .submission-video__answer-display-inner {
                display: flex;
                flex-direction: column;
                padding: 15px 100px;
                p {
                    color: $white;
                }

                .predefined-answer-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 10px;

                    .answer-text {
                        max-width: 300px;
                        position: relative;
                        &.active {
                            color: $brand-color-2;

                            &::before {
                                content: '\279C';
                                position: absolute;
                                left: -20px;
                                top: 0px;
                            }
                        }
                    }
                    
                    .answer-matching {
                        
                        margin-left: 150px;
                        cursor: not-allowed;

                        .rzslider {
                            width: 100px;
                            .rz-bubble {
                                color: $white;
                            }
                        }
                    }

                }
            }

.submission-questions {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 50%;
    color: $white;

    .rz-bubble {
        color: $white;
    }
}

    .submission-questions__label {
        display: flex;
        justify-content: center;
        .label {
            margin: -20px 0 30px;
            width: 80%;
        }
    }

        .submission-questions__container {
            flex: 1;
        }
