

// @Pixel to REM
// ===========================================
@use "sass:math";

@mixin font-size($size) {
    font-size: $size;
    font-size: math.div($size, 16px) * 1rem;
}

@mixin border-red {
    border: 1px solid red;
}

@mixin border-green {
    border: 1px solid green;
}

@mixin border-blue {
    border: 1px solid blue;
}

@mixin box-shadow($shadow...) {
    box-shadow: $shadow;
}

@mixin nav-divider($color: #e5e5e5) {
    height: 1px;
    margin: ($spacer-y * 0.5) 0;
    overflow: hidden;
    background-color: $color;
}
@mixin hover {
    // TODO: re-enable along with mq4-hover-shim
    //  @if $enable-hover-media-query {
    //    // See Media Queries Level 4: http://drafts.csswg.org/mediaqueries/#hover
    //    // Currently shimmed by https://github.com/twbs/mq4-hover-shim
    //    @media (hover: hover) {
    //      &:hover { @content }
    //    }
    //  }
    //  @else {
    &:hover { @content }
    //  }
}

@mixin icon-hover-circle($iconUrl) {
    @include size(50px 50px);
    min-height: 50px;
    min-width: 50px;
    
    &:hover {
        border-radius: 50%;
        background-color: rgba($grey-light, 0.5);
    }
    
    .hover-circle {
        height: 100%;
        width: 100%;
        background: $iconUrl center / 100% 100% no-repeat;
    }
}

@mixin plain-hover-focus {
    @if $enable-hover-media-query {
        &,
        &:focus {
            @content
        }
        @include hover { @content }
    }
    @else {
        &,
        &:focus,
        &:hover {
            @content
        }
    }
}

@mixin hover-focus {
    @if $enable-hover-media-query {
        &:focus { @content }
        @include hover { @content }
    }
    @else {
        &:focus,
        &:hover {
            @content
        }
    }
}

@mixin reset-filter() {
    filter: "progid:DXImageTransform.Microsoft.gradient(enabled = false)";
}

@mixin circle-progress {
    @include size(60px);
    margin: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    line-height: 1rem;
    color: black;
    canvas {
        position: absolute;
        top: 0;
        left: 0;
    }
}

@mixin append__arrow($top: 0px, $color: black, $arrowUp: false) {
    &::after {
        content: "";
        position: relative;
        right: -8px;
        border-width: 8px 6px 0px 6px;
        border-style: solid;
        border-color: #{$color} transparent;
        top:  $top;
        margin-right: 8px;

        @if $arrowUp {
            border-width: 0px 6px 8px 6px;
        }
    }

}

@mixin append__arrow-down($top: 0px, $color: black) {
    @include append__arrow($top, $color, false);
}
@mixin append__arrow-up($top: 0px, $color: black) {
    @include append__arrow($top, $color, true);
}

@mixin make_circle_cross($color, $icon-size, $bar-width, $bar-length) {
    border-radius: 50%;
    width: $icon-size;
    height: $icon-size;
    background-color: $color;
    position: relative;

    &::before {
        content: " ";
        position: absolute;
        background-color: $background-light-grey;
        width: $bar-width;
        margin-left: -$bar-width*0.5;
        left: 50%;
        top: ($icon-size - $bar-length) * 0.5;
        bottom: ($icon-size - $bar-length) * 0.5;
    }
    &::after {
        content: " ";
        position: absolute;
        background-color: $background-light-grey;
        height: $bar-width;
        margin-top: -$bar-width*0.5;
        top: 50%;
        left: ($icon-size - $bar-length) * 0.5;
        right: ($icon-size - $bar-length) * 0.5;
    }
}

@mixin input-icon($iconUrl) {
    background-image: $iconUrl;
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: 15px center;
    padding-left: 45px;
    // font-family: FontAwesome;
}

@mixin prepend-little-icon($iconUrl) {

    &:before {
        content: "";
        display: inline-block;
        background-image: url($iconUrl);
        background-size: contain;
        background-repeat: no-repeat;
        width: 15px;
        height: 15px;
        margin: 0 6px -3px 0;
    }
}

// replace default styling for formatted HTML (in descriptions, emails, ...) (duplicate in 0-site, ref 9382)
@mixin beehire-formatted-html($color) {
    line-height: 1.8rem;
    justify-content: center;
    * {
        font-family: $font-family-main-book;
        font-size: $font-size-small;
        color: $color;
    }
    & > *,
    &.ql-blank:before // to also use on ng-quill placeholder
    {
        margin: 1rem 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    h1, h2, h3, h4, h5, h6 {
        margin-bottom: 10px;
    }
    h1, h2 {
        font-size: $font-size-large;
    }
    h3, h4 {
        font-size: $font-size-medium;
    }
    h5, h6 {
        font-size: $font-size-base;
    }
    p {
        margin-bottom: 1rem;
    }
    ol {
            padding-left: 20px;
        li {
            position: relative; 
            margin-left: 0;
            padding-left: 0em !important;
            &::before {
                margin-right: 0.5em;
            }
            @for $i from 1 through 8 {
                &.ql-indent-#{$i} {
                    margin-left: 0.5em + ($i - 1) * 0.5em;
                    &::marker {
                        content: none;
                        margin-right: 0.5em;
                    }
                }
            }
        }   
    }
    ul {
        list-style-type: none;
        padding-left: 10px;
        li {
            position: relative; 
            margin-left: 0;
            padding-left: 2px;
            &::before {
                content: '•';
                position: absolute;
                left: -10px;
                top: 4px;
                margin-right:10px;
                color: $color; 
                font-size: 1.2em;
                line-height: 1;
            }
            @for $i from 1 through 8 {
                &.ql-indent-#{$i} {
                    margin-left: 1em + ($i - 1) * 0.5em;
                    &::before {
                        content: nth(('◦', '▪', '▪', '▪', '▪', '▪', '▪', '▪'), $i);
                        margin-right: 0.2em;
                    }
                }
            }
        }
    }
    a {
        text-decoration: underline;
    }
    u {
        text-decoration: underline;
    }
    // classes given by ngquill 
    .ql-align-center {
        text-align: center;
    }
    .ql-align-left {
        text-align: left;
    }
    .ql-align-right {
        text-align: right;
    }
    .ql-align-justify {
        text-align: justify;
    } 
    .ql-size-small {
        font-size: .75em;
    }
    .ql-size-large {
        font-size: 1.5em;
    }
    .ql-size-huge {
        font-size: 2.5em;
    }
}
@mixin tag() {
    display: flex;
    flex-direction: flex-end;
    align-items: center;
    width: fit-content;
    text-align: center;
    align-content: center;
    margin: 5px;
    padding: 9px 13px 9px 13px;
    border: none;
    border-radius: 100px;
    background-color: $tag-background-color;
    font-size: $font-size-small;
    color: #fff;
    // using descendant selectors 'span' and 'a' because those are also used by the ng-tags-input library
    span {
        line-height: 1;
    }
    a {
        margin-bottom: 0;
        margin-left: 10px;
    }
}

@mixin job-category() {
    @include tag();
    background-color: $job-category-color-1;
}