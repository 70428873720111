.profile-photo {
    @include size(43px 43px);
    border-radius: 50%;
    object-fit: cover;
}

.profile-photo--md {
    @include size(60px 60px);
}

.profile-photo--lg {
    @include size(133px 133px);
    border-radius: 50%;
    object-fit: cover;
}

.profile-photo--s {
    @include size(38px 38px);
    border-radius: 50%;
    object-fit: cover;
}