$micMeterHeight: 120px;
$micMeterWidth: 40px;
$defaultElemSpacing: 30px;

.interview-video__video-container {
  width: 100%;
  height: calc(var(--vh)*100 - 275px);
  flex: 1;

  @media screen and (min-width: $size-lg-max) {
    height: calc(var(--vh)*100 - 205px);
  }

  // display: flex;
  // @include size(100% auto);
  // max-height: 90vh;
  // position: absolute;
  // border: 3px solid green;
  // background-color: #111;
  // align-self: center;
}

.interview-video__playback-container {
  @include size(100%);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 900;
  .plyr {
    height: 100%;
  }
  .interview-video__preview {
    padding: 0;
  }
}

.interview-video__playback {
  width: 100%;
}

.interview-proceed {

}

.pre-video {
  display: flex;
  align-items: center;
}

.pre-video__image {
  img {
    width: 100%;
    max-width: 300px;
  }
}

.pre-video__text {
  li {
    margin: 20px 0;
  }
}

.pre-app-mobile {
  display: flex;
  align-items: center;
}

.pre-app-mobile__store {
  display: flex;
  justify-content: center;

  img {
    max-width: 200px;
  }
}

.question-field__video {
  align-self: center;
}

.interview-video {
    // display: flex;
    // flex: 1;
    // flex-direction: column;
    // align-items: center;
    // h1, p {
    //     color: #333;
    // }
    // h1 {
    //     b {
    //         color: #bbb;
    //     }
    // }
    // p {
    //     text-align: center;
    // }
    // .btn--dark {
    //     padding: 1rem 8rem;
    // }
}

  .interview-video__question-preview {
    flex: 1;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    .plyr {
      height: 100%;
    }
    .interview-video__video-question-picture {
      margin-bottom: 1rem;
    }
    h1 {
      margin-bottom: 0.5rem
    }
    p {
      margin: 0 0 0.8rem !important;
    }
  }

  .interview-video__question-frame {
    @media screen and (min-width: $size-xl) {
      margin-bottom: 30px;
    }
    video {
      width: 100%;
    }
  }

  .interview-video__intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      margin-bottom: $defaultElemSpacing;
    }
  }


  .interview-video__question-preview, .interview-video__intro {
    width: 100%;
  }
  
    .thank-you__video {
        width: 500px;
    }

    .interview-video__recording {
        display: flex;
        align-items: center;
        padding: 10px 0;
        gap: 5px;
        margin: auto;
    }

        .interview-video__indicator {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 3.125rem;
            background-color: rgba(0,0,0,.9);
            z-index: 100;
            margin: 0 0 -3.125rem;
            span {
                font-weight: bold;
                line-height: 3.125rem;
            }
        }

        .interview-video__camera {
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 800;
            background-color: $warm-grey;
            margin: 0;
            width: 100%;
            h1 {
                color: red;
            }
        }

        video.interview-video__camera {
            height: auto;
        }

        object.interview-video__camera {

        }

            .interview-video__camera--full-screen {
                width: 100%;
                background-color: #111;
                margin: 0;
                max-height: 100%;
            }

            video.interview-video__camera--full-screen {
                height: auto;
            }

            object.interview-video__camera--full-screen {

            }

        .interview-video__playback, .interview-video__playback-intro {
          height: 100%;
          // min-height: 444px;
        }

          .interview-video__playback--full-screen {

          }

            .interview-video__overlay {
                @include size(100%);
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                bottom: 0;
                // border: 3px blue;
                box-shadow: inset 0px -200px 124px -45px rgba(0,0,0,0.75);
                // margin: 0px auto;
            }

                .interview-video__question {
                    margin-top: -30px;
                    max-width: 800px;
                    padding: 1.4rem 2rem 1.1rem;
                    border-radius: 3px;
                    z-index: 1500;
                    background-color: rgba(255,255,255,.8);
                    font-size: $font-size-base;
                    margin-bottom: $defaultElemSpacing;
                }

        .interview-video__mic {
            display: flex;
            position: relative;
            flex-direction: column;
            flex-shrink: 0;
            flex-grow: 0;
            @include size($micMeterWidth $micMeterHeight);
        }

            .interview-video__mic-left {
                position: absolute;
                @include size($micMeterWidth $micMeterHeight);
                background: url('/imgs/mic-strength-left@2x.png') center bottom / $micMeterWidth $micMeterHeight no-repeat;
            }

            .interview-video__mic-left-filled {
                position: absolute;
                bottom: 0;
                @include size($micMeterWidth $micMeterHeight);
                background: url('/imgs/mic-strength-left-filled@2x.png') center bottom / $micMeterWidth $micMeterHeight no-repeat;
            }

            .interview-video__mic-left-mask {
              position: absolute;
              bottom: 0;
              @include size($micMeterWidth $micMeterHeight);
              background: url('/imgs/mic-strength-left-mask@2x.png') center bottom / $micMeterWidth $micMeterHeight no-repeat;
            }


            .interview-video__mic-right {
                position: absolute;
                @include size($micMeterWidth $micMeterHeight);
                background: url('/imgs/mic-strength-right@2x.png') center bottom / $micMeterWidth $micMeterHeight no-repeat;
            }

            .interview-video__mic-right-filled {
                position: absolute;
                bottom: 0;
                @include size($micMeterWidth $micMeterHeight);
                background: url('/imgs/mic-strength-right-filled@2x.png') center bottom / $micMeterWidth $micMeterHeight no-repeat;
            }

            .interview-video__mic-right-mask {
              position: absolute;
              bottom: 0;
              @include size($micMeterWidth $micMeterHeight);
              background: url('/imgs/mic-strength-right-mask@2x.png') center bottom / $micMeterWidth $micMeterHeight no-repeat;
            }





    .interview-video__timer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        @include size(600px auto);
        // border: 1px solid red;
        // margin: 30px 0;
        margin: auto 0 -30px;
        z-index: 1000;

        .btn--video {
          margin: 0;
        }
    }


        .interview-video__timer-item {
            opacity: .4;
            &.active {
                opacity: .85;
            }
            h2 {
                font-size: $font-size-small;
                text-transform: uppercase;
                color: $white;
                width: 160px;
                text-align: center;
                margin-bottom: 20px;
                &.active {
                    color: $white;
                }
            }
        }

            .timer-circular {
            }

                .timer-circular__num {
                    font-size: 2.5rem;
                    line-height: 2.5rem;
                    color: $black;
                }

                .timer-circular__label {
                    color: $black;
                    font-size: $font-size-small;
                    padding: 0 15px;
                    text-align: center;
                }


    .interview-video__preview {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        // safari patch
        width: 50vh;
        height: auto;
        max-height: calc(100% - 170px);
        padding: 50px 0;
        .plyr {
            @include size(100%);
        }
    }

    .interview-video__preview-list {
        margin-bottom: 50px;
        a {
            padding: 0 1rem;
            color: #9C9C9C;
            font-size: $font-size-small;
            // + a {
            //     border-left: 1px solid $black;
            // }
            &:hover, &:focus, &:active {
                color: $black;
            }
        }
    }

    .interview-video__video-question-picture {
        @include size(54px 46px);
        background: url('/imgs/video-question-black.png') center / contain no-repeat;
        border: 0 none;
        margin-bottom: 1rem;
    }

    a.thank-you__feedback {
      color: $brand-blue;
      text-decoration: underline;
    }
