/*
---
name: Bookmark
category: components/sidebars
tag:
  - Sidebars
---

```jade
.sidebar.sidebar--dark
    .sidebar-bookmark__title
        h1 Bookmark
        span 2

    .sidebar-bookmark__profile
        .sidebar-bookmark__profile-item
            img.profile-photo.sidebar-bookmark__photo(src='/imgs/profile-marc@2x.png')
            .sidebar-bookmark__name Marc Chavanne
        .sidebar-bookmark__delete.icon.icon--delete

    .sidebar-bookmark__profile
        .sidebar-bookmark__profile-item
            img.profile-photo.sidebar-bookmark__photo(src='/imgs/profile-samantha@2x.png')
            .sidebar-bookmark__name Samanta Delvaux
        .sidebar-bookmark__delete.icon.icon--delete
```
*/

.sidebar {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    min-width: 400px;
    padding: 4.375rem 0;
    background: #fff;
}

.sidebar--dark {
    background-color: $charcoal-grey;
    .label {
        text-transform: uppercase;
        color: #bbb;
    }
}

.sidebar--video {
    justify-content: center;
    .interview-video__camera {
        width: 100%;
        height: auto;
    }
}

    .sidebar-bookmark__title {
        display: inline-flex;
        align-items: center;
        margin-bottom: 100px;
        h1, span {
            color: $white;
            margin-bottom: 0;
        }

        h1 {
            margin-right: 20px;
        }

        span {
            background-color: #56595c;
            @include size(30px);
            text-align: center;
            line-height: 30px;
            border-radius: 50%;
            font-size: $font-size-smaller;
        }
    }

    .sidebar-bookmark__profile {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include size(100% 70px);
        margin-bottom: 3px;
        background-color: #56595C;
        padding: 0 20px;
    }
        .sidebar-bookmark__profile-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            transition: all 0.2s ease-in-out;
            &:hover {
                opacity: .7;
            }
        }

            .sidebar-bookmark__photo {
                margin-right: 20px;
                min-width: 43px;
            }

            .sidebar-bookmark__name {
                color: $white;
                overflow: hidden;
                text-overflow: ellipsis;
            }

        .sidebar-bookmark__delete {
            cursor: pointer;
        }

/*
---
name: Candidate profile
category: components/sidebars
tag:
  - Sidebars
---

```jade
.sidebar.sidebar--profile
    .sidebar-profile
        img.sidebar-profile__photo(src='/imgs/profile-julian@2x.png')
        .sidebar-profile__row
            .sidebar-profile__info
                span.sidebar-profile__name Julian O'hayon
                span.sidebar-profile__address 1180 Bruxelles, Belgique
            .sidebar-profile_menu.icon.icon--menu
        .sidebar-profile__row
            .sidebar-profile__rating
                span 4,8
            .sidebar-profile_bookmark.icon.icon--bookmark

    ul.sidebar-links
        li.active
            a Interview
        li
            a Tests
        li
            a Documents
        li
            a Notes
            span 2


```
*/

.sidebar--profile {
    padding: 50px;
    max-width: 400px;
    min-width: 400px;
}

.sidebar-profile {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    overflow-wrap: anywhere;
}

    .sidebar-profile__photo {
        @include size(110px);
        margin: 0 0 20px 0px;
        border-radius: 50%;
        object-fit: cover;
    }

        .sidebar-profile__photo-added-by {
            @include size(38px);
            border: 1px solid black;
            border-radius: 50%;
            margin: 0 8px;
            padding: 3px;
        }

    .sidebar-profile__row {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        + .sidebar-profile__row {
            margin-top: 20px;
        }

        .sidebar-profile__rating-table {
            td {
                padding: 4px 10px;
            }

            td.rating-table__title {
                max-width: 140px;
            }
            .rating-table__stars {
                padding: 0px;
            }
        }
    }
    
    .sidebar-profile__actions {
        tooltip + tooltip, tooltip + div {
            margin-left: 10px;
        }
    }

        .sidebar-profile__icon {
            $icon-profile-sidebar-size: 32px;
            height: $icon-profile-sidebar-size;
            width: $icon-profile-sidebar-size;
            min-height: $icon-profile-sidebar-size;
            min-width: $icon-profile-sidebar-size;
            max-width: $icon-profile-sidebar-size;
            max-height: $icon-profile-sidebar-size;
            border: 1px solid #e2dfdf;
        }

        .sidebar-profile__info {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }

            .sidebar-profile__name {
                font-size: $font-size-medium;
                font-family: $font-family-main-medium;
            }

            .sidebar-profile__personal {
                font-size: $font-size-small;
                & + .sidebar-profile__personal {
                    margin-top: 6px;
                }
            }

            .sidebar-profile__email {
                @include prepend-little-icon('/imgs/icons/profile-sidebar/icon-at-x32.png');
                text-decoration: underline;
            }
            .sidebar-profile__no__email {
                @include prepend-little-icon('/imgs/icons/profile-sidebar/icon-at-x32.png');
            }
            .sidebar-profile__address {
                @include prepend-little-icon('/imgs/icons/profile-sidebar/icon-place-x32.png');
            }
            .sidebar-profile__referral {
                // @include prepend-little-icon('/imgs/icons/icon-career@2x.png');
            }
            .sidebar-profile__linkedin {
                @include prepend-little-icon('/imgs/icons/LI-In-Bug.png');
            }
            .sidebar-profile__phone {
                @include prepend-little-icon('/imgs/icons/profile-sidebar/icon-phone-x32.png');
            }
            .sidebar-profile__date {
                @include prepend-little-icon('/imgs/icons/profile-sidebar/icon-calendar-x32.png');
            }

        .sidebar-profile__rating {
            display: flex;
            align-items: center;
            justify-content: center;
            @include size(74px 57px);
            background: url('/imgs/profile-rating@2x.png') center center / contain no-repeat;
            color: $white;
            margin-left: 5px;
            font-size: $font-size-medium;
            font-weight: bold;
        }

    .sidebar-profile__stage {
        width: 100%;
    }

    .stage__main {
        display: flex;
        align-items: center;
        width: fit-content;
        margin: auto;
    }

    .stage__refusal-reason {
        margin-top: 15px;
        position: relative;
    }
        .refusal-reason__summary {
            text-align: center;
        }
        .refusal-reason__placeholder {
            color: $grey-darker;
        }
        .refusal-reason__edit-button {
            position: absolute;
            top: -8px;
            right: 0px;
        }
        .refusal-reason__text {
            margin-top: 10px;
        }
        .refusal-reason__textarea {
            width: 100%;
        }

.sidebar-links {
    margin-top: 40px;
    width: 100%;
    text-align: center;
    li {
        padding: 15px 0;
        text-transform: uppercase;
        color: #7C8083;
        font-family: $font-family-main-medium;
        font-size: $font-size-small;

        + li {
            border-top: 1px solid #eaeef1;
        }

        a {
            font-family: $font-family-main-medium;
            &:hover, &.active {
                color: $brand-color-1;
            }
        }
    }
}

      .sidebar--edit-profile {
          max-width: 540px;
          padding: 50px 0;
          .account-settings__photo {
              align-self: flex-start;
          }
          .btn--where-info {
              align-self: flex-start;
          }
      }

      .sidebar-profile__tags {
          height: 45px;
          overflow: hidden;
      }

      .sidebar-profile__tag-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        align-items: center;

        .badge {
            margin: 5px;
        }
      }