ng-dropdown-multiselect {
    .multiselect-parent {
        position: relative;

        // .dropdown-toggle {
        //     @include append__arrow-down(0, $black);
        //     width: 230px;
        //     padding: 20px;    background-color: $input-field-grey;
        //     background-color: $input-field-grey;
        //     color: $black;
        //     font-family: Gotham Book;
        //     font-size: $font-size-default;
        // }

        //new oussama
        .dropdown-toggle {
            border: none;
            background-color: transparent;
            padding: 0;
            outline:none;
            // @include append__arrow-down(0, $black);
            color: $black;
            font-family: Gotham Book;
            font-size: $font-size-default;
            width: 100%;
            justify-content: space-between;
        }
    
        .dropdown-menu {
            position: absolute;
            z-index: 10;
            max-height: 50vh;
            padding: 5px 0;
            margin: 2px 0 0;
            background-color: white;
            border: 1px solid #dadada;
            font-size: $font-size-default;
            border-radius: 5px;
            box-shadow: 0 6px 12px rgba(0,0,0,.175);

            li {
                a {
                    display: block;
                    padding: 3px 20px;
                    line-height: calc(10/7);
                    white-space: nowrap;
                    cursor: default;
        
                    span.glyphicon-remove:before {
                        content: "✘";
                    }
        
                    span.glyphicon-ok:before {
                        content: "✔";
                    }

                    input, textarea {
                        -webkit-appearance: checkbox;
                        margin-right: 10px;
                    }
                }

                .dropdown-header {
                    padding: 3px 20px;

                    input.form-control {
                        height: 34px;
                        padding: 6px 12px;
                        border: 1px solid #ccc;
                        border-radius: 4px;
                        box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
                    }
                }
            }
    
            li.divider {
                height: 1px;
                margin: 9px 0;
                overflow: hidden;
                background-color: #e5e5e5;
            }
        }
    }
}

ng-dropdown-multiselect.dropdown-menu-above-spinner {
    .multiselect-parent {
        .dropdown-menu {
            z-index: 10001 !important;
        }
    }
}