/*
---
name: Main navigation
category: navs/main-nav
tag:
  - Navs
---

```jade
.main-nav
    a.logo.main-nav__logo(href='/') talentis
      b .

    .main-nav__links
        a.active Campaigns
        a Collaborators

    .main-nav__right.main-nav__right--profile
        a Joey Cariano
```
*/

.main-nav {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 0 $content-padding;
    margin-top: 1rem;
    margin-bottom: 1rem;
    @include size(100% 60px);
    min-height: 60px;

    // for the language selector (angular-dropdowns puts it into a div with class 'wrap-dd-menu')
    .main-nav__lang {
        .wrap-dd-menu {
            margin: unset;
            text-transform: uppercase;
            .dropdown {
                width: auto;
                border-top: 5px solid $black;
            }
        }
    }
}

    .main-nav__logo_links {
        display: flex;
        align-items: center;
    }

    .main-nav__logo {
        font-size: 1.625rem;
        width: auto;
        max-height: 80px;
        max-width: 200px;
    }

    .main-nav__links {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        
        margin-left: 40px;

      	letter-spacing: 0.5px;
        text-transform: uppercase;
        white-space: nowrap;

        & > * {
            margin-left: 5px;
            margin-right: 25px;
        }
    }

    .main-nav__home-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        width: 30px;
        height: 18px;
        img {
            display: block;
            max-width: 100%;
            max-height: 100%;
        }
    
        &:hover {
            opacity: 0.8;
            cursor: pointer;
        }
    }
    

    .dropdown--nav-more {
        padding: 10px 15px;
        margin-left: -15px;
        background: url('/imgs/icons/icon-profile-arrow@2x.png') right center / 8px 5px no-repeat;
        font-family: $font-family-main-book;
        font-size: $font-size-default;

        & + .dropdown {
            width: 315px;
            margin-top: 5px;
        }
    }

    .main-nav__links, .dropdown--nav-more {
        a {
            color: $grey-darkerer;
            font-family: $font-family-main-medium;
            font-size: $font-size-small;
            &:hover, &.active {
                color: $black;
            }
        }
    }

    .main-nav__position {
        font-size: $font-size-base;
        color: $warm-grey;
        b {
            color: $brand-color-1;
        }
    }

    .main-nav__right {
        display: flex;
        // a {
        //     font-family: $font-family-main-book;
        //     font-size: $font-size-small;
        // }
    }

        .main-nav__right--dropdown {
            padding-right: 15px;
            background: url('/imgs/icons/icon-profile-arrow@2x.png') right center / 8px 5px no-repeat;
        }

        .main-nav-link {
            color: #111;
            font-family: $font-family-main-medium;
            font-size: $font-size-default;
            letter-spacing: 1px;

            display: flex;
            align-items: center;
        }

            .main-nav-link--grey {
                padding: 1rem 1.5rem;
                border-radius: 3px;
                background-color: #e7e7e7;
            }


/*
---
name: Page navigation
category: navs/page-nav
tag:
  - Navs
---

```jade
.page-nav
    .page-nav__back
        a Back

    .page-nav__title
        span Create a new campaign
```
*/


.page-nav {
    display: inline-flex;
    @include size(100% 40px);
    min-height: 40px;
    background-color: $brand-color-2;
    justify-content: flex-start;
    align-items: center;
    text-transform: uppercase;
    font-size: $font-size-smaller;
    letter-spacing: 0.5px;
    padding: 0 $content-padding;
}

.page-nav__live-interview {
    background-color: $brand-blue;
}

    .page-nav__back {
        margin-right: 30px;
        a {
            color: white;
            background: url('/imgs/icons/icon-page-nav-back@2x.png') left center / 8px 15px no-repeat;
            padding-left: 12px;
            height: 15px;
            &:hover {
                opacity: .5;
            }
        }
    }

    .page-nav__title {
        font-family: $font-family-main-bold;
        font-size: $font-size-small;
        color: white;
    }


/*
---
name: Process navigation
category: navs/process-nav
tag:
  - Navs
---

```jade
.process-nav
     
        .process-nav__steps-item
            .process-nav__steps-item-num 1
            span.process-nav__steps-item-title Campaign settings

        .process-nav__steps-item.active
            .process-nav__steps-item-num 2
            span.process-nav__steps-item-title Interview

        .process-nav__steps-item
            .process-nav__steps-item-num 3
            span.process-nav__steps-item-title Tests

        .process-nav__steps-item
            .process-nav__steps-item-num 4
            span.process-nav__steps-item-title Documents

    a.btn.btn--icon-next Next


```
*/

.process-nav {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    @include size(100% 55px);
    padding: 0 $content-padding;
    background-color: $black;
}

    .process-nav__steps {
        display: inline-flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-around;
        @include size(100%);
        font-size: $font-size-default;
    }

        .process-nav__steps-item {
            display: inline-flex;
            opacity: .6;

            &.active {
                opacity: 1;
            }
        }

        .steps-item--disabled {
            pointer-events: none;
        }

            .process-nav__steps-item-num {
                // display: flex;
                font-family: $font-family-main-bold;
                @include size(25px);
                line-height: 25px;
                background-color: $brand-blue;
                text-align: center;
                color: white;
                border-radius: 50%;
                padding-top: 1px; // font padding fix
                margin-right: 15px;
            }

            .process-nav__steps-item-title {
                font-family: $font-family-main-bold;
                line-height: 25px;
                padding-top: 1px; // font padding fix'
                color: white;
            }

    .process-nav__buttons {
        display: flex;
        .btn + .btn {
            margin-left: 10px;
        }
    }
        .process-nav__button {
            min-width: 140px;
        }
    
        .process-nav__btn-save {
            background-color: #EAEAEA;
            color: black;
            &:hover {
                color: $white;
            }
        }


.process-subnav {
    //display: inline-flex;
    //justify-content: space-between;
    //align-items: center;
    text-align: center;
    line-height: 40px;
    @include size(100% 40px);
    padding: 0 $content-padding;
    background-color: #454545;
    color: $white;
    font-size: $font-size-default;
}
