.unsupported-browsers {
    @include size(100vw 100vh);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
    background-color: #fff;
    padding: 2rem 1rem;
    @media (max-height: 600px) {
        @include size(125vw 125vh);
    }
}

.unsupported-browsers__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 85%;
    justify-content: center;
    h1 {
        font-family: $font-family-main-bold;
        font-size: 2.375rem;
        margin-bottom: 1.875rem;
    }
    p {
        font-size: $font-size-default;
        text-align: center;
    }
}


    .unsupported-browsers__download {
        display: flex;
        margin-top: 3.75rem;
    }

        .unsupported-browsers__link {
            display: flex;
            flex-direction: column;
            align-items: center;
            + .unsupported-browsers__link {
                margin-left: 5.25rem;
            }
            a {
                font-family: $font-family-main-bold;
                font-size: $font-size-default;
                color: $brand-color-1;
                text-decoration: underline;
                margin-bottom: 5px;
                &:hover {
                    color: $black;
                };
            }
            span {
                font-size: 0.8125rem;
            }
        }

            .unsupported-browsers__icon {
                @include size(204px 199px);
                margin-bottom: 2.5rem;
            }

                .unsupported-browsers__icon--chrome {
                    background: url('/imgs/unsupported-browser-chrome.png') center center / contain no-repeat;
                }

                .unsupported-browsers__icon--firefox {
                    background: url('/imgs/unsupported-browser-firefox.png') center center / contain no-repeat;
                }

                .unsupported-browsers__icon--opera {
                    background: url('/imgs/unsupported-browser-opera.png') center center / contain no-repeat;
                }
