.submission-tasks {
  flex: 1;

  .priority--low {
    .task__priority__col {
      .dropdown-multiselect button,
      .crud-list__data-label {
        color: #f2ca3a;
      }
    }
  }
  .priority--medium {
    .task__priority__col {
      .dropdown-multiselect button,
      .crud-list__data-label {
        color: #d9730d;
      }
    }
  }
  .priority--high {
    .task__priority__col {
      .dropdown-multiselect button,
      .crud-list__data-label {
        color: #d44c47;
      }
    }
  }

  .overdue {
    .task__duedate__col .crud-list__data-label {
      color: $warning-color-darker;
    }
  }

  .task-title--done {
    .task__title__col .crud-list__data-label {
      text-decoration: line-through;
    }
  }

  .tasks__header {
    display: flex;
  }
}

.task__title__col {
  min-width: 120px;
  margin-right: auto;
  width: 100%;
  .crud-list__data-label {
    white-space: normal;
    word-break: break-all;
  }
}
.task__campaign__col {
  width: 150px;
  flex: 0 0 150px;
}
.task__candidate__col {
  width: 150px;
  flex: 0 0 150px;
}
.task__reminder__col {
  width: 300px;
}
.task__priority__col {
  width: 90px;
  flex: 0 0 90px;
}
.task__no-prop {
  color: $warm-grey;
}
