$table-border-radius: 3px;
$table-border: solid 1px rgba(226, 229, 232, 0.4);

.table-list {
    width: 100%;

    table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 3px;
        th, td {
            padding: 0px 15px;
            height: 65px;
            text-align: center;
            background-color: white;
        }
        td:first-child, th:first-child {   
            text-align: start;
        }

        // Borders

        td:first-child, th:first-child {
            border-top: $table-border;
            border-left: $table-border;
            border-bottom: $table-border;
            border-top-left-radius: $table-border-radius; 
            border-bottom-left-radius: $table-border-radius;
        }
        td:last-child, th:last-child {
            border-top: $table-border;
            border-right: $table-border;
            border-bottom: $table-border;
            border-bottom-right-radius: $table-border-radius; 
            border-top-right-radius: $table-border-radius; 
        }
    }
}