$paginator-border-color: #ddd;

.paginator-component {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  border: 1px solid $paginator-border-color;
  background-color: white;

  & .paginator__current-page {
    font-size: 1.5em;
    margin: 0 20px;
  }

  .paginator-component__button {
    background-color: transparent;
    height: 100%;
    width: 100%;
    padding: 10px 15px;
    border: 0px;
    color: $black;

    &.active, &.active:disabled {
      color: $brand-yellow;
      font-family: $font-family-main-medium;
    }

    &:disabled {
      color: $warm-grey;
      cursor: default;
    }

    .paginator-component__button__previous {
      &:after {
        content: '';
        width: 0;
        height: 80%;
        border: 1px solid $paginator-border-color;
      }
    }

    .paginator-component__button__next {
      &:before {
        content: '';
        width: 0;
        height: 80%;
        border: 1px solid $paginator-border-color;
      }
    }

    .paginator-component__button__next, .paginator-component__button__previous {
      width: 150px;
      font-family: $font-family-main-medium;
    }
  }
}