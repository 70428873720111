.google-autocomplete-dropdown {
    border: 1px solid #ccc;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    background-color: #fff;
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
    margin-top: 5px;
    border-radius: 10px;
  }
  
  .google-autocomplete-dropdown li {
    padding: 8px 12px;
    cursor: pointer;
    line-height: 24px;
  }
  
  .google-autocomplete-dropdown li:hover {
    background-color: #eee;
    border-radius: 10px;
  }

  .google-autocomplete-container  {
    position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  }
  
  .google-autocomplete-container  .fa-spinner {
    margin-left: -46px; 
    font-size: 180%;
    color: $grey-darker;
  }
 