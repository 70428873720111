.interview-informations {
    .candidate-profile__password {
        tooltip tip {
            width: 300px;
            margin-left: 50px;
            tip-arrow {
                margin-left: -50px;
            }
        }
    }
}

    .input-field--shorter {
        width: 405px;
    }

    .interview-informations__photo {
        @include size(88px 88px);
        margin: -8px 0 16px -2px;
        border-radius: 100%;
        align-self: center;
        cursor: pointer;
    }

    .upload__no-input {
        padding: 10px;
        button {
            // font-family: initial;
            border-style: dashed;
            // border-width: thin;
            height: 40px;
            width: 100%;
            &.dragover {
                opacity: 0.5;
            }
        }
    }

.interview-archived {
    height: 100vh;
}

.terms-and-conditions-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .terms-and-conditions-item {
        padding: 30px 30px 10px;
        border: 1px solid $grey-darker;
        border-radius: 5px;
    }
}

.dev-button-container{
    display: flex;
    justify-content: center;
    align-items:center;
}
.autofillfields{
    padding: 14px;
    width:30%;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 20px;
    justify-content: center;
}