$questions-popup-head-height: 106px;

.cc-questions {
    display: flex;
    flex-direction: row;
    flex: 1;
    background-color: #EAEAEA;
}

    .cc-questions__container {
        display: flex;
        flex-direction: column;
        flex: 4 1 0%;
        padding: 45px 40px;
    }

        dashboard-question + dashboard-question {
            margin-top: 40px;
        }

        .cc-questions__form {
            display: flex;
            flex: 1;
            flex-direction: column;
            .btn--full-width {
                padding: 1.3rem 0;
            }
        }

            .cc-questions__form-label {
                margin-bottom: 20px;
                label {
                    padding-left: 0;
                    text-transform: uppercase;
                    &:before {
                        right: 5rem;
                    }
                    &:after {
                        left: 5rem;
                    }
                }
            }

            .cc-questions__add-buttons {
                display: flex;
                justify-content: center;
                margin-top: 6px;
                .btn {
                    padding: 1rem 1.25rem;
                }
            }

        .cc-questions__button-bar {
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            align-items: center;
            padding: 2.5rem 0;
        }

            .button-bar__item {
                cursor: pointer;
                color: #999;
                margin: 0.5rem 1rem;
                white-space: nowrap;
                font-size: $font-size-default;
                font-family: $font-family-main-medium;
                transition: all 0.3s ease-in-out;
                &.active, &:hover {
                    color: #111;
                }
            }

        .question__list-item {
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 1.25rem 2.5rem 1.25rem 1.25rem;
            border: 1px solid transparent;
            font-family: $font-family-main-book;
            font-size: $font-size-small;
            line-height: 2;
            background-color: $white-three;
            border-radius: 6px;
            &.question__list-item--disabled {
                cursor: default;
            }
            &:hover {
                background: $brand-color-3 url('/imgs/icons/icon-arrow-right-white@2x.png') 97% center / 9px 16px no-repeat;
                color: #fff;
                .question__list-item-dropdown {
                    .icon {
                        background: url('/imgs/icons/icon-3-dots-white@2x.png') center / 100% 100% no-repeat;
                    }
                }
            }
            + .question__list-item {
                margin-top: 18px;
            }
            &.question--active, &.question__list-item--add-question {
                border: 1px solid rgba(0,0,0,.2);
                &:hover {
                    background: $white-three;
                    color: $black;
                }
            }
            &.question--form {
                .question__input {
                    .question__input-row {
                        margin-top: 0;
                        align-items: flex-start;
                        textarea {
                            padding: 0;
                            margin-top: -5px;
                        }
                    }
                }
            }
            .icon {
                margin-right: 15px;
            }
        }

            .question__list-item--add-question {
                color: #9e9e9e;
            }

                .question__list-item-dropdown {
                    height: 21px;
                    // margin-right: 20px;
                    .icon {
                        margin: 0 25px 0 5px
                    }
                    .wrap-dd-menu {
                        .dropdown {
                            width: 250px;
                        }
                    }
                }

                .question__list-item-text {
                }


.cc-questions-templates {
    display: flex;
    flex-direction: column;
    flex: 3 1 0%;
    background-color: #fff;

    .cc-questions__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }

        .cc-questions__form-container {
            flex: 1;
            width: 100%;
        }
}

    .cc-questions-templates__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        select {
            width: 150px;
            font-family: $font-family-main-book;
            font-size: $font-size-small;
            padding-left: 0;
            margin: 0;
            min-height: 0;
        }
        h1 {
            margin: 0;
        }
        a {
            color: #999;
            &.active {
                color: $black;
            }
        }
    }
