.multipage-edit {
  width: 100%;
  display: flex;

  &.row, .row {
    margin-left: 0px;
    margin-right: 0px;
  }

  .multipage-edit--title {
    text-align: center;
    font-weight: 700;
  }

  .multipage-edit--sidebar {
    padding: 50px;
    height: 100%;
    
    .multipage-edit--sidebar-item {
      width: 100%;
      font-family: $font-gotham-medium;
      color: $steel-grey;

      &.active, &:hover {
        color: $brand-color-1;
      }
    }
    ul.multipage-edit--sidebar-item {
      & + ul.multipage-edit--sidebar-item {
        margin-top: 30px;
        padding-top: 30px;
      }
      & + ul.multipage-edit--sidebar-item.multipage-edit--sidebar-item-divisor {
        border-top: 1px solid #eaeef1;
        text-transform: uppercase;
      }

      & > span:first-child {
        cursor: pointer;
        display: inline-block;
        width: 100%;
        &.active {
          color: $brand-color-1;
        }
      }

      li {
        color: $warm-grey;
        margin-left: 40px;
        cursor: pointer;
        margin-top: 30px;
        text-transform: none;
      }
    }

    .multipage-edit--new-brand {
      cursor: pointer;
      width: 100%;
      border-top: 1px solid #eaeef1;
      margin-top: 30px;
      padding-top: 30px;
    }
  }

  .multipage-edit--content {
    background-color: $white-three;
    height: 100%;
  }

  .multipage-edit--form {
    align-items: center;
  }
  
  .multipage-edit--upload {
    gap: 5%;
    flex-wrap: wrap;
    .box__input-upload {
      border: 2px dashed;
    }
    .multipage-edit--upload--thumb-small {
      max-width: 130px;
      max-height: 60px;
    }
    .multipage-edit--upload--thumb-large {
      max-width: 170px;
      max-height: 190px;
    }
    .multipage-edit--upload-item {
      width: 40%;
      min-width: 300px;
    }
    
    span {
      color: $warm-grey-two;
      &.label--sub {
        font-family: $font-gotham-book;
      }
    }
  }

  .multipage-edit--buttons {
    margin-top: 30px;
    text-align: center;

    .btn {
      margin-top: 30px;
      max-width: 200px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .multipage-edit--career-link {
    gap: 20px;
  }
  
  .multipage-edit--campaign-selection {
    width: 100%;

    .input-field--checkbox.half-checked {
      background-image: url('/imgs/icons/icon-checked-half-bold@3x.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 12px 9px;
    }
    .input-field--checkbox.checked {
      background-image: url('/imgs/icons/icon-checked-bold@2x.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 12px 9px;
    }
    
    .multipage-edit--campaign-selection-filter {
      font-size: $font-size-small;
      height: 45px;
      margin-bottom: 10px;
    }

    .multipage-edit--campaign-selection-list {
      background-color: white;
      padding: 25px 50px;
      max-height: 400px;
      width: 100%;
      overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 15px;
      
      .input-field--checkbox-container {
        margin-bottom: unset;

        &.checkbox--selectAll {
          margin-bottom: 10px;
        }

        &>.input-field__title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: 0px;
          display: inline-block;
        }
      }
    }
  }

  .multipage-edit--filters {
    padding-left: 50px;
    padding-top: 20px;
  }

  .multipage-edit--header-banner {
    padding-left: 30px;
    padding-right: 30px;
  }

  // color picker customizations
  .sp-replacer {
    width: 100px;
    height: 40px;
    background-color: white;
    padding: 9px;
    
    .sp-preview {
      width: 56px;
    }
    .sp-dd {
      font-size: 17px;
    }
  }

  .multipage-edit--sections {
    width: 100%;
    max-width: 625px;
    flex-direction: column;
    gap: 20px;
    .sections--add {
      max-width: 220px;
      margin-left: auto;
      margin-top: -20px;
    }
    table {
      width: 100%;
      tr {
        height: 60px;
        padding: 10px;
        background-color: white;
      }
    }
  }
}

.link-input {
  padding-top: 20px;
  padding-bottom: 10px;
}

.link-input_item {
  max-width: 330px;
  
  & + & {
      margin-top: 10px;
  }

  a {
      text-decoration: underline;
      transition: all 0.3s ease-in-out;
      font-size: $font-size-small;
      
      &:hover {
          color: #999;
      }
  }

  a.icon {
    font-size: $font-size-default;
    text-decoration: none;
    margin-left: 10px;
  }

  span {
      margin-right: 10px;
  }
}
