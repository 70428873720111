.campaign-selection {
  text-align: left;

  .campaign-selection--access-all-warn {
    margin-bottom: 10px;
  }

  .campaign-selection--filter {
    font-size: $font-size-small;
    height: 45px;
    margin-bottom: 10px;
  }

  .input-field--checkbox.half-checked {
    background-image: url('/imgs/icons/icon-checked-half-bold@3x.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12px 9px;
  }
  .input-field--checkbox.checked {
    background-image: url('/imgs/icons/icon-checked-bold@2x.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12px 9px;
  }

  .campaign-selection--list {
    background-color: white;
    padding: 25px 50px;
    max-height: 400px;
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 15px;
    
    .input-field--checkbox-container {
      margin-bottom: unset;

      &.checkbox--selectAll {
        margin-bottom: 10px;
      }

      &>.input-field__title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 0px;
        display: inline-block;
      }
    }
  }
}