.box__toggle {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 16px;

  &-tags {
    @extend .box__toggle;
    margin-left: 20px;
    margin-bottom: 0px;
    min-width:200px;
    & p {
      font-size: 1rem;
    }
  }

}

.toggle-checkbox {
  display: none;
}

.toggle-container {
  position: relative;
  width: 52px;
  height: 26px;
  background: #CDCDCD;
  border-radius: 26px;
  transition: background-color 0.3s;
  margin-left: 16px;
  cursor: pointer;
  
  &::before {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: 20px;
    height: 20px;
    background: white;
    border-radius: 50%;
    transition: left 0.3s;
  }
}

.toggle-checkbox:checked + .toggle-container {
  background: $brand-yellow;
  
  &::before {
    left: 29px;
  }
}

.box__input-field.input-field--disabled {
  pointer-events: none;
  background-color: #e0e0e0;
  color: #4a4a4a;

  .input-field {
    background-color: #e0e0e0;
    color: #4a4a4a;
    cursor: not-allowed;
  }
}
