.create-job-categories {
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: left;

  &.box-container {
    padding: 0px;
  }

  h4 {
    margin-bottom: 5px;
  }
}
.job-categories__section {
  padding-bottom: 10px;

  .job-categories-container {
    display: flex;
    flex-wrap: wrap;

    .badge {
      margin: 5px;
    }
  }
}

.job-categories__num {
  font-size: $font-size-small;
  color: $warm-grey-two;
  margin-bottom: 5px;
}