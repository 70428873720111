$unactiveFilterColor: $black;
$activeFilterColor: $charcoal-grey;

.candidates__display-mode {
    display: flex;
    align-items: center;
    gap: 20px;
}

.x-scrollable {
    overflow: auto visible;
    width: 100vw;
    margin-left: -50px;
    padding-left: 50px;
    min-height: 540px;

    ul.dropdown {
        display: none;
        &.active {
            display: inline-block;
        }
    }
}

.candidates-profiles {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: fit-content;

    .profile-field {
        .bulk-actions--columns {
            width: 350px;
            &.bulk-actions--long {
                width: 510px;
            }
        }
        & > div {
            flex-shrink: 0;
        }

        height: auto;
        min-height: 70px;
        .profile-field__name {
            max-width: 100%;
        }
    }

    .input-field--checkbox {
        min-height: 20px;
        min-width: 20px;
        @include size(20px);

        display: flex;
        
        background-repeat: no-repeat;
        background-position: center;
        background-size: 12px 9px;

        &.half-checked {
            background-image: url('/imgs/icons/icon-checked-half-bold@3x.png');
        }

        &.checked {
            background-image: url('/imgs/icons/icon-checked-bold@2x.png');
        }
    }
}

    .candidates__campaign-infos {
        color: $grey-darkerer;    
        tooltip {
            width: fit-content;
        }
    }

        .candidates__info {
            margin: 5px 0px;
        }

    .candidates-profiles__filters {
        display: flex;
        align-items: center;
        justify-content:flex-end;
        font-size: $font-size-small;
        color: $unactiveFilterColor;
        gap: 30px;
        width: max-content;
    }

    .candidates-profiles__filters.candidates-profiles__filters--columns {
        gap: 0;
        margin-left: auto;

        ng-dropdown-multiselect.candidate-profiles__assessFirst-search,
        ng-dropdown-multiselect.candidate-profiles__docimo-search,
        ng-dropdown-multiselect.candidate-profiles__interview-search,
        ng-dropdown-multiselect.candidate-profiles__tag-search,
        ng-dropdown-multiselect.candidate-profiles__campaign-search,
        ng-dropdown-multiselect.candidate-profiles__stage-search,
        ng-dropdown-multiselect.candidate-profiles__matching-search,
        ng-dropdown-multiselect.candidate-profiles__rating-search,
        ng-dropdown-multiselect.candidate-profiles__source-search,
        ng-dropdown-multiselect.candidate-profiles__addedBy-search,
        ng-dropdown-multiselect.candidate-profiles__country-search {
            width: 160px;
        }

        ng-dropdown-multiselect.candidate-profiles__assessFirst-search {
            width: 120px;
        }
        ng-dropdown-multiselect.candidate-profiles__docimo-search {
            width: 120px;
        }
        ng-dropdown-multiselect.candidate-profiles__interview-search {
            width: 120px;
        }
        ng-dropdown-multiselect.candidate-profiles__stage-search {
            width: 140px;
        }
        ng-dropdown-multiselect.candidate-profiles__matching-search {
            width: 90px;
        }
        ng-dropdown-multiselect.candidate-profiles__rating-search {
            width: 120px;
        }
    }

        ng-dropdown-multiselect.candidate-profiles__assessFirst-search,
        ng-dropdown-multiselect.candidate-profiles__docimo-search,
        ng-dropdown-multiselect.candidate-profiles__interview-search,
        ng-dropdown-multiselect.candidate-profiles__tag-search,
        ng-dropdown-multiselect.candidate-profiles__campaign-search,
        ng-dropdown-multiselect.candidate-profiles__stage-search,
        ng-dropdown-multiselect.candidate-profiles__matching-search,
        ng-dropdown-multiselect.candidate-profiles__rating-search,
        ng-dropdown-multiselect.candidate-profiles__source-search,
        ng-dropdown-multiselect.candidate-profiles__addedBy-search,
        ng-dropdown-multiselect.candidate-profiles__country-search {
            max-width: 160px;
            display: flex;
            justify-content: center;      

            .multiselect-parent {
        
                .dropdown-toggle {
                    // overwrite properties of buttons
                    border: none;
                    background-color: transparent;
                    padding: 0;
                    // apply same style as other filters
                    font-size: $font-size-small;
                    color: $unactiveFilterColor;
                    
                    &::after {
                        content: "\f0b0";
                        font-size: 10px;
                        position: absolute;
                        right: -13px;
                        font-family: 'Font Awesome 5 Free';
                        font-weight: 900;
                    }
                
                    &.active {
                        font-family: $font-family-main-bold;
                        color: $activeFilterColor;
                        &::after {
                            color: black;
                        }
                    }
                }

                .dropdown-menu-form {
                    color: $black;
                }
            }
        }

        .candidate-profiles__tag-search .tag-search__category,
        .analytics__filter .tag-search__category
        {
            font-family: $font-gotham-medium
        }

        .candidates-profiles__filters-items {
            @include append__arrow-down(10px, $unactiveFilterColor);
            
            text-align: center;
            width: 110px;
            cursor: pointer;
            
            &.active {
                font-family: $font-family-main-bold;
                color: $activeFilterColor;
                @include append__arrow-down(10px, $activeFilterColor);
            }
            
            &.active.reverse {
                @include append__arrow-up(-10px, $activeFilterColor);
            }
        }

    .candidates-collaborators {
        display: flex;
        align-items: center;
    }
    .candidates-departments {
        .box__input-field {
            margin-bottom: 0px;
        }
    }
    .candidates-profiles__members {
        display: inline-flex;
        flex-direction: row-reverse;
        margin: 10px 0;
        .icon {
            margin-left: 20px;
            cursor: pointer;
            &:hover {
                opacity: .7;
            }
        }
    }

        .candidates-profiles__members-photo {
            @include size(38px);
            margin: 0;
            margin-right: -15px;
            + .candidates-profiles__members-photo {
                // border: 2px solid red;
            }

            border: 2px solid $white;
            border-radius: 100px;
        }

        .profile-field__header-tag {
            width: 50px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            text-align: center;
            align-items: center;
            padding-bottom: 0.5rem;
        }

        .profile-field--scheduled {
            display: inline-flex;
            align-items: center;
            }
            
            .profile-field--scheduled__icon-plane {
            position: relative;
            top: 2px;
            margin-right: 5px;
            }
            
            .profile-field--scheduled__icon-background {
            position: absolute;
            left: 17px;
            top: 10px;
            color: white;
            }
            
            .profile-field--scheduled__icon-clock {
            position: absolute;
            left: 17px;
            top: 10px;
            }
          