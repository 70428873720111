// settings for this page
$max-page-width: 1500px;
$career-font-size-section-header: $font-size-larger;
$career-font-size-section-text: $font-size-default;
$banner-height: 80px;
$mobile-break-size: 768px;

//////////////////////
//
//      GENERAL
//
//////////////////////

.career-section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 10px;

  &:nth-child(even) {
    background-color: $background-light-grey;
  }
}

.career-first-section {
  padding: 60px 0;
}

.career__banner-image {
  overflow: hidden;
  height: 400px;
  padding: 0;
  background-color: black;

  img {
    width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
}

.career__banner-video {
  overflow: hidden;
  padding: 0;
  background-color: black !important; // otherwise this is ruled out by 'nth-child' rule above
  video {
    max-height: 400px;
    max-width: 100%;
  }
}

.scroll-anchor {
  position: absolute;
  top: -70px;
  left: 0;
}

.career__title {
  font-family: $font-family-ternary-semi;
  font-size: 40px;
  margin-bottom: 30px;
  text-align: center;
}

.career__subsection {
  display: flex;
  align-items: center;
  justify-content: center;

  &.career__sn__links {
    margin-top: .5rem;
  }
}

.career__img {
  min-width: 360px;
  max-width: 540px;
  min-height: 240px;
  max-height: 360px;
  width: auto;
}

.career__video {
  min-width: 360px;
  max-width: 540px;
  min-height: 240px;
  max-height: 360px;
  width: auto;
}

.career-app__container {
  height: 100%;
  overflow: auto;

  .interview_hero_title, .career__title, .section-title, .section-title-2,
  .section-banner__detail, .section-content__link, .section-content__text
  {
    word-break: break-word;
  }
  
  &.container-fluid {
    // to counter the padding of .container-fluid
    padding: 0px;
    & > .row {
      margin-right: 0px;
      margin-left: 0px;
    }
  }

  .section-banner {
    padding: 0px;
  }

  .section-banner-overlay {
    z-index: 1;
    position: absolute;
  }

  .career__banner-video {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
  }

  .int-video {
    margin-top: 15px;
    @media screen and (min-width: $size-xl) {
        margin-bottom: 30px;
    }

    video {
        width: 100%;
        max-width: 500px;
    }
  }
}

//////////////////////
//
//   NAVIGATION BAR
//
//////////////////////

// #campaign-description::before {
//   display: block;
//   content: " ";
//   margin-top: -40px;
//   height: 40px;
//   visibility: hidden;
//   pointer-events: none;
// }

.career__nav {
  width: 100%;
  position: fixed;
  z-index: 10;
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
}

.nav__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: $max-page-width;
  padding: 0 20px;
}

  .nav__edges {
    width: 0;
  }

.nav-title {
  max-width: none;
  font-family: $font-family-main-medium;
  font-size: $font-size-medium;
  text-align: center;
}

.nav-logo {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    padding: 5px;
    display: block;
    max-height: $banner-height;
    max-width: 200px;
    width: auto;
    height: auto;
  }
}

.nav__no-logo {
  color: $grey-darkerer;
  font-family: $font-gotham-medium;
}

.nav-links {
  display: none;

  &.active {
    display: block;
  }
}

.nav-link {
  font-size: 1.125rem;
  font-family: $font-gotham-medium;
  letter-spacing: .6px;
  color: $black;
  cursor: pointer;
}

.nav-links li {
  text-align: center;
  margin: 15px auto;

  // for the language selector (angular-dropdowns puts it into a div with class 'wrap-dd-menu')
  .wrap-dd-menu {
    display: inline;
    text-transform: uppercase;

    .dropdown {
      width: auto;
    }
  }

}

.nav__lang {
  text-transform: uppercase;
  @include append__arrow-down(12px, $black);
}

.nav__candidate-area {
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-thickness: 2px;
}

.navbar-toggle {
  position: absolute;
  top: 26px;
  right: 30px;
  cursor: pointer;
  font-size: 28px;
  color: $black;
  line-height: 0px;
}

// Only applies to Desktop mode
@media screen and (min-width: $mobile-break-size) {
  
  .nav__container {
    flex-direction: row;
  }
  .nav-title {
    max-width: calc(100% - 450px);
    margin-bottom: 0px;
  }
  .nav-logo {
    justify-content: left;
    margin-left: 30px;
  }
  .nav-links {
    display: flex;
    margin-right: 30px;
    flex-direction: row;
    justify-content: flex-end;
  }
  .nav-links li {
    margin: 0;
  }
  .nav-link {
    margin-left: 30px;
  }
  .navbar-toggle {
    display: none;
  }
  .nav-link:hover {
    color: $grey-darkerer;
  }
}

//////////////////////
//
//      HEADER
//
//////////////////////

.career__header {
    position: relative;
    @include size(100% 600px);
    margin-top: $banner-height;
}

    .header__image {
        position: absolute;
        @include size(100% 100%);
        background-size: cover;
        background-position: center;
        background-image: url('/imgs/candidate-welcome-background.jpg'); // default background image, can be overwritten
    }

    .candidate_default-background {
      background-image: url('/imgs/candidate-area-banner-lg.jpg'); // default background image, can be overwritten
    }

    .header__video {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        @media (max-width: $screen-sm) {
            display: none;
        }
    }

    .header__overlay {
        position: absolute;
        @include size(100% 100%);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0,0,0,.3);
        overflow: hidden;
    }

.header__overlay {
  @include size(100% 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .3);
}

.header__title {
  color: white;
  text-shadow: 0 0 1px #000;
  font-size: 54px;
  font-family: $font-family-ternary-semi;
  padding: 10px;
  text-align: center;
  margin-bottom: 0px;
}

.header__cta {
  background-color: transparent;
  border: 2px solid;

  &:hover {
    background-color: transparent;
  }
}

.career_cta {
  width: fit-content;
  height: 55px;
  font-size: $font-size-default;
  padding: 1rem 2.5rem;
  margin-top: 1rem;
}

//////////////////////
//
//      JOB OFFERS
//
//////////////////////

.career__jobs {
}

@keyframes jobItemsShowMore {
  0% {
    overflow: hidden;
  }
  100% {
    overflow: visible;
  }
}

.jobs__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: $max-page-width;
  gap: 20px;
  margin-bottom: 20px;
  transition: max-height 0.5s ease-in-out;
}

.jobs__items-btn-show {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  font-size: $font-size-default;
  font-weight: 400;
  padding-bottom: 0 !important; // prevent disalignment when the show more is the last item of a spaced section
}

.jobs__items--show-more {
  animation: jobItemsShowMore 2s
}

.jobs__items--show-less {
  position: relative;
  max-height: 360px;
  overflow: hidden;
  
  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 50px;
    background: linear-gradient(180deg, rgba(255,255,255,0.0), rgba(255,255,255,1));
    bottom: 0;
  }
}

.section-spaced .jobs__map {
  padding-bottom: 0px;
  .setting__checkbox {
    padding: 5px 0;
    margin-bottom: 0px;
  }
}

.jobs__search {
  margin-bottom: 20px;
}

.search-centered {
  display: flex;
  justify-content: center;
}

.search__title {
  width: 300px;
  margin: 10px;
}

.search__city {
  margin: 10px;
}

.search__job-category {
  margin: 10px;
}

.search__number-of-hours-per-week {
  margin: 10px;
}

.search__contract-type {
  margin: 10px;
}

.search__home-working-policy {
  margin: 10px;
}

ng-dropdown-multiselect.search {
  &.search__city .multiselect-parent .dropdown-toggle {
    @include input-icon(url('/imgs/icons/icon-localization.png'));
  }

  &.search__job-category .multiselect-parent .dropdown-toggle {
    @include input-icon(url('/imgs/icons/icon-category.png'));
  }

  &.search__number-of-hours-per-week .multiselect-parent .dropdown-toggle {
    @include input-icon(url('/imgs/icons/icon-time@2x.png'));
  }

  &.search__contract-type .multiselect-parent .dropdown-toggle {
    @include input-icon(url('/imgs/icons/icon-written-question-grey.png'));
  }

  &.search__home-working-policy .multiselect-parent .dropdown-toggle {
    @include input-icon(url('/imgs/icons/icon-house.png'));
  }

  .multiselect-parent {
    .dropdown-toggle {
      width: 260px;
      padding: 20px;
      @include append__arrow-down(0, $black);
      background-color: $input-field-grey;
      background-color: $input-field-grey;
      color: $black;
      font-family: Gotham Book;
      font-size: $font-size-default;
    }
  }
}

.jobs__item {
  @include size(300px 150px);
  background-color: $brand-blue;

  color: white;
  border-radius: 5px;
  border: 1px solid #ececec;
  box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, .1);

  &:hover .job__learn-more {
    text-decoration: underline;
  }
}

.job__primary {
  height: 70%;
  padding: 20px;
  position: relative;
}

.job__position {
  font-size: 1.25rem;
  font-family: $font-gotham-medium;
  line-height: 1.4rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  overflow: hidden;
}

.job__city {
  position: absolute;
  bottom: 10px;
}

.job__secondary {
  height: 30%;
  background-color: white;
  color: #6f6f6f;
  padding: 14px;
  padding-left: 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .job-categories__container {
    .badge {
      height: 24px;
      max-width: 95px;

      span {
        width: 100%;
      }
    }
  }
}

.job__learn-more {
}

//////////////////////
//
//      ABOUT US
//
//////////////////////

.career__info {
}

.justify-content-center {
  justify-content: center;
}

.info__video {
  display: flex;
  justify-content: center;

  video {
    width: 100%;
    max-width: 600px;
    padding: 40px;

    &:focus {
      outline: none; // removes the blue glowy halo around the selection
    }
  }
}

.info__description {
  max-width: 700px;
  margin-bottom: 10px;
  text-align: justify;
}

.description__title {
}

.description__text {
  @include beehire-formatted-html($black);
}

//////////////////////
//
//      FIND US
//
//////////////////////

#findus__map {
  @include size(100% 400px);
}

//////////////////////
//
//      FOOTER
//
//////////////////////

.career__footer {
    @include size(100% 160px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: $font-size-default;
}

    .fixed-footer .career__footer {
      position: fixed;
      bottom: 0;
    }

    .footer__text {
        margin: 20px;
        text-align: center;
    }

.footer__logo {
  width: 130px;
  
  img {
    width: 100%;
  }
}
