.filter-prop:last-child {
  margin-right: 30px + 84px; // flex gap + actions col width

  @media screen and (max-width: 1400px) {
    margin-right: 20px + 84px; // flex gap + actions col width
  }
}

.crud-list__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  
  .list__top-search {
    margin-left: auto;
    .input-field.input-field--s {
      padding-left: 45px;
    }
  }
}

.crud-list__data-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  display: inline-block;
}

.crud-list__cards-container {
  width: fit-content;
  min-width: 100%;
  margin-top: 3px;
  display: flex;
  flex-wrap: wrap;
  gap: 3px
}

.crud-list__card {
  width: fit-content;
  min-width: 100%;
  background-color: #fff;
  padding: 20px;
  border: 1px solid rgba(226,229,232,.4);
  
  &:hover .crud-list__card__header .crud-list__card__actions.no-grow a.icon-circle {
    display: flex;
  }

  .item__no-prop {
    color: $warm-grey;
  }
}


.crud-list__card__header .crud-list__card__actions.no-grow {
  .crud-list__card__actions {
    a {
      display: none;
    }
    width: 84px;
    height: 32px;
  }
}

.crud-list__card__header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  height: 30px;
  gap: 30px;

  .items-card--expanded & {
    height: auto;
  }

  .input-field {
    margin-bottom: 0;
  }

  .no-grow {
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    gap: 20px;

    &.actions {
      justify-content: end;
    }
  }

  @media screen and (max-width: 1400px) {
    gap: 20px
  }
}

.crud-list__card__body {
  width: 100%;
  min-height: 50px;
  margin-top: 20px;

  .label--input {
    padding-left: 0;
  }
}

.crud-list__card__footer {
  flex: 1;
  display: flex;
  justify-content: flex-start;

  .btn {
    width: 250px;
  }
}
.crud-list__card__header + .ng-hide + .crud-list__card__footer {
  margin-top: 30px;
}

.crud-list__card__col {
  .badge {
    max-width: 70%;
    span {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .badge--inline {
    margin-left: 2px;
  }
}

.crud-list__card__dropdown {
  @extend .comp__filter;
  width: 100%;
}

.crud-list__title__col {
  flex: 1;
  min-width: 250px;
  max-height: 50px;
  overflow: hidden;
  .crud-list__data-label {
    white-space: normal;
    word-break: break-all;
  }
}

.crud-list__image__col {
  width: 45px;
  .circle_image {
    @include size(43px);
    object-fit: cover;
    margin: 0;
    margin-right: -15px;
    border: 2px solid $white;
    border-radius: 100px;
  }
}

.crud-list__matching__col {
  width: 90px;
}

.crud-list__toggle__col {
  width: 45px;
  flex: 0 0 45px;

  .crud-list__toggle {
    @include size(36px);
    padding: 10px;
    color: $warm-grey;
    margin-left: auto;
    margin-right: auto;
    
    &.crud-list__toggle--disabled {
      cursor: default;
    }

    &.crud-list__toggle--true {
      background-color: #28bd83;
      color: #fff;
    }
  }
}

.crud-list__date__col {
  width: 130px;
  flex: 0 0 130px;

  .crud-list__datepicker {
    height: auto;
    .input-field {
      min-height: 25px;
      padding: 20px 5px;
      background-image: none;
    }
  }
}

.crud-list__datetime__col {
  width: 200px;
  flex: 0 0 200px;

  .items-card--expanded & {
    display: flex;
    gap: 2px;
  }

  .crud-list__datepicker {
    height: auto;
    min-width: 120px;
    .input-field {
      min-height: 25px;
      padding: 20px 5px;
      background-image: none;
    }
  }
  .crud-list__timepicker {
    height: auto;
    min-width: 80px;
    min-height: 25px;
    padding: 20px 5px;
    background-image: none;
  }
}

.crud-list__collaborators__col {
  width: 105px;
  flex: 0 0 105px;
}

.crud-list__stars__col {
  width: 110px;
  flex: 0 0 110px;
}

.crud-list__card__actions {
  justify-content: end;
  a {
    display: none;
  }
  width: 84px;
  flex: 0 0 84px;
  height: 32px;
}