tags-input.bootstrap-badges_input_design {
  max-width: 700px;
  
  .tags {
      display: flex;
      align-items: center;
      min-height: 60px;
      width: 100%;
      outline: none;
      border: none;
      box-shadow: none;

      .tag-item {
          @include tag();
          padding: 5px 10px 5px 15px;
          line-height: 18px;

          .remove-button {
          color: #fff;
          margin: 0px 0px 0px 4px;
          }
      }

      .focused {
          border: none;
          outline: none;
          box-shadow: none;
      }
  }

  .focused {
      border: none;
      outline: none;
      box-shadow: none;
  }
}

tags-input.tags-input {
  .tags {
      .tag-item {
          background-color: $tag-background-color;
          color: #fff;
      }
  }
}

tags-input.job-categories-input {
  .tags {
      .tag-item {
          background-color: $job-category-color-1;
          color: #fff;
      }
  }
}