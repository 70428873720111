.vonq-cart {
  .vonq-cart__cart-item {
    display: flex;
    align-items: center;
    span {
      margin-left: 5px;
    }
  };

  table {
    width: 100%;

    td {
      padding: 5px;
      &:first-child {
        padding-left: 0px;
      }
      &:last-child {
        padding-right: 0px;
      }
    }

    .title_cell {
      .tooltip__container {
        left: -450px;
      }

      .badges-row {
        margin: 2px 0px;
      }
    }

    .duration_cell {
      width: 100px;
      text-align: right;
    }

    .price_cell {
      width: 60px;
      text-align: right;
    }

    .action_cell {
      width: 35px;
      text-align: right;
    }
  }
  
  .vonq-cart__cart-total {
    margin-top: 10px;
  }

  .vonq-cart__no-content {
    font-size: $font-size-small;
    color: $warm-grey;
  }
}