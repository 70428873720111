.interview__next {
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 0;
}

.interview__skip {
    margin-top: 15px;
    text-decoration: underline;
    color: $grey-darker;
    text-align: center;
}

.btn--next {
    font-family: $font-family-main-bold;
    margin-bottom: 15px;
}

.interview-centering {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center
}