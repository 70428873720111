.overflow-hidden {
    overflow: hidden;
}

.scrollable {
    overflow: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.scrollable-h {
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}