* {
  box-sizing: border-box;
}

@media print {
  body {
    visibility: hidden;
  }
}

a {
  font-family: $font-family-main-book;
  color: inherit;
  text-decoration: none;
  cursor: pointer; //todo: zasto ne radi??
  transition: all 0.1s ease-in-out;
  &:hover {
    cursor: pointer; //fix: sada radi
  }
  &.disabled:hover {
    cursor: default;
  }
  &[href^="tel"] {
    color: inherit;
    text-decoration: none;
    font-family: inherit;
  }
  cursor: pointer;
}

button {
  cursor: pointer;
}

section {
  // padding: 3.125rem 0;
}

p, ul {
  font-family: $font-family-main-book;
  font-size: $font-size-small;
  line-height: 1.8;
  color: $black;
  // padding-bottom: 15px;
  b {
    font-weight: unset;
    font-family: $font-family-main-bold;
    color: $black;
  }
}

p + p {
  padding-top: 2rem;
}

p + .row {
  margin-top: 3.125rem;
}


.row {
  @media screen and (min-width: 1440px) {
    /* adding wide responsive breakpoints */
    .col-wd-2 {
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .col-wd-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-wd-4 {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .col-wd-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-wd-7 {
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .col-wd-8 {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
  }
  @media screen and (min-width: 1600px) {
    /* adding extra wide responsive breakpoints */
    .col-xw-2 {
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .col-xw-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-xw-4 {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .col-xw-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-xw-7 {
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .col-xw-8 {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
  }
}


b {
  color: $brand-color-2;
  font-weight: bold;
}

html,
body {
  height: 100%;
  // min-height: 700px;
  font-family: $font-family-main-book;
  // font-size: 0.8em;
  // zoom: .9;

  -webkit-text-rendering: optimizeLegibility;
  -moz-text-rendering: optimizeLegibility;
  -ms-text-rendering: optimizeLegibility;
  -o-text-rendering: optimizeLegibility;
  text-rendering: optimizeLegibility;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased;

  -moz-osx-font-smoothing: grayscale;

  // @media (min-height: $screen-height-m) {
  //   font-size: 1em;
  // }

  color: $black;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  // overflow: hidden;
}

body {
  // overflow-y: scroll;
  // -webkit-overflow-scrolling:touch;
}

ul {
  list-style: none;
}

img {
  //@include size(100% auto);
}

input,
textarea {
  -webkit-appearance: none;
  outline: none;
  border: none;
  &:hover {
    cursor: text;
  }
  &[type="submit"] {
    &:hover {
      cursor: pointer;
    }
  }
}

input[type=checkbox], input[type=radio] {
  cursor: default;
}

iframe {
}
