.submission-printout {
  background: $background-light-grey;
  //width: 1280px;
  width: 100%;

  display: none;

  /*height: 768px;
  overflow-y: scroll;
  z-index: 10000;
  position: absolute;
  left: 0;
  top: 0;*/
  //overflow: visible !important;
}

article.submission-printout {
  display: none;
}

@media print {
    // html,body{
    //     height:297mm !important;
    //     width:210mm !important;
    // }
    // @page {
    //     size: 210mm 297mm; /* landscape */
    //     /* you can also specify margins here: */
    //     margin: 25mm;
    //     margin-right: 45mm; /* for compatibility with both A4 and Letter */
    // }

    .app-content, header, footer {
        display: none;
    }
  .submission-printout {
    display: block !important;
    visibility: visible !important;
    z-index: 10000;
    position: absolute;
    left: 0;
    top: 0;
    overflow: visible !important;
    width: 100%;
    min-width: 100% !important;
    // min-width: 100% !important;
    // max-width: 100vw !important;
    //height: 100% !important;
  }

  u {
      text-decoration: underline;
  }

  // Alignments
  .ql-align-center {
      text-align: center;
  }
  .ql-align-left {
      text-align: left;
  }
  .ql-align-right {
      text-align: right;
  }
  .ql-align-justify {
      text-align: justify;
  } 

  // Font sizes
  .ql-size-small {
      font-size: .75em;
  }
  .ql-size-large {
      font-size: 1.5em;
  }
  .ql-size-huge {
      font-size: 2.5em;
  }


  // Lists
  ol, ul {
    padding-left: 20px;
    margin: 1rem 0;
  }

  ol li, ul li {
    position: relative;
  }

  ol {
    list-style-type: decimal;
    li.ql-indent-1 {
      margin-left: 1em;
    }
    li.ql-indent-2 {
      margin-left: 2em;
    }
    li.ql-indent-3 {
      margin-left: 3em;
    }
    li.ql-indent-4 {
      margin-left: 4em;
    }
    li.ql-indent-5 {
      margin-left: 5em;
    }
    li.ql-indent-6 {
      margin-left: 6em;
    }
    li.ql-indent-7 {
      margin-left: 7em;
    }
    li.ql-indent-8 {
      margin-left: 8em;
    }
  }

  ul {
    list-style-type: none;
    li.ql-indent-1 {
      margin-left: 1em;
      &::before {
        content: '•';
        position: absolute;
        left: -1em;
        color: black;
      }
    }
    li.ql-indent-2 {
      margin-left: 2em;
      &::before {
        content: '◦';
        position: absolute;
        left: -1em;
        color: black;
      }
    }
    li.ql-indent-3 {
      margin-left: 3em;
      &::before {
        content: '▪';
        position: absolute;
        left: -1em;
        color: black;
      }
    }
    li.ql-indent-4 {
      margin-left: 4em;
      &::before {
        content: '•';
        position: absolute;
        left: -1em;
        color: black;
      }
    }
    li.ql-indent-5 {
      margin-left: 5em;
      &::before {
        content: '◦';
        position: absolute;
        left: -1em;
        color: black;
      }
    }
    li.ql-indent-6 {
      margin-left: 6em;
      &::before {
        content: '▪';
        position: absolute;
        left: -1em;
        color: black;
      }
    }
    li.ql-indent-7 {
      margin-left: 7em;
      &::before {
        content: '•';
        position: absolute;
        left: -1em;
        color: black;
      }
    }
    li.ql-indent-8 {
      margin-left: 8em;
      &::before {
        content: '◦';
        position: absolute;
        left: -1em;
        color: black;
      }
    }
    li {
      &::before {
        content: '•';
        position: absolute;
        left: -1em;
        color: black;
      }
    }
  }
  
  // Force background colors to print
  span[style*="background-color"] {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
}

}

  .submission-printout__header {
    text-align: center;
    background: white;
    height: 262px;
    padding-top: 62px;
    position: relative;
    margin-bottom: 37px;
    border-bottom: 1px solid $background-light-grey;
  }

    .header__photo {
      @include size(80px 80px);
      border-radius: 100%;
      margin-bottom: 12px;
    }

    .header__profile-info {

    }

      .profile-info__name {
        font-size: $font-size-medium;
        margin-bottom: 7px;
      }

      .profile-info__address {
        font-family: $font-family-main-medium;
        font-size: 10px;
      }

    .header__rating-container {
      position: absolute;
      width: 100%;
      text-align: center;
      bottom: -37px;
    }


      .profile-info__rating {
        -webkit-print-color-adjust: exact;
        margin: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        @include size(92px 71px);
        background: url('/imgs/profile-rating@2x.png') center center / contain no-repeat;
        color: $white;
        font-size: $font-size-medium;
        font-weight: bold;
      }

  .submission-printout__section {
    //padding: 60px 210px 0 210px;
    padding: 60px 0;
    h1 {
        font-weight: normal;
    }

    .submission-notes__comments-comment {
      margin-left: 60px;
    }
  }

    .section--center-items {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

.submission-assessment__questions-container {
  padding: 30px;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 3px;
  margin-bottom: 10px;
}

.submission-assessment__questions {
  margin-bottom: 40px;
}

.submission-assessment__answers {
  margin-top: 40px;
}

.submission-assessment {
  margin-bottom: 80px;
  border-top: 10px solid $brand-color-1;
}

.submission-assessment__details {
  margin-bottom: 40px;
}

.submission-assessment__details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 15px;
  padding: 15px;
  border: 1px solid #e9e9e9;
  border-radius: 3px;
}

.submission-assessment__details .detail {
  display: flex;
  align-items: center;
}

.submission-assessment__details .detail strong {
  width: 150px;
  color: #333;
}

.submission-assessment__details .detail span {
  color: #555;
}