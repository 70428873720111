.login-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

  .login {
      @include size(100% 100%);
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }

  .login--unauthorized {
    a {
        @extend .login__hyperlink
    }
  }
  
  .login--signout {
    margin-top: 20px;
  }

  .login__box {
      @include size(550px 430px);
      display: flex;
      justify-content: center;
      border-radius: 30px;
      box-shadow: 0 7px 54.5px 0 rgba(0, 0, 0, 0.18);
      background-color: #fff;
  }

      .login__box--signup {
          height: 640px;
      }

      .login__box--forgot {
          @include size(500px auto);
      }

          .login__image, .login__form {
              width: 70%;
              height: 100%;
          }

          .login__image {
              border-radius: 3px 0 0 3px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: flex-end;
              padding: 50px 0 77px;
          }

          .login__form {
              display: flex;
              align-items: center;
              flex-direction: column;
              justify-content: space-between;
              padding: 50px 0px;
              width: 385px;
          }

              .login__form--forgot {
                  width: 100%;
                  padding: 60px 85px;
                  .label {
                      margin-bottom: 15px;
                  }
              }

          .login__signup {
              top: 80%;
              padding: 1.3rem 4.375rem;
              background-color: $brand-color-3;
              border-radius: 3px;
              width: 100%;
              display: block;
              color: $white;
              font-family: $font-family-main-medium;
              text-align: center;
              transition: all 0.3s ease-in-out;
              &:hover {
                  background-color: rgba(0,0,0,.5);
              }
          }

          .login__forgot-password {
          	font-size: $font-size-small;
          	color: #9a9999;
              text-decoration: underline;
              transition: all 0.3s ease-in-out;
              &:hover {
                  color: $black;
              }
          }

  .login__hyperlink {
    color: blue;
    text-decoration: underline;
    font-weight: bold;
    &:hover {
      color: $black;
    }
  }
