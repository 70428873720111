/*
---
name: Question Fields - create
category: fields/question
tag:
  - Fields
---

```jade
.question
    dd.question__num Q.1
    dt.question__title Please introduce yourself.
.question.question--active.question--form
    dd.question__num Q.2
    .question__input
        textarea.input-field.input-field--textarea.input-field--white.input-field--xl(placeholder='Question...')

        .question__input-row
            .question__input-field
                label.label.label--input.label--white.label--thin Preparation Time
                input.input-field.input-field--minutes(type='number', pattern="\d{1,5}", value='3', maxlength='2', min='1', max='15')
            .question__help.icon.icon--help-dark

            .question__input-field
                label.label.label--input.label--white.label--thin Answer Time
                input.input-field.input-field--minutes(type='number', pattern="\d{1,5}", value='3', maxlength='2', min='1', max='15')
            .question__help.icon.icon--help-dark

            .question__input-cta
                a.btn.btn--transparent Done
                a.question__delete Delete

```
*/

.question-label {
    margin-bottom: 20px;
}

.question-label--text {
    margin-top: 40px;
}

.question {
    display: flex;
    align-items: center;
    @include size(100% auto);
    min-height: 50px;
    border-radius: 3px;
    background-color: #fff;
    margin-bottom: 6px;
    padding: 10px 30px;
    color: $black;
    font-size: $font-size-small;
    cursor: pointer;
}

    .question__separator {
        @include size(100% 0px);
        border-top: 1px dashed #bcc7cf;
        margin-bottom: 6px;
    }

    .question--active {

        // background-color: $charcoal-grey !important;
        align-items: flex-start;
        flex-direction: column;
        cursor: default;
        .question__heading {
            justify-content: space-between;
            .dropdown {
                margin-left: -20px;
            }
        }
    }

    .question--form {
        padding: 15px 30px 0;
        textarea, input, .dropdown--questions {
            background-color: #f7f7f7;
        }
        .input-field--multi {
            background-color: transparent;
        }
    }

        .question__heading {
            max-height: 200px;
            // overflow-y: hidden; // removing this line because it made the dropdown of questions types hidden
            display: flex;
            align-items: center;
            width: 100%;
            .icon--move {
                margin-right: 0;
            }
        }

            .question__num-container {
                display: flex;
                align-items: center;
            }

                .question__num {
                    font-family: $font-family-main-bold;
                    margin-right: 25px;
                    line-height: 1;
                }

                .question__title {
                    // letter-spacing: 0.2px;
                    line-height: 1.4;
                    flex: 1;
                    padding-right: 20px;
                    font-family: $font-family-main-medium;
                }

                .question__match-importance {
                    text-align: right;
                }

            .question__video-thumb {
              position: relative;
              @include size(auto 36px);
              border-radius: 3px;
              overflow: hidden;
              margin-right: 1.25rem;
              img {
                @include size(auto 100%);
              }
              span {
                position: absolute;
                @include size(100%);
                top: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                font-family: $font-family-main-bold;
                font-size: $font-size-small;
                background-color: rgba(0,0,0,0.2);
              }
            }

        .question__input {
            display: flex;
            flex: 1;
            flex-direction: column;
            // justify-content: space-between;
            @include size(100%);
            // padding: 20px;
            //margin-top: 20px;

            .input-field--textarea {
                resize: none;
                color: $black;
                font-size: $font-size-small;
                padding: 1.125rem 0 0 .8125rem;
                margin-bottom: 0;
            }
        }


            .question__input-row {
                display: flex;
                flex: 1;
                justify-content: space-between;
                align-items: flex-start;
                min-height: 70px;
                margin-top: 10px;

                // .input-field--textarea {

                // }

                .input-field--checkbox-container {
                    margin: 0;
                }

                .btn--plus {
                    height: 52px;
                    // align-self: flex-start;
                    margin-top: 1px;
                }
  
            }   
            .question__input-row--video {
                flex-direction: column;
              .input-field {
                height: 5rem;
                margin-bottom: 1rem;
              }
            }
                .question__input-video-container {
                    margin: 0 auto;
                }
            .question__input-row--video .input-field {
                width: 60%; 
                margin-right: auto;
                margin-left: auto;
            }
            .question__input-row--video textarea.input-field--textarea {
                width: 100%;
                // min-height: 100px; If you want to adjust textarea's height
            }

            .question__input-row.question__input-row--video .add-video--question {
                margin-left:0px;
            }

            .question__input-row-title{
                color:black;
                
                margin-bottom: 16px;
                margin-top: 16px;
                display: flex;
                align-items: center;
                font-weight:bold;
                font-size:0.8rem;
                .icon {
                    display: block;
                    margin-left : 1.3rem;
                    font-size: 0.8rem;
                }
            }
            .question__input-row-preparation-answer {
                margin-top: 20px;
                
                //justify-content: flex-start; 
                //justify-content: center;
            }

            .question__input-field-container-answer-time{
                display:flex;
                flex-direction: column;
            }

            .question__input-row--details {
                details {
                    background-color: $white-three;
                    padding: 1.125rem .8125rem;
                    border-radius: 3px;
                    width: 100%;
                    summary>h4 {
                        display: inline-block;
                    }
                }
            }
            .question__input-row--image {
                display: flex;
                justify-content: center;
                media-upload {
                    margin-top: 20px;
                    width: 50%;
                }
            }

            .question__input-row--no-min {
                min-height: 0;
            }

                .question__input-field-container {
                    display: flex;
                    align-items: center;
                    + .question__input-field-container {
                        margin-left: 15px;
                    }
                    .tooltips {
                        margin: 25px 0 0 10px;
                    }
                }

                .question__input-field {
                    width: 115px;
                    color: $black;
                    .input-field, .label {
                        margin: 0;
                    }
                    .label {
                        margin-bottom: 10px;
                        padding-left: 0;
                    }
                }

                .question__help {
                    cursor: pointer;
                    margin-top: 5px;
                    &:hover {
                        opacity: .5;
                    }
                }

                .question__input-cta {
                    display: flex;
                    // flex-direction: column;
                    // justify-content: center;
                    align-items: center;
                    flex: 1;
                }
                
                .question__error-message {
                    margin: 20px;
                    p {
                        color: $warning-color;
                        font-weight: bold;
                    }
                }

                    .question__input-cta--where-label {
                        margin-top: 24px;
                    }

                        .question__done {
                            padding: 16px 50px 14px;
                            font-family: $font-family-main-bold;
                            font-size: $font-size-small;
                            margin-right: -40px;
                            margin-left: 50%;
                            transform: translateX(-50%);
                        }

                        .question__delete {
                            color: #999999;
                            font-size: $font-size-small;
                            text-decoration: underline;
                            &:hover {
                                color: #F62D73;
                            }
                        }


/*
---
name: Question Fields - review
category: fields/question
tag:
  - Fields
---

```jade
.question.question--starred
    dd.question__num Question 1
    dt.question__title Please introduce yourself.

.question.question--active.question--rate
    dd.question__num Question 2
    dt.question__title Why are you applying for this position?

    .question__stars
        .icon.icon--star-border
        .icon.icon--star-border
        .icon.icon--star-border
        .icon.icon--star-border
        .icon.icon--star-border

.question
    dd.question__num Question 3
    dt.question__title Why are you interested in our company?

```
*/

.question--submission {
    flex-direction: column;
    color: $white;
    background-color: $cloudy-blue;

    &.question--submission-assessment {
        background-color: #eee;
        color: $black;
    }

    .question__input-row {
        min-height: 30px;
        width: 100%;
        justify-content: flex-start;
        margin-top: 0;
    }
}

// .question--rate {
//     min-height: 90px;
//     align-items: flex-start;
// }

.question--submission-active {
    background-color: $charcoal-grey;
    // padding: 25px 30px;
    min-height: 110px;

    &.question--submission-active-assessment {
        background-color: #ddd;
        border: 1px solid $pinkish-grey;
    }
}

.question--skipped {
    margin-left: auto;
    margin-right: auto;
    font-family: $font-family-main-bold;
}

.question--starred {
    background-color: $brand-color-2;
}

.question__stars {
    display: flex;
    margin: 0 auto;
    .icon {
        cursor: pointer;
        margin: 0 1px;
    }

    &.question__stars--assessment {
        background-color: $pinkish-grey;
        padding: 5px 10px 8px;
        border-radius: 3px;
    }
}

.question__match-score {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 auto;
    color: $white;

    &.question__match-score--assessment {
        color: $black;
    }
}

.question-field__image {
    img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: auto;
        height: auto;
        max-width: 100%;
        object-fit: scale-down;
        cursor: zoom-in
    }
}
.question-field__image--modal {
    position: fixed;
    display: flex;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.9);
    cursor: zoom-out;

    img.question-field__image--modal-content{
        margin: auto;
        display: block;
        max-width: 80%;
        cursor: zoom-out
    }
}

.question__input-field .label {
    text-align: center;
}

.question__input-flex-container{
    display: flex;
    flex-direction: column;
}

.question__input-field-container-answer-time {
    display: flex;
    align-items: center;
    width: 300px;
}

.question__input-field-answer {
    width: 300px;
    color: $black;
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    .input-field, .label {
        margin: 0;
    }
    .label {
        margin-bottom: 10px;
        padding-left: 0;
        width:500px;
    }
}