$checkbox-size: 15px;

.question-matching {

    // .rz-bubble {
    //     color: $white;
    // }
    
    &.dropdown--questions {
        margin-left: 20px;
        display: flex; 
        flex-direction: row;
        align-items: center;

        &.dropdown--questions--assessment {
            margin-left: 0px;
            margin-top: 10px;
            margin-bottom: 20px;
            padding: 1.125rem .8125rem;
        }
    }

    .icon {
        margin-left: 10px;
        margin-right: 10px;
    }
}

.answer-matching {
    display: flex;
    align-items: center;
    width: 300px;

    .sliders {
        display: flex;
        align-items: center;
    }

    .eliminatory {
        display: flex;
        align-items: center;
        margin-left: 20px;
    
        .input-field--checkbox {
            margin-right: 6px;
            min-height: 0px;
            min-width: 0px;
            width: $checkbox-size;
            height: $checkbox-size;
            &:checked {
                background-image: url('/imgs/icons/icon-checked-bold-lightblue@2x.png');
            }
        }
    }

    .icon {
        margin-left: 10px;
        margin-right: 10px;
    }
}
