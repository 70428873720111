.departments {
    display: flex;
    flex-direction: column;
    flex: 2;

    .department__header__name {
        flex: 1 200px;
        min-width: 200px;
    }
    .department__header__campaigns {
        width: 250px;
        flex: 1 250px;
        .badge {
            max-width: 60%;
        }
    }

    .list-filter {
        .department__header__badge:last-child {
            margin-right: 118px;
        }
    }
}

    .departments__container {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: space-between;

        crud-list {
            width: 100%
        }
    }

        .departments__members {
            flex: 1;
        }
