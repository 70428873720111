

.comp__filter {
    display: flex;

    &:before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
    }
    &:before {
        margin-right: 10px;
    }
    .dropdown-toggle .caret {
        @include append__arrow-down(0, $charcoal-grey);
        &:after {
            top: 14px;
        }
    }
    & + & {
        margin-left: 5rem;
    }
    &.comp__filter--selected {
        .dropdown-toggle {
            font-family: $font-family-main-medium;
        }
    }
}

.filter__datepicker {
    @include append__arrow-down(0, $charcoal-grey);
    cursor: pointer;
    &:before {
        // calendar icon
        content: "\f073"; 
    }
    &:after {
        top: 5px;
    }
    span {
        text-overflow: ellipsis;
        max-width: 150px;
        overflow: hidden;
        white-space: nowrap;
    }
}

.filter__campaign {
    &:before {
        // filter icon
        content: "\f0b0"; 
    }
}

.filter__source {
    &:before {
        // filter icon
        content: "\f0f2"; 
    }
}

.filter__tags {
    &:before {
        //filter icon
        content: "\f02c";
    }
}

.filter__creators {
    &:before {
        //filter icon
        content: "\f007";
    }
}

.filter__video {
    &:before {
        // filter icon
        content: "\f03d";
    }
}

.filter__export {
    &:before {
        // export icon
        content: "\f56e";
    }
}