.vote-campaign-validation-request {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 20px;
  
  i {
    margin-right: 10px;
    font-size: 24px;
  }

  &.approved {
    color: #28a745;

    i {
      color: #28a745;
    }
  }

  &.rejected {
    color: #dc3545;

    i {
      color: #dc3545;
    }
  }
}
