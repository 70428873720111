.interview-questions {
}

.question__timer {
  @media screen and (min-width: $screen-sm-min) {
    float: right;
    margin-right: 40px;
    transform: translate(0px, -16px);
  }
  @media screen and (max-width: $screen-sm-min) {
    margin-top: 10px;
    text-align: center;
  }
  span + span {
    margin-left: 10px
  }
  .timer-count {
    display: inline-block;
    text-align: right;
    width: 50px;
  }
}

.question-field {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .question-field__text {
    background-color: #eeeeee;
    color: $black;
    border-radius: 3px;
    padding: 28px 25px;
  }
  .question-field__info {
    background-color: #DBDBDB;
    color: black;
  }

  .question-field__word-limit-text {
    padding: 10px 0 5px 0;
  }

  .question-field__word-limit-count {
    display: inline-flex;
    justify-content:flex-end;
    align-items:center;
    margin: 0;
    color: #666666;
    &.question-field__word-limit-count-exceed {

      color: red;
   }
  }

  .question-field__input {
    border-radius: 3px;
    background-color: white;
    padding: 18px 25px;
    border: 1px solid $black;
    li:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .question-field__input-text {
    min-height: 128px;
  }

  .question-field__input-checkbox, .question-field__input-radio {
    display: flex;
    gap: 14px;
    align-items: center;

    input {
      @include size(16px);
      min-width: 16px;
      min-height: 16px;
      border: 2px solid black;
    }

    span {
      font-size: $font-size-default;
    }
  }

  .question-field__input-checkbox input {
    border-radius: 2px;
    &:checked {
      background-image: url('/imgs/icons/icon-checked-bold@2x.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 14px 10px;
    }
  }

  .question-field__input-radio input {
    border-radius: 50%;

    &:checked {
      background-image: url('/imgs/icons/icon-checked-round@2x.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 10px;
    }
  }
}

.question-field__flex {
  display: flex;
}

.info-icon {
  margin-right: 10px;
  font-size: 20px;
}

.question-field__text-unset {
  font-size: unset;
  line-height: unset;
}

.question-field__input-one-to-ten {
  display: flex;
  align-items: center;

  input.input-field.input-field--narrow.input-field--white-border {
    margin: 0;
  }
  
  span {
    margin-left: 20px;
  }
}