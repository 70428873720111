.app-wrapper {
  display: flex;
  position: fixed;
  flex-direction: column;
  height: 100%;
  // min-width: 960px;
  width: 100%;
  // zoom: 0.6;
  top: 0;
  left: 0;
  overflow: hidden;
  //overflow-y: scroll;
  // @media (min-height: $screen-height-m) {
  //   width: 125vw;
  //   zoom: 0.8;
  // }
  // @media (min-height: $screen-height-l) {
  //   width: 100vw;
  //   zoom: 1;
  // }
}

// Notification banner
.notification-banner {
  padding: 6px 15px;
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.notification-banner__row {
  width: 100%;
}

#recruiter-banner .notification-banner p,
#candidate-banner .notification-banner p {
  color: inherit;
}

.notification-banner__row#candidate-banner,
#recruiter-banner {
  display: none;
}

.notification-banner#candidate-banner {
  min-height: 50px;
}

.notification-nav-wrapper {
  left: 0;
  right: 0;
  top: 0;
  position: fixed;
  z-index: 1000;
}

.notification-nav-wrapper .notification-banner__row,
.notification-nav-wrapper .career__nav {
  position: relative;
  width: 100%;
}

@media print {
  .app-wrapper {
    position: absolute !important;
  }
}

header {
  z-index: 2000; // header dropdown over app-content
}

.app-content {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: row;
  min-height: 0;
  background-color: $background-light-grey;
  z-index: 1000; // hide .spinner behind loading page spinner
  // background: #000;
  // padding: 60px $content-padding;
  // overflow: hidden;
}

.app-content__black {
  flex: 1;
  background-color: #111;
  display: flex;
}

.app-content__container {
  padding: 45px $content-padding;
  flex: 1;
}

// .app-content__container--center {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     align-content: center;
// }

.app-content__container--submission {
  padding: 40px $content-padding 20px;
  flex: 1;
  h1 {
    font-family: $font-family-main-book;
    font-size: $font-size-medium;
  }
}

.cards-container {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  margin-right: -50px;
  margin-top: 16px;

  .cards-container__empty-message {
    color: $brand-color-3-light;
    margin: auto;
    margin-top: 10%;
    font-family: $font-gotham-medium;
  }
}

.scrolling-spacer {
  flex: 1;
  width: 100%;
  min-height: 100px;
  // border: 1px solid red;
}

.no-grow {
  flex: 0;
}