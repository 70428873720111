datepicker a, [datepicker] a, .datepicker a{
  color:inherit;
  text-decoration:none;
}
datepicker a:hover, [datepicker] a:hover, .datepicker a:hover{
  text-decoration:none;
}
datepicker select, datepicker select:focus, datepicker select:hover,
.datepicker select, .datepicker select:focus, .datepicker select:hover,
[datepicker] select, [datepicker] select:focus, [datepicker] select:hover{
    width:100%;
    overflow: hidden;
    background:none;
    color:#fff;
    background-color: #138EFA;
    border-radius:2px;
    border: 0;
    margin-top:5px;
}


datepicker, .datepicker, [datepicker],
._720kb-datepicker-calendar-header,
._720kb-datepicker-calendar-body,
._720kb-datepicker-calendar-days-header,
._720kb-datepicker-calendar-years-pagination-pages {
  //font-family: $font-family-main-regular;
  //font-size: $font-size-small;
  box-sizing: border-box;
  width: 100%;
  float: left;
  clear: right;
  position: relative;
}

._720kb-datepicker-calendar {
  background: white;
  color: #333;
  position: absolute;
  z-index: 999;
  min-width: 220px;
  margin: 0 auto;
  width: 101%;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  visibility: hidden;
  overflow:hidden;
  margin-left:-0.5%;
  padding: 0 0 2% 0;
  border-radius: 3px;
  a {
    transition: none;
  }
}
._720kb-datepicker-calendar._720kb-datepicker-open,._720kb-datepicker-calendar._720kb-datepicker-forced-to-open {
  visibility: visible;
}
._720kb-datepicker-calendar-header {
    text-align: center;
    font-size: 15px;
    line-height: 40px;
    &:nth-child(odd) {
        //background: $brand-color-1;
    }
    &:nth-child(even) {
        background: #edf6fe;
    }
}

._720kb-datepicker-calendar-header-left,
._720kb-datepicker-calendar-header-middle,
._720kb-datepicker-calendar-header-right {
  width: 15%;
  float: left;
}
._720kb-datepicker-calendar-header-middle {
  width: 70%;
  a {
      display: none; // hide year select
  }
}

._720kb-datepicker-calendar-header-closed-pagination::after {
  content: " \25BE";
}

._720kb-datepicker-calendar-header-opened-pagination::after {
  content: " \25BE";
  margin-left: 4px;
  position: relative;
  bottom: -3px;
  display:inline-block;
  transform: rotate(180deg);
}
._720kb-datepicker-calendar-body {
  width: 96%;
  margin: 2%;
  text-align: center;
}
._720kb-datepicker-calendar-day {
  cursor: pointer;
  font-size: 12.5px;
  width: 14.18%;
  margin: 5px 0;
  padding: 1.5% 0;
  float: left;
  border-radius: 1px;
}
._720kb-datepicker-calendar-day:hover,._720kb-datepicker-calendar-day._720kb-datepicker-active {
  background: rgba(0, 0, 0, 0.03);
}
._720kb-datepicker-calendar-header a, ._720kb-datepicker-calendar-header a:hover {
  text-decoration:none;
  padding:3% 9% 4% 9%;
  font-size: 13.5px;
  color:rgba(0, 0, 0, 0.55);
  font-weight: bold;
  border-radius: 3px;
}
._720kb-datepicker-calendar-header a:hover {
  color:rgba(0, 0, 0, 0.9);
  background: rgba(255, 255, 255, 0.45);
}
// ._720kb-datepicker-calendar-month {
//   color:#fff;
// }
._720kb-datepicker-calendar-month span {
  //font-size: $font-size-smaller;
  color:rgba(0, 0, 0, 0.6);
}
._720kb-datepicker-calendar-month a span  i {
  font-style: normal;
  //font-size: $font-size-default;
}
._720kb-datepicker-calendar-month a, ._720kb-datepicker-calendar-month a:hover {
  padding: 3px;
  margin-left:1%;
}
._720kb-datepicker-calendar-years-pagination{
  padding:2% 0 0 0;
  float:left;
  clear: right;
  width: 100%;
}
._720kb-datepicker-calendar-years-pagination a, ._720kb-datepicker-calendar-years-pagination a:hover {
  font-size:12px;
  padding:0 7px;
  font-weight: normal;
  margin:3px 1% 0 1%;
  line-height: 20px;
  display: inline-block;
}
._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-active {
  color:rgba(0, 0, 0, 0.9);
  font-weight: 500;
  background: rgba(255, 255, 255, 0.45);
}
._720kb-datepicker-calendar-years-pagination-pages a,._720kb-datepicker-calendar-years-pagination-pages a:hover{
  padding:5px 10px;
}
._720kb-datepicker-calendar-days-header{
  max-width: 100%;
  margin:0 auto;
  padding:0 2% 0 2%;
  background: rgba(19, 142, 250, 0.08);
  border-bottom:1px solid rgba(0,0,0,0.02);
}
._720kb-datepicker-calendar-days-header div{
  width: 14.18%;
  font-weight: 500;
  font-size: $font-size-smaller;
  padding:10px 0;
  float:left;
  text-align: center;
  color:rgba(0,0,0,0.7);
}
._720kb-datepicker-calendar-days
._720kb-datepicker-default-button{
  font-size: 18.5px;
  position: relative;
  bottom:-0.5px;
}
._720kb-datepicker-default-button{
  padding:0 4.5px;
}
._720kb-datepicker-calendar-header-middle._720kb-datepicker-mobile-item{
  width:95%;
  float:none;
  margin:0 auto;
}
._720kb-datepicker-item-hidden{
  visibility:hidden;
}

._720kb-datepicker-calendar-day._720kb-datepicker-disabled,
._720kb-datepicker-calendar-day._720kb-datepicker-disabled:hover,
._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-disabled,
._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-disabled:hover,
._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-active._720kb-datepicker-disabled,
._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-active._720kb-datepicker-disabled:hover{
  color:rgba(0,0,0,0.2);
  background: rgba(25,2,0,0.02);
  cursor: default;
}
