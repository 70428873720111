/*
---
name: Modals
category: components/modals
tag:
  - Modals
---

```jade
.main-modal
    .main-modal__box
        .main-modal__head
            .main-modal__controls
                .main-modal__close
                    span Close
                    .main-modal__btn.icon.icon--close
            .main-modal__head-content
                h1 Upgrade plan
                p Want to get more out of Talentis?
                    br
                    | Contact us via the form below or give us a call at +32 (0) 493 505 507
        .main-modal__body
            input.input-field.input-field--white-border(placeholder='Phone number')
            textarea.input-field.input-field--textarea.input-field--white-border.input-field--xxxl(placeholder='Message...')

            a.btn.btn--secondary Contact us

```
*/


.main-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    @include size(100%);
}

    .main-modal__box {
        display: flex;
        flex-direction: column;
        @include size(37.5rem auto);
        max-width: 90%;
        background-color: #fff;
        border-radius: 3px;
        box-shadow: 0 19.5px 101px 0 rgba(0, 0, 0, 0.68);
    }

    .main-modal__head {
        min-height: 35%;
        width: 100%;
    }

        .main-modal__controls {
            display: flex;
            justify-content: flex-end;
            padding: 1.375rem 1.5rem;
        }

            .main-modal__close {
              display: flex;
              align-items: center;
              font-size: $font-size-default;
              color: $grey-dark;
              cursor: pointer;
              span {
                  margin-right: 9px;
              }
            }

                .main-modal__btn {
                    cursor: pointer;
                    transition: all 0.1s ease-in-out;
                    &:hover {
                        opacity: .5;
                    }
                }

                .main-modal__description { 
                  padding-bottom: 30px;
                 }

        .main-modal__head-content {
            text-align: center;
            padding: 0 3rem 1rem;
        }

            .main-modal__head-content--warning {
                h1 {
                    color: $warning-color;
                }
            }

                .main-modal__icon {
                    margin-bottom: 1rem;
                }

        .main-modal__modal-nav {
            display: flex;
            flex-direction: row;
            justify-content: center;
        }

            .modal-nav__item {
                display: flex;
                align-items: center;
                cursor: pointer;
                padding: 18px 0;
                color: #b6b6b6;
                text-align: left;

                .icon {
                    margin-right: 16px;
                }

                + .modal-nav__item {
                    margin-left: 45px;
                }

                &.active {
                    border-bottom: 3px solid $brand-color-1;
                    color: $black;
                }
                &:hover {
                    color: $black;
                }
            }



    .main-modal__body {
        // height: 100%;
        min-height: 50%;
        padding: 20px 40px 10px 40px;
        background-color: #f7f7f7;
        text-align: center;
        display: flex;
        flex-direction: column;
        flex: 1;
        // todo : try "flex: 1 1 auto;" for support in EI11
        // and also try to set the width to 100%, otherwise text gets out of the box
        justify-content: center;
        align-items: center;
        .input-field {
          // make default padding for input fields smaller inside popups
          padding-left: 20px;
          &.input-field--multiselect {
            padding-left: 0;
            .multiselect-parent {
              .dropdown-toggle {
                  padding-left: 20px;
              }
            }
          }
        }
        .input-field.input-field--search {
          padding-left: 45px;
        }

        .input-field--textarea {
            font-size: $font-size-default;
            resize: none;
            margin: 0;
        }
        h2 {
          margin-bottom: 1rem;
          font-size: 1.25rem;
          margin-top: 2.5rem;
        }

        .main-modal__body__field-left {
          text-align: left;
          .wrap-dd-menu {
            margin-left: 0;
            margin-right: 0;
          }
        }
    }

        .main-modal__buttons {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #f7f7f7;
            padding: 1rem 0 2rem 0;
            span {
                padding: 0 10px;
            }
            .btn {
                font-size: $font-size-default;
            }
        }


.modal--profile-photo {

  h1 {
    letter-spacing: normal;
    font-weight: normal;
    margin-bottom: 24px;
  }
  .main-modal__controls {
    display: flex;
    justify-content: flex-end;
    padding: 1.375rem 1.5rem 1rem 1.5rem; /* 22/16, 24/16, 16/16, 24/16 */
  }

  .main-modal__body {
    display: none;
  }
  .main-modal__buttons {
    margin-top: 40px;
    padding: 3.25rem 0 3.625rem 0; /* 52/16, 58/16 */
    background-color: #f7f7f7;
  }

  .btn--save-picture, .btn--upload-picture {
    padding: 16px 0;
    margin: 0 14px;
    width: 185px;
  }

  .btn--save-picture {
    background-color: black;
    &:hover {
      background-color: $brand-color-3-lighter;
    }
  }

  .btn--upload-picture {
    background-color: $brand-color-3;
    &:hover {
      background-color: $brand-color-3-lighter;
    }
  }
}

.modal--candidate-invite,
.modal--collaborator-invite,
.modal--collaborator-message,
.modal--candidate-message,
.modal--edit-candidate,
.modal--upgrade,
.modal--extend,
.modal--post-job,
.modal--reset-video-question {

  .main-modal__head-content {
    input {
      min-height: 63px;
    }
  }

  .main-modal__body {
    textarea {
      min-height: 182px;
      padding-left: 1.5rem;
    }
  }

  .main-modal__buttons {
    padding-top: 2rem;
  }

  .btn {
    padding: 1rem 2.3rem;
    letter-spacing: .02rem;
  }
}
.modal--collaborator-invite {
  .main-modal__box {
    width: 720px;
    max-width: 720px;
  }

  user-rights-manager {
    margin-top: 20px;
  }
}

.modal--campaign-selection {
  .campaign-selection {
    width: 100%;
  }
}

.modal--upgrade {

  width: 500px;

  .upgrade--img {
    margin-bottom: 1rem;
    margin-top: -2rem;
    width: 50px;
  }

  .main-modal__body {
    text-align: left;
    font-family: Gotham Book,Arial,sans-serif;
    font-size: .875rem;
    line-height: 1.8;

    p + p {
      padding-top: 0.5rem;
    }
    ul {
      list-style-position: inside;
      list-style-type: disc;
      padding-left: 40px;
    }
  }
}

.modal--post-job {

  .main-modal__body {
    textarea {
      min-height: 60px;
    }
    .input-field--textarea {
      margin-bottom: 20px;
    }
  }

  .p {
    margin: 40px auto;
  }

  .main-modal__box {
    width: 800px;
    max-width: 800px;
  }
}


.modal--submission-share {
    .main-modal__head-content {
        p {
            font-size: $font-size-default;
        }
        .input-field {
            margin: 2rem 0 0;
            border: 1px solid #ebebeb;
        }
    }
    .main-modal__buttons {
        padding-top: 1rem;
    }
}

.modal--stage-history {

  .history-stage {
    display: flex;
    align-items: center;

  }
  
  .history-stage + .history-stage {
    margin-top: 10px;
  }
}

.modal--collect-candidates {
  min-width: 1200px;

  p + p {
    padding-top: unset;
  }

  .main-modal__head-content {
    p {
      color: $grey-darker;
      font-size: $font-size-default;
      margin: 35px auto 15px;
    }
  }

  .main-modal__box {
    width: 100%;
    max-width: 100%;
  }
}

.modal--import-csv {

  .main-modal__box {
    width: 900px;
    max-width: 900px;
  }

  .main-modal__body {
    padding-left: 5rem;
    padding-right: 5rem;
    padding-bottom: 2rem;
  } 
}

.modal--export-campaigns {
  .main-modal__box {
    width: 900px;
    max-width:900px;
  }
}

.modal--create-task-from-message {
  .main-modal__body {
    text-align: left;
    align-items: start;
  }
}

.modal--crud {
  width: 1200px;
  crud-list {
    width: 950px;
  }
  .main-modal__box {
    width: 100%;
  }
}

// 
// Dropdowns
// 

.dropdown-modal__container {
  width: 100%;
  margin-bottom: 20px;
  
  .wrap-dd-menu {
      .dropdown {
          width: 100%;
      }
  }
}

  .dropdown-modal {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 55px;
      margin-bottom: 0;
  }

  // Dropdown in the "message to candidate" modal, next to the "send email" button
  // -> this is super ugly but I could not find another way at the moment
  .modal-dropdown-options {
    padding: 20px 0.8rem !important;
    margin-left: 3px;

    @include append__arrow-down(10px, white);

    &:after {
      right: -4px;
      border-width: 8px 6px 0;
      top: 0;
    }
  }

/**
 * angular-fancy-modal - the definitive modal/popup/dialog solution for AngularJS.
 * @author Alessandro Arnodo
 * @url http://alessandro.arnodo.net
 * @version v0.1.4
 * @link https://github.com/vesparny/angular-fancy-modal
 * @license MIT
 */
body.fancymodal-open {
  overflow: hidden; }

// .fancymodal,
// .fancymodal *,
// .fancymodal *:before,
// .fancymodal *:after {
//   box-sizing: border-box; }

.fancymodal {
  // padding-top: 120px;
  // padding-bottom: 160px;
  // -webkit-overflow-scrolling: touch;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  overflow: scroll;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  padding: 20px;
  // padding-left: 8px;
  // padding-right: 8px;
}
  .fancymodal .fancymodal-overlay {
    -webkit-backface-visibility: hidden;
    background-color: transparentize(#45494C, .15);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .fancymodal .fancymodal-content {
    max-width: 100vw;
    /*background: #fff;*/
    -webkit-backface-visibility: hidden;
    // -webkit-border-radius: 5px;
    // -moz-border-radius: 5px;
    // -ms-border-radius: 5px;
    // -o-border-radius: 5px;
    // padding: 2em 1em;
    // border-radius: 5px;
    /*background: #eee;*/
    // color: #000;
    position: relative;
    margin: auto; }
  .fancymodal .fancymodal-close {
    // border-radius: 3px;
    // position: absolute;
    // top: 0;
    // right: 0;
    // cursor: pointer;
    display: none;
  }
//   .fancymodal .fancymodal-close:before {
//     border-radius: 3px;
//     position: absolute;
//     content: "\00D7";
//     font-size: 26px;
//     line-height: 30px;
//     height: 30px;
//     width: 30px;
//     text-align: center;
//     top: 3px;
//     right: 3px;
//     color: #ccc;
// }
  // .fancymodal .fancymodal-close:hover:before,
  // .fancymodal .fancymodal-close:active:before {
  //   color: #777;
  //   background: #e0e0e0; }
  .fancymodal .fancymodal-content-opening {
    -webkit-animation: fancymodal-in 0.5s;
    animation: fancymodal-in 0.5s; }
  .fancymodal .fancymodal-content-closing {
    -webkit-animation: fancymodal-out 0.5s;
    animation: fancymodal-out 0.5s; }
  .fancymodal .fancymodal-overlay-opening {
    -webkit-animation: fancymodal-fadeIn 0.5s;
    animation: fancymodal-fadeIn 0.5s; }
  .fancymodal .fancymodal-overlay-closing {
    -webkit-animation: fancymodal-fadeOut 0.5s;
    animation: fancymodal-fadeOut 0.5s; }

@-webkit-keyframes fancymodal-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes fancymodal-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes fancymodal-out {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px); } }

@keyframes fancymodal-out {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px); } }

@-webkit-keyframes fancymodal-fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fancymodal-fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fancymodal-fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fancymodal-fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }
