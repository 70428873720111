.interview-entries {
  flex: 1;

  .interview__header {
    display: flex;

  }
  
  .interview__header__subtitle {
    margin-top: 10px;
  }

  .interview-card {
    width: 100%;
    background-color: #fff;
    margin-top: 15px;
    padding: 20px 30px;

    .interview-datepicker {
      height: auto;
      width: 250px;

      .input-field {
        min-height: 25px;
        padding: 20px;
      }
    }

    &:hover .interview-card__header .actions.no-grow a {
      display: flex;
    }
    .interview-card__header .actions.no-grow {
      a {
        display: none;
      }
      width: 84px;
      height: 32px;
    }

    .interview-card__header {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      gap: 50px;

      .input-field {
        margin-bottom: 5px;
      }

      & > .title {
        flex: 1;
        max-width: 60%;
      }

      .no-grow {
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        gap: 20px;

        &.actions {
          justify-content: end;
        }
      }
    }

    .interview-card__body {
      width: 100%;
      min-height: 50px;
      margin-top: 20px;
    }

    .interview-card__footer {
      padding: 10px;
      flex: 1;
      display: flex;
      justify-content: flex-start;

      .btn {
        width: 250px;
      }
    }
  }
}
