/*
---
name: Page header
category: headers/page-header
tag:
  - Headers
---

```jade
.page-header.bg.bg--pics-on-wall
    p You can optionally add tests to your campaign to test the skills of the candidates.
        br
        | A score will be computed based on the answers.
```
*/

.page-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    @include size(100% 150px);
    padding: 0 $content-padding;
    h1, p {
        color: $white;
    }
    h1 {
        font-family: 'Lato Light';
        margin-bottom: 0;
        margin-top: 30px;
    }
    p {
        text-align: center;
        width: 100%;
        font-size: $font-size-default;
    }
}

.page-header--white {
    h1, p {
        color: $black;
    }
}
