.section--first {
  padding-top: 110px;
  @media screen and (min-width: $size-md) {
    padding-top: 80px;
  }

  &.section--single {
    padding-top: 0px;
    height: 100%;
    align-content: center;

    .section-spaced {
      padding-top: 0px;
    }
  }
}

.section-content {
  display: flex;
  flex-direction: column;
  padding-left: 360px;
  padding-right: 360px;
  max-width: 1520px;
  margin-left: auto;
  margin-right: auto;

  &.section-content--relative {
    position: relative;
  }
  
  &.section-content--center, .section-content--center {
      justify-content: center;
      align-items: center;
  }

  &.section-content--sm {
    max-width: 1320px;
    @media screen and (max-width: $size-lg-max) {
      max-width: 550px;
    }
  }

  &.section-content--lg {
    max-width: 1120px;
    padding-left: 90px;
    padding-right: 90px;

    @media screen and (min-width: $size-sm) and (max-width: $size-lg-max) {
      padding-left: 60px;
      padding-right: 60px;
    }
    @media screen and (max-width: $size-xs-max) {
        padding-left: 20px;
        padding-right: 20px;
    }
  }

  &.section-content--row {
    flex-direction: row;
  }

  &.section-content--btn-container {
    flex-direction: row;
    justify-content: space-between;
  }
  
  @media screen and (min-width: $size-xl) {
      .col-md-6 &, .col-lg-6 &, .col-xl-6 & {
          padding-left: 140px;
          padding-right: 140px;
      }
  }
  @media screen and (min-width: $size-sm) and (max-width: $size-lg-max) {
      padding-left: 75px;
      padding-right: 75px;
  }
  @media screen and (max-width: $size-xs-max) {
      padding-left: 20px;
      padding-right: 20px;
  }

  .section-content__element {
      padding-top: 10px;
      padding-bottom: 10px;

      &.section-content__element--sm {
        font-size: $font-size-small;
        &:not(:first-child) {
            padding-top: 3px;
        }
        &:not(:last-child) {
            padding-bottom: 3px;
        }
      }
      
      .section-content__text {
          margin-bottom: 10px;
          font-family: $font-family-main-medium;
      }
      
      .section-content__links {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: auto;
      }

        .section-content__link {
          text-decoration: underline;
          color: $brand-blue;
          width: fit-content;

        }
        .section-content__link + .section-content__link {
          margin-top: 5px;
        }

      .section-content__text--justify {
        text-align: justify;
        width: fit-content;
        margin: auto;
        white-space: pre-wrap;
      }

      .section-content__icon {
          margin-right: 10px;
      }
  }
  .section-content__center {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      display: inline-block;
  }
  .section-centered-buttons {
    display: flex;
    flex-direction: row-reverse;
    gap: 20px;
    align-items: center;
    justify-content: center;
  }
}

.btn--next-section {
  background: url('/imgs/icons/icon-down-arrow.png') center / contain no-repeat;
  filter: invert(100%); 
  position: absolute;
  align-self: center;
  bottom: 10px;
  z-index: 20;

  &:hover {
    background-color: transparent;
  }
}

.col--no-padding {
  padding-left: 0px;
  padding-right: 0px;
}
.col--centered {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.col--fit-content {
  display: flex;
  max-height: fit-content;
  justify-content: flex-end;
  align-items: flex-end;
  margin-inline-start: auto;
}
.col--buttons {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 15px;
}

.section-spaced {
  gap: 30px;
  padding-top: 30px;

  > :last-child, > .section-content__element:last-child {
      padding-bottom: 30px;
  }
  > :first-child {
      padding-top: 0;
      &.section-content__element .section-content__text:first-child {
          margin-top: 0;
      }
  }
  > * {
    margin-bottom: 0;
  }
}

.section-banner {
  //height: 590px;
  height: calc(var(--vh)*100);
  background-size: cover;
  background-position: 50%;
  padding: 80px 0 0;

  &.section-banner--small {
    max-height: 480px;
    background-position: center;
  }

  h1, h2, p {
    color: $white;
    text-shadow: 0 0 1px #000;
    font-size: 54px;
    @media screen and (max-width: $size-sm) {
      font-size: 38px;
    }
    font-family: $font-family-ternary-semi;
    padding: 10px;
    text-align: center;
    margin-bottom: 0px;
  }

  // if background is custom image, text must be readable
  .custom-background {
      color: $white;
      text-shadow: 0 0 1px black;
  }

  .section-banner-overlay {
    background-color: rgba(0, 0, 0, .3);
    width: 100%;
    height: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 20px;
    text-align: center;
  }

  .section-banner__details {
      display: flex;
      justify-content: center;
      font-size: $font-size-base;
      margin: 10px 0;

      .section-banner__detail {
          display: inline-block;
          margin: 10px;
      }

      .section-banner__icon {
          margin-right: 10px;
      }

      @media screen and (max-width: $size-sm) {
          flex-direction: column;

          .section-banner__detail {
            margin: 5px;
          }
      }
  }
  
  @media screen and (min-width: $size-xl) {
    margin-bottom: 60px;
  }
  @media screen and (max-width: $size-lg-max) {
    margin-bottom: 20px;
  }
}

.section-title {
  font-family: $font-family-main-medium;
  font-size: $font-size-medium;
  margin-bottom: 15px;
  text-align: center;

}
h1.section-title {
  font-family: $font-family-ternary-semi;
  font-size: $font-size-larger;
}
.section-title-2 {
  font-family: $font-family-main-medium;
  font-size: $font-size-default;
  text-align: center;
}
.section-transition {
  background-color: $brand-yellow;
  color: $white;
  // text-shadow: 0 0 1px black; // not sure to be removed..
  margin-bottom: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-family: $font-family-main-medium;

  .section-transition__title {
    font-size: $font-size-medium;
    text-align: center;
  }
  .section-transition__back {
    float: left;
    margin-left: 20px;
    position: absolute;
  }
}
.section-html-text {
  @include beehire-formatted-html($black);
}
.section-content--grey {
  height: 100%;
  background-color: $white-three;
  word-break: break-word;
  padding-left: 20px;
  padding-right: 20px;

  .section-title, .section-title-2 {
      padding-top: 20px;
      font-family: $font-family-main-medium;
      font-size: $font-size-medium;
  }
}
.section--square {
  width: 100%;

  &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
  }
}