.account-select__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-image: url('/imgs/login-yellow-neutral.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.account-select__box {
  width: 100%;
  max-width: 440px;
  height: 600px;
  padding: 20px 40px 50px;
  border-radius: 5px;
  box-shadow: 0 7px 54.5px 0 rgba(0, 0, 0, 0.18);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.12);
}

.account-select__title {
  font-size: 1.5rem;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 30px;
}

.account-select__logo {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.account-select__search {
  width: 97%;
  margin-bottom: 20px;
  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 58px;
  }
}

.account-select__list {
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 0;
  padding: 5px;
}

.account-select__item {
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #f7f7f7;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin-bottom: 3px;
  
  &:hover {
    background-color: #e9e9e9;
  }
}
  
  .account-select__item-icon {
    width: 50px;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    img {
      height: auto;
      max-height: 50px;
      max-width: 50px;
      width: auto;
      vertical-align: middle;
    }
  }
  
  .account-select__item-name {
    flex-grow: 1;
    padding: 0 10px;
    text-align: left;
    font-size: 1rem;
    margin-left: 15px;
  }
  
  .account-select__item-action {
    font-size: 1.5rem;
    color: #ccc;
  }

.account-select__logo--xl {
  height: 60px;
  width: 60px;
  margin-top: 70px;
}

.account-select__no-logo-placeholder {
  width: 40px;
  height: 40px;
  display: inline-block;
}

.account-select__item--suspended {
  background-color: #e0e0e0;
  color: #a0a0a0;
  cursor: not-allowed;

  &:hover {
    background-color: #e0e0e0;
  }
}