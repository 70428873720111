.show-more-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 10px 0;
}

  .show-more {
    font-family: $font-gotham-bold;
    font-size: $font-size-small;
    color: $brand-color-3;
    cursor: pointer;
  }

    .show-more--open {
      padding-right: 20px;
      background: url('/imgs/icons/icon-full-arrow-black-top@2x.png') right center / 12px 6px no-repeat;
    }

    .show-more--closed {
      padding-right: 20px;
      background: url('/imgs/icons/icon-full-arrow-black-bottom@2x.png') right center / 12px 6px no-repeat;
    }
