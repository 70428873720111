/*
  fixes the bug: when menu is closed, text remains for a second
*/
.dropdown-item {
  display: none;
}

.active {
  .dropdown-item {
    display: block;
  }
}

.dropdown--questions {
  background-color: #EAEAEA;
  border-radius: 3px;
  margin: 0;
  padding: .9rem 1.25rem;
  width: 300px;
  .dropdown {
      width: 300px;
  }
  &.active {
    .dropdown {
      visibility: visible;
    }
  }
  &:after {
      border-color: #A3A3A3 transparent;
  }
}
