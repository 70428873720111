/*
---
name: Campaign card
category: components/campaign-card
mixin: campaignCardPreview
tag:
  - Components
---

```code
// Active
+campaignCard()

// Inactive
+campaignCard('inactive')
```
*/


.campaign-card {
    display: inline-flex;
    flex-direction: column;
    @include size(330px 205px);
    border-radius: 5px;
	border: solid 1px #ececec;
    margin: 0 50px 50px 0;
}

    .campaign-card__stats {
        display: inline-flex;
        justify-content: space-around;
        align-items: center;
        padding: 0 10px;
        @include size(100% 55%);
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }

    .campaign-card__stats-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px 0;
        font-family: $font-family-main-book;
        color: #fff;

        + .campaign-card__stats-item {
            border-left: 1px solid rgba(255,255,255,.2);
        }
    }

        .campaign-card__stats-value {
            font-size: 50px;
        }

        .campaign-card__stats-label {
            font-size: $font-size-small;
            margin-top: -5px;
            text-transform: uppercase;
        }

    .campaign-card__bottom {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        position: relative;
        @include size(100% 45%);
        padding: 0 25px;
        background-color: $white;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
    }
        .campaign-card__info {
            width: 240px;

            .campaign-card__title {
                margin-top: 5px;
                font-size: $font-size-default;
                font-family: $font-family-main-medium;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .campaign-card__date {
                width: fit-content;
                margin-top: 6px;
                font-size: $font-size-small;
                color: $grey-darkerer;
            }
        }

        .campaign-card__badges {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;

            .campaign-card__posting {
                margin-top: 4px;
            }

            .job-categories__container {
                height: 28px;

                .badge {
                    margin: 4px 0px 0px;
                    height: 24px;
                    max-width: 95px;

                    span {
                        width: 100%;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
            }
        }

        .campaign-card__settings {
            cursor: pointer;
            @include size(22px);
            min-width: 22px;
            .icon {
                &:hover {
                    opacity: .5;
                }
            }

            // .wrap-dd-menu {
            //     width: 22px;
            //     .dropdown {
            //         width: 220px;
            //     }
            // }
        }
    .campaign-card__notif-container {
        
    }
