//
// Angular tooltips
//
$tolerance: 3px;
$margin-tooltip-arrow: 6px;
$padding-top-bottom-tooltip: 8px;
$padding-right-left-tooltip: 16px;
$tooltip-background-color: rgba(0, 0, 0, .85);
$tooltip-color: #fff;
$tooltip-border-radius: 3px;
$tooltip-fast-transition: .15s;
$tooltip-slow-transition: .65s;
$tooltip-medium-transition: .35s;

@mixin opacity-transition($speed) {
  animation: animate-tooltip $speed;
}
@mixin border-radius($radius) {
  border-radius: $radius;
}

@mixin transform($x, $y) {
  -webkit-transform: translateX($x) translateY($y);
  transform: translateX($x) translateY($y);
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin tipArrow($side, $color) {
  content: '';
  height: 0;
  position: absolute;
  width: 0;

  border: $margin-tooltip-arrow solid transparent;
  border-#{$side}: $margin-tooltip-arrow solid $color;
  #{$side}: 100%;

  @if($side == 'top' or $side == 'bottom') {
    margin-left: -$margin-tooltip-arrow;
    left: 50%;
  }
  @if($side == 'right' or $side == 'left') {
    margin-top: -$margin-tooltip-arrow;
    top: 50%;
  }
}

._exradicated-tooltip {
  display: block;
  opacity: 1;
  position: absolute;
  z-index: 999;
}

tooltip {
  width: fit-content;

  @include keyframes(animate-tooltip) {

    @for $i from 0 through 100 {
      #{$i}% {
        opacity: $i * 0.01;
      }
    }
  }

  display: inline-block;
  position: relative;

  &._multiline {

    display: block;
  }

  &._slow {

    &._ready {

      tip {
        @include opacity-transition($tooltip-slow-transition);
      }
    }
  }

  &._fast {

    &._ready {

      tip {
        @include opacity-transition($tooltip-fast-transition);
      }
    }
  }

  &._steady {

    &._ready {

      tip {
        @include opacity-transition($tooltip-medium-transition);
      }
    }
  }

  tip {
    @include border-radius($tooltip-border-radius);
    background: $tooltip-background-color;
    color: $tooltip-color;
    display: none;
    line-height: normal;
    text-transform: none;
    max-width: 500px;
    min-width: 120px;
    opacity: 0;
    padding: $padding-top-bottom-tooltip $padding-right-left-tooltip;
    position: absolute;
    text-align: center;
    width: auto;
    overflow-wrap: break-word;
    will-change: top, left, bottom, right;

    &._hidden {

      display: block;
      visibility: hidden;
    }
  }

  &.active:not(._force-hidden) {

    tip {
      display: block;
      opacity: 1;
      z-index: 999;
    }

  }

  tip-tip {

    font-size: .95em;

    &._large {

      font-size: 1.1em;
    }

    &._small {

      font-size: .8em;
    }
  }

  &._top {

    tip {

      left: 50%;
      top: -($margin-tooltip-arrow + $tolerance);
      @include transform(-50%, -100%);

      tip-arrow {
        @include tipArrow('top', $tooltip-background-color);
      }
    }
  }

  &._bottom {

    tip {

      right: 50%;
      top: 100%;
      @include transform(50%, $margin-tooltip-arrow + $tolerance);

      tip-arrow {
        @include tipArrow('bottom', $tooltip-background-color);
      }
    }
  }

  &._right {

    tip {

      left: 100%;
      top: 50%;
      @include transform($margin-tooltip-arrow + $tolerance, -50%);

      tip-arrow {
        @include tipArrow('right', $tooltip-background-color);
      }
    }
  }

  &._left {

    tip {

      left: -($margin-tooltip-arrow + $tolerance);
      top: 50%;
      @include transform(-100%, -50%);

      tip-arrow {
        @include tipArrow('left', $tooltip-background-color);
      }
    }
  }
}

tip-tip {

  #close-button {
    cursor: pointer;
    float: right;
    left: 8%;
    margin-top: -7%;
    padding: 3px;
    position: relative;
    }
  }

.parent-of-tooltip-overlay {
  /* Used when the tooltip needs to overlay the whole element */
  position: relative;
  width: 100%;

  & > tooltip {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
}

.parent-of-tooltip-collaborator {
  @extend .parent-of-tooltip-overlay;
  display: inline-block;
    & > tooltip {
      left: 7px;
  }
}

.parent-of-tooltip-overlay-upgrade {
  @extend .parent-of-tooltip-overlay;
}