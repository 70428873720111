/*
---
name: Invite collaborator
category: components/invite-collaborator
tag:
  - Invite
---

```jade
.invite-box.bg.bg--gradient-sky
    h1 Invite a collaborator

    .invite-box__count
        span 4
            b /5
        .invite-box__cta Upgrade

    .invite-box__invite
        input.input-field.input-field--s(placeholder='Email address...')
        a.btn.btn--dark.invite-box__invite-btn Invite
```
*/

.invite-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 15rem;
    justify-content: space-between;
    height: 160px;
    width: auto;
    border-radius: 3px;
    padding: 30px;
    margin-left: 50px;
    h1, h2 {
        color: $white;
    }
    @media (max-height: 600px) {
        max-height: 13rem;
    }
}


    .invite-box__count {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0px 0 25px;
        span {
            display: flex;
            align-items: center;
            justify-content: center;
            @include size(90px 35px);
            border-radius: 3px;
            background-color: rgba(255,255,255,.15);
            color: $white;
            font-size: font-size-small;
            letter-spacing: 3px;
            margin-bottom: 10px;
            b {
                color: white;
            }
        }
    }

        .invite-box__cta {
            text-transform: uppercase;
            text-decoration: underline;
            color: white;
            font-size: 10px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            &:hover {
                color: #fff;
            }
        }

    .invite-box__invite {
        display: inline-flex;
        align-items: center;

        .input-field {
            border-radius: 3px 0 0 3px;
            margin-bottom: 0;
            @media (max-height: 770px) {
                min-height: 2.6rem;
            }
        }
    }

        .invite-box__invite-btn {
            padding: 1rem;
            width: 5.4375rem;
            border-radius: 0 3px 3px 0;
        }
