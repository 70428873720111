$padlock-ratio: 0.75;
$padlock-little-height: 15px;
$padlock-little-width: calc(#{$padlock-little-height} * #{$padlock-ratio});
$padlock-medium-height: 20px;
$padlock-medium-width: calc(#{$padlock-medium-height} * #{$padlock-ratio});

// 
// Mixins
// 

@mixin upgrade-padlock-badge($width, $height, $up) {
    position: relative;

    &::after {
        content: "";
        position: absolute;
        background: url('/imgs/icons/upgrade-padlock.png') no-repeat;
        background-size: $width;
        height: $height;
        width: $width;
        margin-left: 10px;

        @if $up {
            right: -15px;
            top: -8px;
        }
    }
}

// 
// Classes
// 

.upgrade-padlock-badge {
    margin-right: 25px;
    @include upgrade-padlock-badge($padlock-little-width, $padlock-little-height, false);
}

.upgrade-nav {
    @include upgrade-padlock-badge($padlock-little-width, $padlock-little-height, true);
}

.profile-item-upgrade {
    & span {
        font-weight: bold;
        color: $upgrade-color;
        font-weight: 700;
        background-color: $upgrade-color;
        color: white;
        padding: 10px;
        margin-left: -10px;
        border-radius: 3px;
    }
}
