.sidebar-documents {
  width: 50px;
  height: 100%;
  position: fixed;
  top: 132px;
  left: 0;
  background-color: #eee;
  transition: width 0.2s ease;
  z-index: 1000;

  &.expanded {
    width: 50%;
  }

  @media screen and (max-width: 1250px) {
    width: 50px;
    height: 0%;
    top: 153px;

    &.expanded {
      width: 100%;
      height: 100%;
      top: 0px;

    }
  }
}

.sidebar-documents__title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  text-align: left;
}

.sidebar-documents__title-container.expanded {
  display: flex;
  justify-content: space-between;
  text-align: right;
  margin-right: 30px;
  left: 45%;
}

.sidebar-documents__toggle-icon {
  margin-left: auto;
}

.sidebar-documents__title-container span {
  font-size: 16px;
}

.sidebar-documents__title-container span i {
  font-size: 30px;
  cursor: pointer;
  padding: 8px;
}

.interview-questions-main__content.expanded {
  margin-left: 50%;
}

.col-12__sidebar-documents-expanded {
  margin-left: 50%;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
  max-width: calc(100% - 50%);
  transition: margin-left 0.2s ease, max-width 0.2s ease;
}

.col-12__sidebar-documents-expanded.expand-to-full-width {
  margin-left: 100%;
  max-width: 100%;
}

.sidebar-documents__container.expanded {
  display: flex;
  flex-direction: row;
  height: 70%;
}

.sidebar-documents__pdf-zone-container.expanded {
  flex: 1;
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 3px;
  background-color: #ccc;
}
.sidebar-documents__pdf-zone-container.expanded {
  width: calc(50% - 20px);
}

.sidebar-documents__document-title {
  margin-left: 75px;
}

.sidebar-documents__pdf-zone.expanded {
  height: 100%;
  width: 100%;
}


#pdfViewerContainer {
  display: none;
}

#pdfViewerContainer.expanded {
  display: block;
  overflow-y: scroll;
  height: calc(100% - 80px);
}

.sidebar-documents__document img {
  width: 100%;
  border: 2px solid black;
  border-radius: 3px;
  margin-bottom: 10px;
}

.sidebar-documents__pdf-text-search-container {
  display: none;

  &.expanded {
    display: flex;
    flex-direction: flex;
    // align-items: space-between;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  .sidebar-documents__pdf-text-search-input-container {
    display: flex;
    align-items: center;

    input.sidebar-documents__pdf-text-search-input {
      flex: 1;
      height: 34px;
      border-radius: 4px 0 0 4px;
      border: 1px solid #ccc;
      padding-left: 10px;
      font-size: 14px;
    }

    button.sidebar-documents__pdf-text-search-button {
      height: 34px;
      border: 1px solid #ccc;
      border-left: 0;
      border-radius: 0 4px 4px 0;
      background-color: #2d517b;
      cursor: pointer;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0px;

      &:hover {
        background-color: #24406d;
      }

      i.fa-search {
        color: #fff;
      }
    }
  }

  button {
    margin-left: 5px;
    padding: 5px 10px;
    border: 1px solid #eee;
    border-radius: 3px;
    background-color: #fff;
    cursor: pointer;
    &:hover {
      background-color: #e0e0e0;
    }
  }

  .sidebar-documents__pdf-text-search-results-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }

  .sidebar-documents__pdf-text-search-results-container span {
    margin: 0 10px;
  }
}

.sidebar-documents__documents-container.expanded {
  flex: 0 0 8%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 80px;
  padding-right: 20px;
}

.sidebar-documents__document.expanded {
  height: auto;
  width: 100%;
  margin: 30px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-documents__document-thumbnail-container.expanded {
  height: 80px;
  cursor: pointer;
}

// Active document (selected)
.sidebar-documents__document.selected img {
  border-color: $brand-yellow;
  border-width: 3px;
}


.section-content.expanded {
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 0;
  width: 100%;
  max-width: calc(100vw - 53%);
}

.sidebar-documents__pdf-text-search-no-result-message {
  color: red;
  font-weight: bold;
}

/* Pagination */
.sidebar-documents__pdf-pagination {
  display:none;
}

.sidebar-documents__pdf-pagination.expanded {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  text-align: center;
}

.sidebar-documents__pdf-pagination-left {
  display: flex;
  align-items: center;
}

.sidebar-documents__pdf-pagination-right {
  display: flex;
  align-items: center;

    &.input {
      flex: 1;
      height: 34px;
      border-radius: 4px 0 0 4px;
      border: 1px solid #ccc;
      padding-left: 10px;
      font-size: 14px;
      width: auto;
    }

    &.button {
      height: 34px;
      border: 1px solid #ccc;
      border-left: 0;
      border-radius: 0 4px 4px 0;
      background-color: #2d517b;
      cursor: pointer;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0px;

      &:hover {
        background-color: #24406d;
      }

      i.fa-search {
        color: #fff;
      }
    }
}

.sidebar-documents__pdf-pagination button,
.sidebar-documents__pdf-pagination input {
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #f5f5f5;
  cursor: pointer;
}

.sidebar-documents__pdf-pagination button:hover,
.sidebar-documents__pdf-pagination input:hover {
  background-color: #e0e0e0;
}

.sidebar-documents__pdf-pagination input {
  width: 160px;
}

.sidebar-documents__pdf-pagination span {
  margin: 0 10px;
}

.sidebar-documents__pdf-zoom-controls {
  display: flex;
  align-items: center;
  margin-left: auto;
  width: 150px;

  button {
    margin-left: 5px;
    // padding: 5px 10px;
    border: 1px solid #ddd;
    border-radius: 3px;
    background-color: #f5f5f5;
    cursor: pointer;

    &:hover {
      background-color: #e0e0e0;
    }
  }

  span {
    margin: 0 10px;
    font-size: 16px;
    font-weight: bold;
    min-width: 50px;
    text-align: center;
  }
}

@media screen and (max-width: 1250px) {
  .sidebar-documents__documents-container.expanded {
    width: 100%;
    height: auto;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    order: 1;
    flex: 0 0 15%;
    min-height: 100px;
  }

  .sidebar-documents__pdf-zone-container.expanded {
    flex: 1 1 80%;
    width: 100%;
    order: 2;
    padding: 5px;
  }

  .sidebar-documents__document.expanded {
    display: inline-block;
    height: auto;
    width: auto;
    margin: 10px;
  }

  .sidebar-documents__document-thumbnail-container.expanded {
    display: inline-block;
    width: 50px;
    height: 70px;
    cursor: pointer;
  }

  .sidebar-documents__title-container.expanded {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    background-color: #eee;
    padding: 5px 0;
    flex: 0 0 5%;
  }
  
  .sidebar-documents__container.expanded {
    flex-direction: column;
    height: 100vh;
  }

  .sidebar-documents__pdf-zone.expanded {
    height: 60vh;
    margin-top: 10px;
  }

}

/* Full width */
.sidebar-documents.expanded.full-width {
  width: 100%;
  max-width: 100%;
}

.sidebar-documents__container.expanded.full-width {
  width: 100%;
}

.sidebar-documents__documents-container.expanded.full-width {
  width: 100%;
}

.sidebar-documents__pdf-zone-container.expanded.full-width {
  width: 100%;
}

.sidebar-documents__pdf-zone.expanded.full-width {
  width: 100%;
}

.sidebar-documents__pdf-text-search-container.expanded.full-width {
  width: 100%;
}

.sidebar-documents__pdf-text-search-results-container.expanded.full-width {
  width: 100%;
}

/* Optionally, if you want to hide the sidebar documents list when in full-width mode */
.sidebar-documents__documents-container.expanded.full-width {
  display: none;
}

.sidebar-documents__container,
.sidebar-documents__documents-container,
.sidebar-documents__pdf-zone-container,
.sidebar-documents__pdf-zone,
.sidebar-documents__pdf-text-search-container,
.sidebar-documents__pdf-text-search-results-container {
  transition: width 0.3s ease-in-out;
}



@media screen and (max-width: 1250px) {
  .sidebar-documents__pdf-text-search-container.expanded {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .sidebar-documents__pdf-text-search-input-container {
    margin-bottom: 10px;
  }

  .sidebar-documents__pdf-zoom-controls {
    align-items: center;
    margin-left: 0;
  }

  .sidebar-documents__pdf-zoom-controls button,
  .sidebar-documents__pdf-zoom-controls span {
    width: 100%;
    margin: 5px 0;
  }

  .sidebar-documents__document-title {
    margin-left: 30px;
  }

  .sidebar-documents__toggle-icon {
    margin-right: 20px;
  }

  .sidebar-documents__toggle-expand-icon {
    display: none;
  }

}