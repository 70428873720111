/*
---
name: Labels
category: forms/labels
tag:
  - Forms
  - Labels
---

```jade

// Smaller fonts
label.label.label--input.label--s Input label
input.input-field(placeholder='Input field')

// Default
label.label.label--input Input label
input.input-field(placeholder='Input field')

// Larger fonts
label.label.label--input.label--l Input label
input.input-field(placeholder='Input field')

// Thinner font
label.label.label--input.label--thin Input label
input.input-field(placeholder='Input field')

// White color
label.label.label--input.label--white Input label
input.input-field(placeholder='Input field')

// Form heading
label.label.label--input.label--heading FORM HEADING
input.input-field(placeholder='Input field')
```
*/

.label--and-tooltip-container {
    display: flex;
    align-items: center;

    // For inserting tooltip to the left of the label
    & tooltip {
        transform: translateY(-6px);
    }
}

.label {
	font-family: $font-family-main-bold;
	font-size: $font-size-small;
	letter-spacing: 0.3px;
	color: $black;
}

	.label--s {
        font-size: $font-size-smaller;
    }

    .label--l {
        font-size: $font-size-default;
    }

	.label--xl {
        font-size: $font-size-base;
    }

    .label--thin {
        font-family: $font-family-main-book;
    }

    .label--white {
        color: $white;
    }

    .label--input {
        display: block;
        margin-bottom: 5px;
    }
    .label--input--inline {
        display: inline-block;
    }
    label.label--input[for] {
        cursor: pointer;
    }
    .label--input--required {
        &::after {
            content: '*';
            margin-left: 5px;
        }
    }

	.label--error {
		color: #f66363;
		text-align: center;
	}

    .label--heading {
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        &:before, &:after {
            content: "";
            display: inline-block;
            position: relative;
            vertical-align: middle;
            width: 50%;
            height: 1px;
            background-color: #bcc7d0;
        }
        &:before {
            right: 1em;
            margin-left: -50%;
        }
        &:after {
            left: 1em;
            margin-right: -50%;
        }
    }

    .label--title {
        display: block;
        font-size: $font-size-large;
        text-align: center;
        margin: 0 auto 30px;
    }

    .label--optional {
        color: #8e8e8e;
    }

    .label--left {
        text-align: left;
        margin-right: auto;
    }

    .label--margin-right {
        margin-right: 15px;
    }

    .label--margin-bottom {
        margin-bottom: 15px;
    }

    .label--email {
        @include prepend-little-icon('/imgs/icons/profile-sidebar/icon-at-x32.png');
        text-decoration: underline;
    }
    .label--address {
        @include prepend-little-icon('/imgs/icons/profile-sidebar/icon-place-x32.png');
    }
    .label--phone {
        @include prepend-little-icon('/imgs/icons/profile-sidebar/icon-phone-x32.png');
    }