.c-modal-view-session__container{
    text-align:left;
}

.c-modal-view-session__label{
    margin-bottom: 1rem;
    margin-right: 6px;
}

.c-modal-view-session__span{
    margin-bottom: 1rem;
    margin-left:6px;
}

.c-modal-view-session__div{
    padding: 8px;
}
