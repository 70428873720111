.submission-tags {
  
}

  .submission-tags__text-default {
    font-size: $font-size-default;
    //color: $warm-grey;
    padding: 2rem 0 1rem;
  }

  .submission__tag-container {
    display: flex;
    flex-wrap: wrap;

    .badge {
      margin: 5px;
    }
  }

  .submission-tags__actions {
      tooltip + tooltip, tooltip + div {
          margin-left: 10px;
      }
  }

  .submission-tags__add {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      max-height: 150px;
      .input-field {
          margin: 0;
          font-size: $font-size-smaller;
      }
      .btn {
          padding: 1.20rem 4rem;
          margin-left: 40px;
      }
  }  

  tags-input.bootstrap-tags_input_design {
    //width: 80%;
    max-width: 700px;
    // border: 1px solid $warm-grey;
    
    .tags {
      display: flex;
      align-items: center;
      min-height: 60px;
      width: 100%;
      outline: none;
      border: none;
      box-shadow: none;

        .tag-item {
          @include tag();
          padding: 5px 10px 5px 15px;
          line-height: 18px;

          .remove-button {
            color: #fff;
            margin: 0px 0px 0px 4px;
          }
        }

        .focused {
          border: none;
          outline: none;
          box-shadow: none;
        }
    }

      .focused {
        border: none;
        outline: none;
        box-shadow: none;
      }
  }

  .tag__assigned__col {
    width: 70px;
    flex: 0 0 70px;
  }
  .tag__label__col {
    min-width: 200px;
    margin-right: auto;
  }
  .tag__category__col {
    width: 150px;
    flex: 0 0 150px;
  }

  