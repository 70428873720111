.submission-notes__comments {
    display: flex;
    flex-direction: column;
}

    .submission-notes__comments-item {
        display: flex;
        //min-height: 130px;
        flex-direction: column;
        justify-content: center;
        padding: 20px 50px;
        background-color: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 3px;

        // make child element appear on hover
        &:hover .submission-notes__comments-action {
            display: flex;
        }
    }
        .submission-notes__comments-header {
            display: flex;
            align-items: center;
        }

            .submission-notes__comments-profile {
                display: flex;
                flex-direction: row;
                align-items: center;
                .profile-field__info {
                    .profile-field__name {
                        text-transform: uppercase;
                        font-size: $font-size-default;
                    }
                    .profile-field__details {
                        font-size: $font-size-smaller;
                        color: $warm-grey;
                    }
                }
            }

            .submission-notes__comments-action {
                margin-left: auto;
                
                tooltip + tooltip {
                    margin-left: 10px;
                }

                // make this element appear on hover
                display: none;
            }

            .submission-notes__comments-comment {
                margin: 20px 0 0 0;
                font-size: $font-size-small;
                font-weight: bold;
                white-space: pre-line;
                word-break: break-word;            
            }

            .comments-item--seen {
                .profile-field__name {
                    opacity: .6;
                }

                .submission-notes__comments-comment {
                    opacity: .6;
                }
            }
