/*
---
name: Headings
category: base/headings
tag:
  - Typography
---

```jade
h1 Heading 1
h2 Heading 2
```
*/

h1, h2, h3, h4, h5, h6 {
    line-height: 1.2;
    font-family: $font-family-main-book;
    letter-spacing: 0.4px;
    color: $black;
    font-weight: normal;
}

h1 {
    margin-bottom: 1.25rem;
    font-size: $font-size-large;
}

h2 {
    margin-bottom: 1.5rem;
    font-size: $font-size-medium;
}

h3 {
    font-size: $font-size-base;
    margin-bottom: .8rem;
}