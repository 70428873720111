.dashboard-assessment-edit {
    width: 100%;
}

.assessment-controls {
    width: 100%;
    margin-bottom: 10px;
    // border: 1px solid green;
    display: flex;
    justify-content: space-between;

}

    .assessment-controls__actions {
        flex: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .assessment-controls__actions-right {
        flex: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .assessment-controls__heading {
        display: inline-flex;
        align-items: center;
        margin-top: 7px;
        h1 {
            margin: 0;
        }
    }

        .assessment-controls__add-assessment {
            margin-left: 10px;
        }

            .assessment-controls__icon {
                position: relative;
                cursor: pointer;
                margin: 0 10px;
                display: flex;
                align-items: center;
                
                &:hover .icon {
                    opacity: .5;
                }
            }

            .icon--add-assessment {
                @include make_circle_cross($grey-darkerer, 35px, 3px, 20px);
            }
            

    .assessment-controls__filters {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: baseline;
    }

    .assessment-controls__btn {
        width: 250px;
        margin-bottom: 10px;
        font-size: $font-size-default;
        background-color: $brand-color-3;
    }

    .assessment-controls__link {
        text-align: center;
        text-decoration: underline;
        margin-top: 5px;
        span:first-child {
            text-decoration: underline;
        }
    }
    
    .assessment-controls__btn--outlined {
        color: $brand-color-3;
        background-color: transparent;
        font-weight: bold;
        box-shadow: inset 0px 0px 0px 3px $brand-color-3;
        &:hover {
            background-color: $brand-color-3;
            color: $white;
        }
    }
     
    .assessment-controls__btn--yellow {
        font-family: $font-family-main-medium;
        background-color: $brand-color-1;
    }

    .no-assessment {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: center;
        @include size(100% 381px);
        background: url(/imgs/bg-no-campaigns@2x.png) center / contain no-repeat;
        margin-top: 15vh;
    }
    
        .no-assessment__cta {
            // margin: 80% auto;
            margin-bottom: 60px;
            margin-left: -35px;
            .btn {
                font-size: .9rem;
                padding: 1rem 2rem;
            }
        }

.assessment-candidates {
    .assessment-card__header__title {
        flex: 1;
        min-width: 250px;
    }
    .assessment-card__header__campaign {
        flex: 0;
        min-width: 250px;
    }
}