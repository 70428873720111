.csv-import, .csv-group, .csv-end {
    text-align: left;
    p + p {
        padding-top: 1rem;
    }
    a {
        text-decoration: underline;
        color: $brand-blue;
    }
}

    .csv__header-img {
        position: absolute;
        width: 120px;
        top: 15px;
        left: 110px;
    }

    .csv-import__download, .csv-end__download {
        text-align: center;
    }

    .csv-import__error {
        margin-top: 1rem;
        p {
            color: $warning-color-darker;
        }
        ul {
            list-style: inside;
            color: $warning-color-darker;
        }
    }

    .csv-group__title {
        font-family: $font-family-main-medium;
    }

    .csv-group__table {
        width: 100%;
        margin-top: 1rem;
        border: 1px solid;
        font-size: $font-size-small;
        text-align: left;

        thead {
            border-bottom: 1px solid;
        }
        th, td {
            min-width: 160px;
            max-width: 240px;
            padding: 4px 10px;
        }
        select {
            padding: 3px;
            max-width: 100%;
        }
        input {
            min-width: 200px;
            -webkit-appearance: revert;
            outline: revert;
            border: revert;
        }
    }

    .csv-end__number-candidates {
        font-family: $font-family-main-medium;
    }