.dropdown--profile {
    padding-right: 15px;
    background: url('/imgs/icons/icon-profile-arrow@2x.png') right center / 8px 5px no-repeat;
    font-family: $font-family-main-book;
    font-size: $font-size-default;
    text-align: center;
}

.dropdown__container--black {
    --container-padding: 20px;
    .wrap-dd-menu {
        .dropdown {
            width: 220px;
            max-height: 50vh;
            overflow: auto;
            background: #282A2D;
            border-top: none;
            border-radius: 3px;
            box-shadow: 0 10px 24.5px 0 rgba(0, 0, 0, 0.52);
            padding: var(--container-padding);
            li {
                a {
                    color: #fff;
                    text-align: center;
                    padding: 10px 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    // width: 140px;
                    font-size: $font-size-small;
                }
                &:not(:last-child)::after {
                    content: '';
                    position: absolute;
                    left: var(--container-padding);
                    width: calc(100% - var(--container-padding) * 2);
                    border-top: 1px solid #4a4a4a;
                    transform: translateY(calc(var(--items-offset) * -.5 - 1px));
                }
            }
        }
    }
}

//
// Dropdown select
//
.wrap-dd-select {
    position: relative;
    width: 200px;
    margin: 0 auto;
    padding: 10px;
    user-select: none;
    // background: #fff;
    // border-radius: 7px;
    // border: 1px solid rgba(0,0,0,0.15);
    // box-shadow: 0 1px 1px rgba(50,50,50,0.1);
    cursor: pointer;
    outline: none;
    // font-weight: bold;
    color: #000;

    &.disabled {
        cursor: default;
        color: #5B6A7D;

        &:after {
            border: none;
        }
    }

    &:after {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: -3px;
        border-width: 6px 6px 0 6px;
        border-style: solid;
        border-color: #000 transparent;
    }

    .dropdown {
        position: absolute;
        top: 100%;
        z-index: 10;
        width: 200px;
        background: #282A2D;
        border-radius: 3px;
        box-shadow: 0 10px 24.5px 0 rgba(0, 0, 0, 0.52);
        padding: 0 20px;
        font-weight: normal;
        list-style: none;
        visibility: hidden;
        max-height: 300px;
        overflow-y: auto;
        /*Provide an auto overflow to display scroll*/
        // &:after {
        //     content: "";
        //     width: 0;
        //     height: 0;
        //     position: absolute;
        //     bottom: 100%;
        //     right: 15px;
        //     border-width: 0 6px 6px 6px;
        //     border-style: solid;
        //     border-color: #fff transparent;
        // }
        //
        // &:before {
        //     content: "";
        //     width: 0;
        //     height: 0;
        //     position: absolute;
        //     bottom: 100%;
        //     right: 13px;
        //     border-width: 0 8px 8px 8px;
        //     border-style: solid;
        //     border-color: rgba(0,0,0,0.1) transparent;
        // }

        li {
            &.divider {
                padding: 2px 0;
                background: #e6e8ea;
            }

            &.divider-label {
                background: #e6e8ea;
                cursor: default;
                color: #000;
                padding: 10px 0;
            }

            a {
                display: block;
                padding: 20px 0;
                overflow: hidden;
                text-overflow: ellipsis;
                text-decoration: none;
                color: #fff;
                cursor: pointer;
                text-align: left;
            }

            + li {
                a {
                    border-top: 1px solid #4a4a4a;
                }
            }

            i {
                float: right;
                color: inherit;
            }

            // &:first-of-type a {
            //     border-radius: 7px 7px 0 0;
            // }
            //
            // &:last-of-type a {
            //     border-radius: 0 0 7px 7px;
            //     border: none;
            // }

            &:hover a {
                color: $warm-grey;
            }
        }

        &.active {
            .dropdown {
                visibility: visible;
            }
        }
    }
}
//
// Dropdown menu
//

.wrap-dd-menu {
    --items-offset: 20px;
    position: relative;
    width: auto;
    margin: 0 auto;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    overflow-wrap: break-word;
    &.disabled {
        cursor: default;
        color: #5B6A7D;
    }
    .dropdown {
        position: absolute;
        width: 220px;
        z-index: 10000;
        top: 70%;
        // left: 0;
        right: 0;
        background: #fff;
        padding: 40px;
        margin-top: 25px;
        font-family: $font-family-main-book;
        font-size: $font-size-small;
        // border-radius: 7px;
        // border: 1px solid rgba(0,0,0,0.17);
        box-shadow: 0 0 20px rgba(0,0,0,0.15);
        list-style: none;
        visibility: hidden;
        border-top: 5px solid $brand-color-1;
        li {
            &:hover {
                cursor: pointer;
                a {
                    // background: #f3f8f8;
                    cursor: pointer;
                    color: $warm-grey;
                }
            }
            &.divider {
                padding: 2px 0;
                background: #e6e8ea;
            }
            &.divider-label {
                background: $brand-color-2;
                margin: 0 -20px;
                cursor: default;
                color: #fff;
                padding: 10px;
                text-align: center;
                border-radius: 3px 3px 0 0;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            a {
                display: block;
                // padding: 10px;
                text-decoration: none;
                text-align: left;
                color: #000;
                // border-bottom: 1px solid #4a4a4a;
                // box-shadow: inset 0 1px 0 rgba(255,255,255,1);
                cursor: pointer;
            }
            &:not(:last-child) a {
                margin-bottom: var(--items-offset);
            }
            i {
                float: right;
                color: inherit;
            }
            // &:first-of-type a {
            //     border-radius: 7px 7px 0 0;
            // }
            // &:last-of-type a {
            //     border-radius: 0 0 7px 7px;
            //     border: none;
            // }
        }
        // &:before {
        //     content: "";
        //     width: 0;
        //     height: 0;
        //     position: absolute;
        //     bottom: 100%;
        //     right: 100px;
        //     border-width: 0 8px 8px 8px;
        //     border-style: solid;
        //     border-color: rgba(0,0,0,0.1) transparent;
        // }
        // &:after {
        //     content: "";
        //     width: 0;
        //     height: 0;
        //     position: absolute;
        //     bottom: 100%;
        //     right: 102px;
        //     border-width: 0 6px 6px 6px;
        //     border-style: solid;
        //     border-color: #fff transparent;
        // }
        &.active {
            visibility: visible;
        }
    }
}


.dropdown--to-right {
    .dropdown {
        left: 0;
    }
}

.dropdown--to-left {
    .dropdown {
        left: none;
        right: 0;
    }
}

