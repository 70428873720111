.c-modal-vote {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  border: solid grey 1px;
  padding: 10px;
  border-radius: 5px;
  background-color: white;

  &-details {
    display: flex;
    justify-content: space-between;
  }

  &-left {
    display: flex;
    align-items: center;
    margin: auto 0;
  }

  &-icon {
    min-width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    color: #333;
    margin-right: 10px;

    &.icon-approved {
      background-color: #28a745;
      color: #fff;
    }

    &.icon-rejected {
      background-color: #dc3545;
      color: #fff;
    }

    &.icon-pending {
      background-color: #ffc107;
      color: #fff;
    }
  }

  &-user {
    font-weight: bold;
    display: flex;
    align-items: center;
    min-width: 300px;
  }

  &-action {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .c-modal-vote-email-reminder {
      padding: 5px 10px;
      border-radius: 3px;
      cursor: pointer;

      &.reminder-sent {
        background-color: #ccc;
        color: #666;
        cursor: not-allowed;
      }
    }

    .c-modal-vote-toggle-comment {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-last-reminder {
    font-size: 12px;
    color: #666;
    margin-top: 10px;
  }

  &-comment-section {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #ddd;
    color: #777;
  }
}

.c-modal-vote-status {
  &.status-approved {
    color: #28a745;
  }

  &.status-pending {
    color: #ffc107;
  }

  &.status-rejected {
    color: #dc3545;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.c-modal-vote-action {
  min-width: 150px;
}