.no-campaigns {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    @include size(100% 381px);
    background: url(/imgs/bg-no-campaigns@2x.png) center / contain no-repeat;
    margin-top: 15vh;
}

    .no-campaigns__cta {
        // margin: 80% auto;
        margin-bottom: 60px;
        margin-left: -35px;
        .btn {
            font-size: .9rem;
            padding: 1rem 2rem;
        }
    }
