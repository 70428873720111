.account-settings {
  width: 100%;

  .app-content__container {
    height: 100%;
  }

  .login__forgot-password {
    margin-bottom: 30px;
  }
}

.account-settings__reset-password {
  margin-bottom: 20px;
}

.account-settings__container--reset-password {
  align-items: center;
  .label {
    margin-bottom: 15px;
  }
  .login__forgot-password {
    align-self: flex-start;
  }
}

.account-settings__photo {
  @include size(65px);
  margin: 10px 20px 20px;
  border-radius: 100%;
  align-self: center;
  cursor: pointer;
}

.account-settings__calendar {
  padding-top: 20px;
  padding-bottom: 10px;
}

.account-settings__calendar_item {
  max-width: 330px;

  & + & {
    margin-top: 10px;
  }

  a {
    text-decoration: underline;
    transition: all 0.3s ease-in-out;
    font-size: $font-size-small;
    &:hover {
      color: #999;
    }
  }

  span {
    margin-right: 10px;
  }
}

.account-settings__calendar_acount {
  margin-left: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}

.account-settings__email {
  align-items: flex-start;
}

.account-settings__icon {
  align-items: flex-end;
  margin-left: auto;
}

.account-settings__reset_btn {
    width: 220px;
    margin-right: 5px
}
