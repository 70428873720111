#contextmenu-node {
  position: absolute;
  background-color: #282A2D;
  border-top: none;
  border-radius: 3px;
  box-shadow: 0 10px 24.5px 0 rgba(0, 0, 0, 0.52);
  padding: 0 20px;
  z-index: 10;
}

.contextmenu-item {
  color: #fff;
  text-align: center;
  padding: 20px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: $font-size-small;
}

.contextmenu-item:hover {
    color: $warm-grey;
    cursor: pointer;
}