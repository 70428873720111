.overlay {
    position: absolute;
    height: 100%;
    width: 100%;
}

.overlay--margin {
    // box around element with extra margin of 10px
    height: calc(100% + 20px);
    width: calc(100% + 20px);
    top: -10px;
    left: -10px;
}

.overlay--upgrade {
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
        background-color: rgba($overlay-grey-trans, .1);
    }
}

.overlay--upgrade--wider {
    width: calc(100% + 480px);
    left: -240px;
    &, &:hover {
        background-color: rgba($overlay-grey-trans, .4);
    }
}

.overlay--coming-soon {
    // backdrop-filter: blur(1px);
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}