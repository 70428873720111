.c-live-interview {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

    .c-live-interview__controls {
        display: flex;
        justify-content: flex-end;
    }

    .c-calendar__today-button {
        align-self: center;
        margin: 1rem 0 3rem;
        background: $grey-light;
        color: $black;
        font-size: $font-size-smaller;
        text-transform: uppercase;
        padding: .5rem 2rem;
        border-radius: 3px;
    }
