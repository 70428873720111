// Fix for the flickering of Google Chart (source : https://github.com/google/google-visualization-issues/issues/2162#issuecomment-233767625)
svg > g > g:last-child {
    pointer-events: none
}

.analytics__section {
    // margin-bottom: 1rem;
}

.analytics__filters {
    display: flex;
    flex-wrap: wrap;
    gap: 20px 50px;

    .comp__filter + .comp__filter {
        margin-left: 0;
    }
}

    ng-dropdown-multiselect.analytics__filters {
        z-index: 1001; // without this the select panel gets the blur effect from the hint__chart 
        .dropdown-multiselect {
            width: fit-content;
            // .dropdown-toggle.btn {
            //     all: unset;
            // }
        }
    }

    .hints__row {
        margin-top: 30px;
    }

    .hint {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px;
        background-color: white;
        border-radius: 5px;
        height: 163px;
        margin-bottom: 30px
    }

    .hint--large {
        min-height: 300px;
        height: fit-content;
    }

        .hint__ball {
            @include size(60px);
            margin-bottom: 10px;
            border-radius: 50%
        }
        .hint__header {
            font-size: $font-size-large;
        }
        .hint__content {
            text-align: center;
            text-transform: uppercase;
            font-size: $font-size-small;
        }

        .hint__candidates {
            .hint__ball {
                background-color: lightskyblue;
            }
        }
        .hint__qualified {
            .hint__ball {
                background-color: #FFB700;
            }
        }
        .hint__hired {
            .hint__ball {
                background-color: #28bd83;
            }
        }
        .hint__time {
            .hint__ball {
                background-color: #52dba7;
            }
        }

        .hint__active-campaigns {
            .hint__ball {
                background-color: $brand-color-3;
            }
        }
        .hint__archived-campaigns {
            .hint__ball {
                background-color: $black;
            }
        }
        .hint__mean {
            .hint__ball {
                background-color: $analytics-hint-mean;
            }
        }
        .hint__interview {
            .hint__ball {
                background-color: #4382e1;
            }
        }

        .hint__stage-bars {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            width: 520px;
        }

        .hint__stage-bar {
            display: flex;
            align-items: center;
            margin-left: auto;
            width: fit-content;
            & + & {
                margin-top: 5px;
            }
        }

            .hint__stage-percent-outer {
                width: 110px;
            }
                .hint__stage-percent {
                    width: fit-content;
                    margin: auto;
                    font-family: $font-family-main-medium;
                }

            .hint__progress-outer {
                @include size(200px 15px);
                box-sizing: initial;
            }
                .hint__progress {
                    @include size(0% 15px);
                }

        .hint__chart {
            width: 100%;
            // @include size(100% 300px);
        }