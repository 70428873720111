.unsupported-devices {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include size(100vw 100vh);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
    @media (max-height: 600px) {
        zoom: 1.25;
    }
    .logo, p {
        color: $white;
        text-align: center;
        margin-bottom: 2rem;
    }
    .logo {
        font-size: 2.5rem;
    }
    p {
        font-size: 1rem;
    }
}
