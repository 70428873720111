@keyframes fadeInButton {
    0% {
        display: inherit;
        opacity: 0;
    }
    100% {
        display: inherit;
        opacity: 1;
    }
}
@keyframes fadeOutButton {
    0% {
        display: inherit;
        opacity: 1;
    }
    100% {
        display: inherit;
        opacity: 0;
    }
}

.interview-welcome {
    .interview_hero_title, .career__title, .section-title, .section-title-2,
    .section-banner__detail, .section-content__link, .section-content__text
    {
        word-break: break-word;
    }

    .int-video {
        margin-top: 15px;
        @media screen and (min-width: $size-xl) {
            margin-bottom: 30px;
        }

        video {
            width: 100%
        }
    }
    
    .campaign__cta-btn__bottom {
        position: sticky;
        bottom: 20px;
        z-index: 5;
        display: none;
        
        &.campaign__cta-btn__bottom--visible {
            display: inherit;
            animation: fadeInButton 0.5s;
            opacity: 1;
        }
        &.campaign__cta-btn__bottom--hidden {
            animation: fadeOutButton 0.5s;
            opacity: 0;
        }
    }
    
    .campaign__cta-btn, .campaign__cta-btn__bottom {
        width: 156px;
        margin: 30px auto 0;

        font-size: $font-size-default;
        padding: 1rem 1rem;
        margin-top: 1rem;
    }
    
    .company__jobs-btn {
        max-width: 268px;
        margin-bottom: 20px;
    }

    .interview-welcome__banner-title {
        margin-top: -50px;
    }
}

.interview-welcome--flex {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    
    h1 {

    }
    h2 {
        font-family: $font-family-main-book;
        font-weight: lighter;
        margin-top: 20px;
        b {
            color: white;
        }
    }
    p {
        text-align: center;
        margin: 0 0 40px;
    }
    &.not-customized {
        h1, h2, p {
            color: black;
        }
    }
    &.has-customized {
        .interview-video__video-question-picture {
            background: url('/imgs/video-question-black.png') center / contain no-repeat;
        }
        .btn--icon-next {
            color: #333;
            border: 2px solid #333;
            background-image: url(/imgs/icons/icon-next-black.svg);
        }
    }
}

.interview_hero_default-background {
    background-image: url('/imgs/candidate-welcome-background.jpg');
}