details>summary {
    width: fit-content;
    list-style: none;
    cursor: pointer;
}

summary::-webkit-details-marker {
    display: block;
}

summary::after {
    content: '    ';
    white-space: pre;
    background: url('/imgs/icons/icon-full-arrow-black-bottom@2x.png') right center / 12px 6px no-repeat;
}

details[open] summary:after {
    content: '    ';
    white-space: pre;
    background: url('/imgs/icons/icon-full-arrow-black-top@2x.png') right center / 12px 6px no-repeat;
}
