.video-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  @include size(100%);
  // background-color: transparentize(#45494C, .85);
  padding: 50px;
  @media (max-height: 600px) {
      zoom: .8;
  }
}

  .video-modal__box {
    display: flex;
    flex-direction: column;
    @include size(40rem auto);
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 19.5px 101px 0 rgba(0, 0, 0, 0.68);
  }

    .video-modal__head {
      min-height: 35%;
      width: 100%;
    }

      .video-modal__controls {
        display: flex;
        justify-content: flex-end;
        padding: 1.375rem 1.5rem 0;
      }

        .video-modal__close {
          display: flex;
          align-items: center;
          font-size: $font-size-default;
          color: #D9D9D9;
          span {
            margin-right: 9px;
          }
        }

            .video-modal__btn {
              cursor: pointer;
              transition: all 0.1s ease-in-out;
              &:hover {
                opacity: .5;
              }
            }

      .video-modal__head-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 2rem 1.25rem;
        text-align: center;
        a, h1, div {
          flex: 1;
        }
        a {
          line-height: 26px;
          padding-left: 25px;
          background: url('/imgs/icons/icon-page-nav-back-black@2x.png') left center / 14px 24px no-repeat;
          font-family: $font-family-main-medium;
          font-size: $font-size-small;
          text-align: left;
          transition: all 0.25s ease-in-out;
          &:hover {
            opacity: 0.7;
          }
        }
        h1 {
          margin: 0;
        }
      }

        .video-modal__head-content--warning {
          h1 {
            color: $warning-color;
          }
        }

          .video-modal__icon {
            margin-bottom: 1rem;
          }

      .video-modal__modal-nav {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }

          .modal-nav__item {
            display: flex;
            align-items: center;
            padding: 18px 0;
            color: #b6b6b6;

            .icon {
              margin-right: 16px;
            }

            + .modal-nav__item {
              margin-left: 45px;
            }

            &.active {
              border-bottom: 3px solid $brand-color-1;
              color: $black;
            }
          }







    .video-modal__body {
      // height: 100%;
      min-height: 50%;
      background-color: #f7f7f7;
      text-align: center;
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;
      align-items: center;
      .input-field {
        padding-left: 20px;
      }

      .input-field {
        margin: 0;
        padding: 0 20px;
        text-align: center;
      }
      .cc-questions__button-bar {
        padding: 1.5rem 0;
      }
      .plyr__video-wrapper {
        height: 480px;
      }
    }


      .video-modal-library {

      }


        .video-modal__videos-list {
          height: 250px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          padding: 0 2rem;
        }

          .video-modal-videos-list-item {
            position: relative;
            width: 30%;
            margin-bottom: 23px;
            cursor: pointer;
            transition: all 0.25s ease-in-out;
            &:hover {
              opacity: 0.7;
            }
            & img {
              @include size(100% auto);
            }
          }

            $text-font-size: $font-size-smaller;
            $text-line-height: 1.4;
            $text-lines-to-show: 2;


            .video-modal-videos-list-item__text {
              width: 100%;
              bottom: 20px;
              position: absolute;
              padding: 0 10px;
              display: block; // Fallback for non-webkit
              max-height: $text-font-size * $text-line-height * $text-lines-to-show; // Fallback for non-webkit

              display: -webkit-box;
              font-family: $font-family-main-bold;
              font-size: $text-font-size;
              line-height: $text-line-height;

              -webkit-line-clamp: $text-lines-to-show;
              -webkit-box-orient: vertical;

              margin: 0 auto;

              text-align: center;
              color: #fff;
              text-shadow: black 0px 0 2px;

              overflow: hidden;
              text-overflow: ellipsis;
            }


      .video-modal-recording {

      }

        .video-modal__indicator {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 3.125rem;
          background-color: rgba(0,0,0,.5);
          z-index: 100;
          margin: 0 0 -3.125rem;
          span {
              font-weight: bold;
              line-height: 3.125rem;
          }
        }

        .video-modal__camera {
          @include size(100% 480px);
          display: flex;
          justify-content: center;
          align-items: center;
          h1 {
            color: red;
          }
        }


      .video-modal-preview {
        position: relative;
      }


        .video-modal__player {
          width: 100%;
        }

        .video-modal__delete-overlay {
          position: absolute;
          @include size(100% 480px);
          top: 0;
          z-index: 999;
          background-color: rgba(0,0,0,.5);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          p {
            color: #fff;
            font-size: $font-size-small;
            margin-bottom: 10px;
          }
        }

          .video-modal__delete-overlay-buttons {
            .btn {
              font-family: $font-family-main-bold;
              min-width: 180px;
              padding: 1rem;
              + .btn {
                margin-left: 1.25rem;
              }
            }
          }




      .video-modal__buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1.5rem 2rem;
        .btn {
          font-family: $font-family-main-bold;
          font-size: $font-size-small;
          padding: 1rem;
        }
        .btn--transparent-black {
          padding: 0.875rem 2rem;
        }
      }

        .video-modal__buttons-item {
          display: flex;
          align-items: center;
        }

          .video-modal__buttons-item--left {
            flex: 1;
            justify-content: flex-start;
          }

          .video-modal__buttons-item--center {
            flex: 1.5;
            justify-content: center;
            .btn {
              min-width: 80%;
            }
          }

          .video-modal__buttons-item--right {
            flex: 1;
            justify-content: flex-end;
          }

            .video-modal__duration {
              display: flex;
              align-items: center;
              height: 30px;
              background: url('/imgs/icons/icon-clock-black@2x.png') left center / contain no-repeat;
              padding-left: 40px;
              span {
                font-size: $font-size-large;
                padding-top: 4px;
                width: 80px;
              }
            }
