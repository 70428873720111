list-filter {
  .list-filter {
    min-width: 100%;
    width: fit-content;
    background-color: #fff;
    border: 1px solid rgba(226,229,232,.4);
    margin-top: 15px;
    padding: 20px 20px;
    display: flex;
    gap: 30px;
    align-items: center;

    @media screen and (max-width: 1400px) {
      gap: 20px
    }
  }

  .filter-prop {
    display: flex;
    gap: 5px;
    align-items: center;

    .filter-text, .list-filter__dropdown .dropdown-toggle {
      color: $unactiveFilterColor;
      font-size: $font-size-small;
    }

    &.active {
      .filter-text, .list-filter__dropdown .dropdown-toggle {
        font-family: $font-family-main-bold;
        color: $activeFilterColor;
      }
    }

    &.filter-prop--filterable {
      .input-field {
        margin-bottom: 0;
      }
      .input-field:before {
        content: "\f0d7";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        margin-right: 10px;
      }

      
      .list-filter__dropdown .dropdown-toggle {
        // overwrite properties of buttons
        border: none;
        background-color: transparent;
        padding: 0;
      }
      &.filter-prop--not-sortable .list-filter__dropdown .dropdown-toggle {
        &::after {
          content: "\f0b0";
          font-size: 10px;
          position: absolute;
          right: -20px;
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
      }
      }
      &.active.filter-prop--not-sortable .list-filter__dropdown .dropdown-toggle {
        font-family: $font-family-main-bold;
        color: $activeFilterColor;
        &::after {
            color: black;
        }
      }
    }

    &.active {
      .list-filter__sort {
        span.filter-text {
          &.sort-asc {
            @include append__arrow-up(-12px, $activeFilterColor);
          }
          &.sort-desc {
            @include append__arrow-down(12px, $activeFilterColor);
          }
        }
      }
    }
    .list-filter__sort {
      span.filter-text {
        border: none;
        background-color: transparent;
        padding: 0;
        &.sort-asc {
          @include append__arrow-up(-12px, $unactiveFilterColor);
        }
        &.sort-desc {
          @include append__arrow-down(12px, $unactiveFilterColor);
        }
      }
    }
  }

  .list-filter__dropdown {
    display: flex;

    & + & {
        margin-left: 5rem;
    }
  }
}