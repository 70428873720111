// img-crop {
//   width:100%;
//   height:100%;
//   display:block;
//   position:relative;
//   overflow:hidden;
//   canvas {
//     display:block;
//     position:absolute;
//     top:50%;
//     left:50%;
//
//     // Disable Outline
//     outline: none;
//     -webkit-tap-highlight-color: rgba(255, 255, 255, 0); /* mobile webkit */
//   }
// }

.img-crop {
    // border: 1px solid green;
    margin-bottom: 20px;
}

    .img-crop__area {
        flex: 1;
        height: 130px;
        // border: 1px solid red;
        margin-bottom: 20px;
        img-crop {
            width: 100%;
            height: 100%;
            // border: 1px solid green;
            display: block;
            canvas {
                margin: 0 !important;

                // Disable Outline
                outline: none;
                -webkit-tap-highlight-color: rgba(255, 255, 255, 0); /* mobile webkit */
              }
        }
    }

.crop-container {
    @include size(128px);
    background-color: #F7F7F7;
    border-radius: 100%;
    margin: 0 auto;
    img {
        @include size(128px);
        margin-bottom: 20px;
        border-radius: 100%;
    }
}
