.c-sidebar-agenda {
    background: #f1f1f1;
    width: 350px;
}

.c-sidebar-agenda__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding-bottom: 1.5rem;
}

.c-sidebar-agenda__header-text {
    font-size: $font-size-large;
    font-family: $font-family-main-book;
    padding: 3rem 0 1rem;
}

.c-sidebar-agenda__selected-day {
    display: flex;
    flex-direction: flex-end;
    align-items: center;
    background-color: $brand-color-3;
    border-radius: 100px;
    padding: 8px 8px 8px 20px;
    span {
        margin-right: 10px;
        color: white;
        font-size: $font-size-small;
        font-family: $font-family-main-medium;
        line-height: 1;
        padding-top: 2px;
    }
    a {
        margin-bottom: 0;
    }
}

.c-sidebar-agenda-item {
    background-color: #ececec;
    border-bottom: 1px solid #e2e2e2;
}

.c-sidebar-agenda-item__header {
    display: flex;
    align-items: center;
    height: 40px;
    // background-color: $brand-color-3;
    padding-left: 1.25rem;
    &.active {
        background-color: #5dcea1;
    }
}

.c-sidebar-agenda-item__date {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    padding-right: 15px;
    span {
        flex: 1;
        font-size: $font-size-small;
        font-family: $font-family-main-medium;
        padding-top: 2px;
    }
}

.c-sidebar-agenda-item__time {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $font-family-main-book;
    font-size: $font-size-small;
    background-color: transparent;
    color: white;
    height: 100%;
    padding: 2px 10px 0 0;
}



.c-sidebar-agenda-item__members {
    text-align: left;
    padding: 1.25rem 1.75rem;
    background-color: $white;
}
        
.c-sidebar-agenda-item__members--clickable {
    cursor: pointer;
    background: url(/imgs/icons/agenda-item-arrow@2x.png) 93% center / 9px 14px no-repeat;
    background-position-y: 75px;
}

.c-sidebar-agenda-item__class-name {
    display: inline-block;
    background-color: $white;
    font-family: $font-family-main-bold;
    font-size: $font-size-smaller;
    text-overflow: ellipsis;
    text-transform: uppercase;
    color: #b5b4b4;
    overflow: hidden;
    white-space: nowrap;
    border: 1px solid #e1e1e1;
    border-radius: 100px;
    padding: 10px 20px 8px;
    margin-bottom: 15px;
}

.c-sidebar-agenda-item__candidate, .c-sidebar-agenda-item__collab {
    display: flex;
    align-items: center;
}

.c-sidebar-agenda-item__collab {
    margin-top: 5px;
}


.c-sidebar-agenda-item__collaborator-name {
    font-family: $font-family-main-medium;
    font-size: $font-size-smaller;
    color: #333;
    font-size: 0.85rem;
    margin-bottom: 5px;
}

.c-sidebar-agenda-item__candidate-name {
    @extend .c-sidebar-agenda-item__collaborator-name;
    text-decoration: underline; 
}

.c-sidebar-agenda-item__btn {
    margin: 1rem 30px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .c-sidebar-agenda-item__btn i {
    color: white;
    margin-left: 8px;
    display: flex;
    align-items: center;
  }

.c-sidebar-agenda-item__subject {
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.c-sidebar-agenda-item__address {
    display: flex;
    margin-top: 1rem;
}

.c-sidebar-agenda-item__map-marker {
    padding-right: 8px;
    color:grey;
}