// todo metis : all CSS here

.candidate-profile-content {
    .section-content {
        max-width: 1930px;
    }
    .box--grey {
        padding: 15px 30px;
    }

    .candidate-profile__name, .candidate-profile-content__box-title {
        font-family: $font-family-main-medium;
    }
    .candidate-profile__name {
        font-size: $font-size-large;
    }
    .candidate-profile-content__box-title {
        font-size: $font-size-medium;
    }

    .candidate-profile-content__img {
        border-radius: 50%;
        object-fit: cover;
        @include size(100px 100px);
        @media screen and (min-width: $size-lg) {
            @include size(133px 133px);
        }
    }

    .candidate-profile__campaign-logo {
        object-fit: contain;
        height: 100px;
        width: 100%;
        @media screen and (min-width: $size-lg) {
            height: 133px;
        }
    }

    .candidate-email-input {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        .input-field {
            width: 100%;
            height: 50px;
            min-height: 50px;
            margin: 0;
        }

        .btn {
            width: 30%;
            height: 50px;
        }

        @media screen and (min-width: $size-md) {
            flex-direction: row;
            .input-field {
              width: 70%;
            }
          }
    }

    .btn--edit-profile {
        margin-left: auto;
        margin-right: auto;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .section-content--btn-container {
        .btn {
            margin: 15px 0;
            width: 45%;
        }
    }

    @media screen and (max-width: $size-sm-max) {
        .sm-centered-element {
            text-align: center;
            margin-left: auto;
            margin-right: auto;
        }

        .section-content--btn-container {
            flex-direction: column-reverse;
            align-items: center;
            .btn {
                width: 70%;
            }
        }

        .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
            &.col--buttons {
                flex-direction: column-reverse;
                margin-bottom: 15px;
                .btn {
                    width: 80%;
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
    }
}

    .candidate-profile {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
        padding-bottom: 50px;
        padding-top: 50px;
    }
        
        .candidate-profile__title {
            font-family: $font-family-ternary-semi;
            font-size: 30px;
            margin-bottom: 30px;
            margin-top: 30px;
            text-align: center;
        }

        .candidate-profile-description {
            max-width: 800px;
        }

        
        .candidate-profile-section {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: baseline;
            padding: 20px;
            min-width: 800px;
        }

        // *******************************
        // section edit candidate info
        // *******************************

        .candidate-profile-box_edit {
            width: 100%;
            border: 1px solid #ddd;
            border-radius: 3px;
            padding: 25px 75px 0px 75px;
            background-color: #fff
        }

            .candidate-profile-box_button {
                min-width: 200px;
                margin-top: 30px;
                margin-left: 25px;
                margin-right: 25px;
                margin-bottom: 20px;
            }

// 
// Only for the candidate area
// 

    .int-video {
        margin: 0 20px 30px;
    }

// 
// 
// 