.tooltip--field-help {
    width: fit-content;
    word-wrap: break-word;
}

.tooltip {
    $background-color: #333;
    width: 200px;
    white-space: normal;
    word-wrap: break-word;
    text-align: center;
    font-size: $font-size-small;
    text-decoration: none;
    font-family: $font-family-main-book;
}

.tooltip-upgrade {
    $upgrade-tooltip-color: #333;
    width: 200px;
    white-space: normal;
    word-wrap: break-word;
    text-align: center;
    font-size: $font-size-small;
    text-decoration: none;
    background: $upgrade-tooltip-color;
    font-family: $font-family-main-book;

    tooltip._bottom & tip-arrow {
      @include tipArrow('bottom', $upgrade-tooltip-color !important);
    }
    tooltip._right & tip-arrow {
      @include tipArrow('right', $upgrade-tooltip-color !important);
    }
    tooltip._top & tip-arrow {
      @include tipArrow('top', $upgrade-tooltip-color !important);
    }
    tooltip._left & tip-arrow {
      @include tipArrow('left', $upgrade-tooltip-color !important);
    }
}

.tooltip-ratings {
    padding: 0;
}

 .tooltip-ratings__field {
    display: flex;
    width: 200px;
    justify-content: flex-start;
    align-items: center;
    padding: 14px 17px;

    + .tooltip-ratings__field {
        border-top: 1px solid #45494c;
    }
 }

 .tooltip-ratings__field--small {
    max-width: fit-content;
 }

 .tooltip-ratings__field--count {
    span {
        color: $white;
        text-transform: uppercase;
        text-align: center;
        font-weight: bold;
        font-size: $font-size-smaller;
        width: 100%;
    }
 }

    .tooltip-ratings__photo {
        @include size(27px);
        border: none;
        margin-right: 12px;
    }

    .tooltip-ratings__info {
        text-align: left;
        span {
            text-align: left;
            font-size: $font-size-smaller;
            color: $grey;
        }
        .profile-field__stars {
            justify-content: flex-start;
            padding-left: 0;
            margin-top: 5px;
        }
    }

.tooltip--mfa-disabled {
    width: 300px;
}