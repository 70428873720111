media-upload {
  width: 100%;
  .media-upload {
    border: 2px dashed $warm-grey-two;
    background: rgba(255,255,255,.8);
    border-radius: 3px;
    color: $warm-grey-two;
    
    div.icon {
      filter: invert(30%) sepia(8%) saturate(0%) hue-rotate(167deg) brightness(90%) contrast(30%);
    }
    
    &.media-upload--drag-over {
      div.icon {
        filter: invert(30%) sepia(8%) saturate(3451%) hue-rotate(167deg) brightness(130%) contrast(80%);
      }
      span, p, a {
        color: $cerulean;
      }
      border-color: lighten($cerulean, 20%);
      a.top-right-position {
        color: $white;
      }
    }
  }

  .bold-text {
    font-size: $font-size-small;
    font-family: $font-family-main-bold;
    letter-spacing: 0.3;
  }
  .info-text {
    font-size: $font-size-small;
    font-family: $font-gotham-book;
    text-align: center;
  }

  img {
    max-width: 200px;
    max-height: 100px;
    width: auto;
    height: auto;
    
    &.media-upload--thumb-small {
      max-width: 130px;
      max-height: 60px;
    }
    &.media-upload--thumb-large {
      max-width: 170px;
      max-height: 190px;
    }
  }

  .media-upload__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 220px;
    width: 100%;
    gap: 5px;
    padding: 15px 40px;
    justify-content: center;
    .top-right-position {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 1;
    }

    .media-upload__content-item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      align-self: center;
      + .media-upload__content-item {
        margin-top: 1.5rem;
      }
      .icon--help {
        margin-left: 20px;
      }
    }

    .add-video__preview {
      span.add-video__preview-duration {
        font-size: $font-size-smaller;
        font-family: $font-gotham-book;
        padding: 2px 10px;
        color: white;
        width: fit-content;
        background-color: rgba($color: #000000, $alpha: 0.6);
        right: 0;
        margin: 0;
      }
    }

    .center-text {
      text-align: center;
    }
  }
}