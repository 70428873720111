.location-map {
  width: 100%;
  border: 0px;
  @media screen and (min-width: $size-xl) {
      min-height: 400px;
  }
  @media screen and (min-width: $size-sm) and (max-width: $size-lg-max) {
      min-height: 378px;
  }
  @media screen and (min-width: $size-xs) and (max-width: $size-xs-max) {
      min-height: 331px;
  }
  @media screen and (max-width: $size-xs) {
      &:after {
          content: "";
          display: block;
          padding-bottom: 100%;
      }
  }
}

.location-map__container {
    position: relative;
    width: 100%;

    .location-map__overlay {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.6);

        label {
            color: white;
            font-weight: bold;
        }
    }
}

.location-map__info-body {
    width: 300px;
    font-size: $font-size-small;
}
.location-map__title {
    font-weight: bold;
}
.location-map__address {
    .location-map__address--icon {
        color: $brand-blue;
        margin-right: 10px;
    }
}
.location-map__description {
    position: relative;
    margin-top: 10px;
    max-height: 70px;
    overflow: hidden;
    &:after {
        position: absolute;
        content: "";
        width: 100%;
        height: 10px;
        background: linear-gradient(180deg,hsla(0,0%,100%,0),#fff);
        bottom: 0;
    }
    p {
        line-height: 1.3;
    }
}
.location-map__button {
    width: fit-content;
    margin-top: 20px;
}