/*
---
name: Main box
category: boxes/main-box
tag:
  - Boxes
---

```jade

```
*/

.box-container {
  margin: auto;
  padding: 2rem 0;
  @include size(100% auto);
  max-width: 600px;
}

  .box-container__large {
    min-width: 800px;
  }

  .box {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 55px 65px;
    border-radius: 3px;
    background-color: #fff;
  }

      .box_input-combined {
        display: flex;
        .input-field {
          width: 100%;
          height: 50px;
          min-height: 50px;
          margin: 0;
        }
        .btn {
          width: 30%;
          height: 50px;
          max-width: 160px;
        }
        @media screen and (max-width: $size-sm-max) {
          flex-direction: column;
          .input-field {
            width: 100%;
          }
        }
      }


      .box__notification {
        color: #f66363;
        text-align: center;
        width: 100%;
        font-size: 10px;
        position: relative;
        top: -10px;
      }

      .box__button-field {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
      }

      .box__input-field {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 20px;
        .input-field {
          margin-bottom: 0;
        }

        .box__input-field {
          + .box__input-field {
              margin-left: 30px;
          }
        }
      }
      .box__input-field.setting__checkbox+.box__input-field.setting__checkbox {
        margin-top: -30px;
      }

          .box__input-field--multi {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 0;
          }


              .box__input-field--s {
                .input-field {
                  max-width: 170px;
                }
              }

              .box__input-field--m {
                .input-field {
                  max-width: 230px;
                }
              }

              .box__input-field--white-transparent {
                background-color: rgba(255,255,255,.8);
                padding: 20px;
                border-radius: 3px;
              }
      .box__input-field--datetime {
        gap: 20px;
      }
      .box__label-field {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 5px;
      }
      .box__input-upload {
        border: 1px dashed;
      }

.box--grey {
  background-color: $white-three;

  & + .box--grey {
    margin-top: 11px;
  }
}

.box--border {
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 10px;

  &:first-of-type {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  &:last-of-type {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  &:not(:last-child) {
    border-bottom: none;
  }
}
.box--xs {
  width: 100%;
  @media screen and (min-width: $size-sm) and (max-width: $size-lg-max) {
      width: 405px;
  }
  @media screen and (min-width: $size-xs) and (max-width: $size-xs-max) {
      width: 336px;
  }
}