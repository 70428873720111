pug-tooltip {
  position: relative;
}
.tooltip__container {
  position: absolute;
  z-index: 9999;
  background-color: #fff;
  box-shadow: 0 0 7px 0px;
  border-radius: 5px;
  padding: 10px;
  top: 25px;
  left: 25px;
}
.tooltip__title {
  border-bottom: 1px inset $grey;
  font-weight: bold;
  padding: 10px;
}
.tooltip__content {
  padding: 10px;
}