.submission-notes {

}

    .mention {
        color: $brand-color-1;
        &:before {
            content: '@';
        }
    }

    .submission-notes__add {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: space-between;
        padding: 50px;
        max-height: 150px;
        background-color: $input-field-grey;
        .input-field {
            margin: 0;
            font-size: $font-size-small;
        }
        .btn {
            margin-left: 40px;
        }
    }

    .submission-notes__textarea {
        margin-bottom: 10px;
        //padding: 60px 50px;
        }

        .notes__body {
            white-space: pre-line;
        }

    .submission-notes__name {
            overflow: visible;
            text-overflow: clip;
            max-width: none;
        }